import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const SelectAll = translated('app')('ProductList.BatchSelect')

/**
 */
export const AddToSampleRequestButton = translated('app')(
  'ProductList.ToSampleRequest',
)

/**
 */
export const RemoveEntries = translated('app')('ProductList.RemoveEntries')

/**
 */
export const BatchRequestBims = translated('app')('ProductList.RequestBims')

/**
 */
export const BulkRequestBims = translated('app')('ProductList.RequestBims')

/**
 */
export const DispatchBulkAction = translated('app')(
  'ProductList.DispatchBulkAction',
)

/**
 */
export const SearchProducts = translated('app')('ProductList.SearchProducts')

/**
 */
export const BulkCopyProjectProducts = translated('app')(
  'ProductList.CopyProjectProducts',
)

/**
 */
export const BulkTagEdit = translated('app')('ProductList.BulkTagEdit')
