import React from 'react'

import * as styles from './styles.module.scss'
import * as Buttons from '@arch-log/webapp.shared/Buttons'

/**
 */
export const Message = ({ children }) => (
  <div className={styles.Message}>{children}</div>
)

/**
 */
export const OkButton = (props) => <Buttons.DefaultButton {...props} />
