import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Message = ({ children, ...props }) => (
  <p className={styles.Message} {...props}>
    {children}
  </p>
)

/**
 */
export const CompleteButton = ({ children, onComplete, ...props }) => (
  <button
    type="button"
    className={styles.CompleteButton}
    onClick={() => onComplete()}
    {...props}
  >
    {children}
  </button>
)

/**
 */
export const AddTotalProducts = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const CopyDataEmptyMessage = ({ children }) => {
  return <>{children}</>
}
