import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ProductCategoryRootEntries {
    productCategories(depth: 1, size: 10000) {
      entries {
        id
        labels {
          en
          ja
        }
        countProducts
      }
      total
    }
  }
`
