import React from 'react'

import * as Fields from './BaseField'

import * as styles from './styles.module.scss'

export const DeletionConfirmation = ({ formatMessage, ...props }) => {
  return (
    <>
      <Fields.DeletionConfirmation
        className={styles.DeletionConfirmation}
        {...props}
      />
    </>
  )
}
