import React from 'react'

import * as Buttons from '@arch-log/webapp.shared/Buttons'

import * as styles from './styles.module.scss'

/**
 * Title Decorator
 */
export const Title = ({ children }) => <span>{children}</span>

/**
 * Sort Decorator
 */
export const SortButton = ({ children, onSelect, isActive, direction }) => (
  <Buttons.SortButton
    isActive={isActive}
    onClick={onSelect}
    direction={direction}
  >
    {children}
  </Buttons.SortButton>
)
