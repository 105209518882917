import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

import { useProperty } from '../useProperty'

/**
 */
export const Label = ({
  render: Renderer = ValueRenderers.TextRenderer,
  language = 'en',
  ...props
}) => {
  const value = useProperty((data) => data.labels?.[language])

  return <Renderer value={value} {...props} />
}
