import React from 'react'

import image from './images/revit.svg'
import imageGray from './images/revit_gray.svg'
import * as styles from './styles.module.scss'

import { classNames } from '@arch-log/webapp.lib/CssHelper'

/**
 */
export const Revit = ({ className, grayscale = false }) => (
  <img
    src={ grayscale ? imageGray : image }
    alt="revit"
    title="revit"
    className={
      className ?? styles.Image
    }
  />
)
