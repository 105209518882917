import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const PublishedAt = ({
  render: Renderer = ValueRenderers.DateRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const DiscontinuedAt = ({
  render: Renderer = ValueRenderers.DateRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const TargetAreaCountry = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const SalesUnit = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Stock = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Packing = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Components = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const SampleBook = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}
