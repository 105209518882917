import {
  comparatorByProperty,
  Direction,
} from '@arch-log/webapp.lib/order'

/**
 * Enum of OrderBy Key
 */
export const OrderBy = {
  Name: 'name',
  PropertyType: 'property_type',
  ConstructionStartDate: 'construction_start_date',
  ConstructionEndDate: 'construction_end_date',
  UpdatedAt: 'updated_at',
  RegisteredAt: 'created_at',
}

/**
 */
export const SortKeys = [
  OrderBy.RegisteredAt,
  OrderBy.UpdatedAt,
  OrderBy.Name,
  OrderBy.PropertyType,
  OrderBy.ConstructionStartDate,
  OrderBy.ConstructionEndDate,
]

/**
 *
 */
export const Sorter = (key, direction = Direction.Desc) => {
  switch (key) {
    case OrderBy.RegisteredAt:
      return comparatorByProperty((entry) => entry.created_at, direction)
    case OrderBy.UpdatedAt:
      return comparatorByProperty((entry) => entry.updated_at, direction)
    case OrderBy.Name:
      return comparatorByProperty((entry) => entry.project_name, direction)
    case OrderBy.PropertyType:
      return comparatorByProperty((entry) => entry.property_type, direction)
    case OrderBy.ConstructionStartDate:
      return comparatorByProperty(
        (entry) => entry.construction_start,
        direction,
      )
    case OrderBy.ConstructionEndDate:
      return comparatorByProperty((entry) => entry.completion_date, direction)
    default:
      return (a, b) => 0
  }
}

/**
 */
export { Direction }
