import React from 'react'

import { Context } from './Context'

/**
 *
 */
export const Provider = ({ children, selectedIds, actions }) => {
  return (
    <Context.Provider
      value={{
        ...actions,
        selectedIds,
      }}
    >
      {children}
    </Context.Provider>
  )
}
