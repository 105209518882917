import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Entries = ({ children }) => (
  <div className={styles.Entries}>{children}</div>
)

/**
 */
export const Entry = ({ children }) => <>{children}</>
