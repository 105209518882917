import React from 'react'

import * as Styled from './Styled'

/**
 */
export const Back = ({
  onBack,
  components: { Wrapper = Styled.BackButton } = {},
}) => (
  <Wrapper onBack={onBack}>
    <Styled.Back />
  </Wrapper>
)
