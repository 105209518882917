import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation CopyProjectProducts(
    $fromProjectId: String!
    $toProjectId: String!
    $productIds: [String!]!
  ) {
    copyProjectProducts(fromProjectId: $fromProjectId,toProjectId: $toProjectId, productIds: $productIds) {
      projectId
      entries {
        id
        #name
        #code
        #manufacturer {
        #  displayNames {
        #    ja
        #    en
        #  }
        #}
        #images {
        #  url
        #  label
        #}
      }
    }
  }
`
