import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app')('BulkTagEditModal.Title')

export const DescriptionAdd = translated('app')(
  'BulkTagEditModal.DescriptionAdd',
)

export const DescriptionRemove = translated('app')(
  'BulkTagEditModal.DescriptionRemove',
)

export const TagGroupName = translated('app')('BulkTagEditModal.TagGroupName')

export const InputLabel = translated('app')('BulkTagEditModal.InputLabel')

export const Close = translated('app')('BulkTagEditModal.Close')

export const Append = translated('app')('BulkTagEditModal.Append')

export const Remove = translated('app')('BulkTagEditModal.Remove')

export const NamespaceNotDefined = translated('app')(
  'BulkTagEditModal.NamespaceNotDefined',
)

export const LinkToTags = translated('app')('BulkTagEditModal.LinkToTags')
