import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ProjectProductEntries(
    $projectV1Id: String
    $projectV2Id: String
    $offset: Int
    $size: Int
  ) {
    projectProductEntries(
      projectV1Id: $projectV1Id
      projectV2Id: $projectV2Id
      offset: $offset
      size: $size
    ) {
      entries {
        id
        label
        code
        family {
          label
        }
        status
        registeredAt
        # updatedAt
        history {
          codes
        }
        manufacturingInfo {
          manufacturer {
            name
            labels {
              ja
              en
            }
            shortLabels {
              ja
              en
            }
          }
          oem
          countryOfManufactured
          countryOfOrigin
        }
        salesInfo {
          publishedAt
          discontinuedAt
          prices {
            name
            value
            valueDirty
            unitType
            unitLabel
            note
          }
          targetAreaCountry
          salesUnit
          stock
          packing
          components
          sampleBook
          provider {
            name
            labels {
              ja
              en
            }
            shortLabels {
              ja
              en
            }
          }
        }
        categories {
          name
          # labels {
          #   ja
          #   en
          # }
        }
        catalogs {
          label
          pages
        }
        medias {
          type
          url
          label
          thumbnails {
            name
            url
          }
        }
        measurements {
          name
          values {
            path
            name
            value
            valueDirty
            unitLabel
            unitType
            note
          }
        }
        archlogExtended {
          seos
        }
        classifications {
          name
          value
          valueDirty
        }
        specifications {
          name
          value
          valueDirty
          unitLabel
          unitType
          note
        }
        standards {
          name
          value
          valueDirty
          note
        }
        ecos {
          name
          value
          valueDirty
          note
        }
        appearances {
          name
          value
          valueDirty
          unitLabel
          unitType
          note
        }
        links {
          url
          label
        }
        bimInfo {
          author
          bimType
          isProxySupported
          links {
            url
            label
          }
          plugins {
            revit {
              url
            }
            archicad {
              url
            }
            sketchup {
              url
            }
          }
          revitExtended {
            omniclass {
              value
              version
            }
            uniformatAssemblyCode {
              value
              version
            }
            familyCategoryId
            familyCategoryLabels {
              ja
              en
            }
          }
        }
        features
        notices
        notes
        materials {
          name
          value
          valueDirty
        }
        # userTags {
        #   name
        #   tags
        # }
        # tags
        addedAt
        raw
      }
      total
      offset
      requestSize
    }
  }
`
