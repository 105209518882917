import React from 'react'

import * as Filter from './Filter'
import * as Sort from './Sort'
import {
  OrderBy,
  Direction,
} from '@arch-log/webapp.modules/project/order'

export const Initializer = ({ children, manufacturers, categories }) => (
  <Filter.Initializer categories={categories} manufacturers={manufacturers}>
    <Sort.Initializer
      defaultSortKey={OrderBy.AddedAt}
      defaultDirection={Direction.Desc}
    >
      {children}
    </Sort.Initializer>
  </Filter.Initializer>
)
