import React from 'react'

import { Context } from './Context'
import { useList } from 'react-use'

/**
 */
export const Initializer = ({
  entries: defaultEntries = [],
  children,
  refreshOnDefaultUpdate = true,
}) => {
  const [entries, { set, push, sort, filter}] = useList(defaultEntries)

  React.useEffect(() => {
    if (refreshOnDefaultUpdate) {
      set(defaultEntries)
    }
  }, [defaultEntries])

  const reset = () => {
    set(defaultEntries)
  }

  return (
    <Context.Provider value={{ entries, set, push, sort, filter, reset }}>
      {children}
    </Context.Provider>
  )
}
