import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation AddProjectProducts($projectId: String!, $entries: [AddProjectProductsInput_Entry!]!) {
    addProjectProducts(projectId: $projectId, entries: $entries) {
      projectId
      entries {
        id
        #name
        #code
        #manufacturer {
        #  displayNames {
        #    ja
        #    en
        #  }
        #}
        #images {
        #  url
        #  label
        #}
      }
    }
  }
`
