import { gql } from '@apollo/client'

export const query = gql`
  query ValidateDeleteProjectProductEntries(
    $projectId: UUID!
    $productIds: [UUID!]!
  ) {
    validateDeleteProjectProductEntries(
      projectId: $projectId
      productIds: $productIds
    ) {
      entries {
        productId
        isDeletable
        caused
      }
    }
  }
`
