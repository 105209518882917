import React from 'react'

import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'

import * as LocalCondition from './LocalCondition'
import * as Category from './Category'
import * as Manufacturer from './Manufacturer'

///**
// *
// */
export const Initializer = ({ children, categories, manufacturers }) => (
  <LocalCondition.Initializer>
    <Category.Initializer enables={categories}>
      <Manufacturer.Initializer enables={manufacturers}>
        <Applier>{children}</Applier>
      </Manufacturer.Initializer>
    </Category.Initializer>
  </LocalCondition.Initializer>
)

const Applier = ({ children }) => {
  const { selected: category = null } = React.useContext(
    Category.Selected.Context,
  )

  const { condition } = React.useContext(LocalCondition.Context)

  const { selected: manufacturer } = React.useContext(
    Manufacturer.Selected.Context,
  )

  return (
    <AddedProducts.Filter
      filterByCategory={category}
      filterByManufacturer={manufacturer}
      filterByKeyword={condition.keyword}
      filterByBim={condition.onlyBimSupport}
    >
      {children}
    </AddedProducts.Filter>
  )
}
