import React from 'react'

import { useFormContext } from 'react-hook-form'

import * as FieldNames from './FieldNames'
import * as Inputs from '@arch-log/webapp.atomics/components/Form/Input.v2/Renderers'

import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const DeletionConfirmation = ({
  render: Renderer = Inputs.Text,
  ...props
}) => {
  const { register } = useFormContext()

  const { t } = useTranslation('app')
  const placeholder = t(
    'BulkDeleteProductModal.Placeholder.DeletionConfirmation',
  )

  return (
    <Renderer
      placeholder={placeholder}
      {...register(FieldNames.DeletionConfirmation, {
        validate: (value) => placeholder === value,
      })}
      {...props}
    />
  )
}
