import { Context } from './Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import * as Renderers from '@arch-log/webapp.modules/product.v2/components/PropertyRenderers/Category'

/**
 * Scoped Renderer
 */
export const Name = withContextValue(Context, (data) => data.name)(Renderers.Name)

export const Label = withContextValue(Context, (data) => data.labels)(Renderers.Label)

