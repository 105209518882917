import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import * as Selected from './Selected'
import * as Styled from './Styled'
import * as Literals from './Literals'

import {
  OrderBy,
  Direction,
} from '@arch-log/webapp.modules/product/order'

/**
 */
const Title = withDefaultRenderComponent(
  ({ components: { Decorator = Styled.Title } = {} }) => (
    <Decorator>
      <Literals.Title />
    </Decorator>
  ),
)

/**
 */
export const SortBy = ({
  sortKey,
  defaultDirection,
  components: { Decorator = Styled.SortBy } = {},
}) => {
  const {
    key: curSortKey,
    direction: curSortDirection,
    orderBy,
  } = React.useContext(Selected.Context)

  const sortBy = (sortKey, defaultDirection) => {
    if (curSortKey === sortKey) {
      orderBy(sortKey, Direction.oposite(curSortDirection))
    } else {
      orderBy(sortKey, defaultDirection)
    }
  }
  const isActive = (name) => name === curSortKey

  return (
    <Decorator
      onSelect={() => {
        sortBy(sortKey, defaultDirection)
      }}
      isActive={isActive(sortKey)}
      direction={curSortDirection}
    >
      {children}
    </Decorator>
  )
}

/**
 */
export const SortByProductName = () => (
  <SortBy sortKey={OrderBy.ProductName} defaultDirection={Direction.Desc}>
    <Literals.ProductName />
  </SortBy>
)

/**
 */
export const SortByPopularity = () => (
  <SortBy sortKey={OrderBy.Popularity} defaultDirection={Direction.Desc}>
    <Literals.Popularity />
  </SortBy>
)

/**
 */
export const SortByRegisteredAt = () => (
  <SortBy sortKey={OrderBy.RegisteredAt} defaultDirection={Direction.Desc}>
    <Literals.RegisteredAt />
  </SortBy>
)

/**
 */
export const SortByProductCode = () => (
  <SortBy sortKey={OrderBy.ProductCode} defaultDirection={Direction.Desc}>
    <Literals.ProductCode />
  </SortBy>
)

/**
 */
export const SortByManufacturer = () => (
  <SortBy sortKey={OrderBy.Manufacturer} defaultDirection={Direction.Desc}>
    <Literals.Manufacturer />
  </SortBy>
)

/**
 */
export const SortByCategory = () => (
  <SortBy sortKey={OrderBy.Category} defaultDirection={Direction.Desc}>
    <Literals.Category />
  </SortBy>
)
