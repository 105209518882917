import React from 'react'

import {
  withCustomComponents,
  withDefaultRenderComponent,
} from '@arch-log/webapp.atomics/components/Rules'
import * as Base from '@arch-log/webapp.shared/AddedProduct.v2'

import * as List from './List'

/**
 */
export const Default = withCustomComponents(Base.Default, {
  List: {
    render: List.Default,
  },
})
