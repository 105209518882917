import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const NamespacesWrapper = ({ children })  => (
  <dl className={ styles.Namespaces }>{children}</dl>
)

/**
 */
export const NamespaceWrapper = ({ children })  => (
  <dl className={ styles.Namespace }>{children}</dl>
)

/**
 */
export const NamespaceTitleSection = ({ children }) => (
  <dt className={styles.NamespaceTitle }>{children}</dt>
)

/**
 */
export const NamespaceTagsSection = ({ children }) => (
  <dd className={styles.NamespaceTags}>
    { children }
  </dd>
)
