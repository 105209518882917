import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const CloseButton = ({ children, onClick }) => {
  return (
    <button type="button" onClick={onClick} className={styles.CloseButton}>
      {children}
    </button>
  )
}

export const TagNameInput = ({ value, onChangeValue }) => (
  <input
    className={styles.TagNameInput}
    type="text"
    value={value}
    onChange={(e) => onChangeValue(e.target.value, e)}
  />
)

export const AppendButton = ({ onClick, children }) => (
  <button onClick={onClick} type="button" className={styles.AppendButton}>
    {children}
  </button>
)

export const RemoveButton = ({ onClick, children }) => (
  <button onClick={onClick} type="button" className={styles.RemoveButton}>
    {children}
  </button>
)

export const ButtonContainer = ({ children }) => (
  <div className={styles.ButtonContainer}>{children}</div>
)

export const Title = ({ children }) => (
  <h2 className={styles.Title}>{children}</h2>
)

export const InputLabel = ({ children }) => (
  <h3 className={styles.InputLabel}>{children}</h3>
)

export const SelectLabel = ({ children }) => (
  <h3 className={styles.SelectLabel}>{children}</h3>
)

export const DescriptionContainer = ({ children }) => (
  <div className={styles.Description_Container}>{children}</div>
)

export const DescriptionSection = ({ children }) => (
  <p className={styles.Description_Text}>{children}</p>
)

export const NamespaceNotDefinedDiscription = ({ children }) => (
  <div className={styles.NamespaceNotDefined_Discription}>{children}</div>
)
