import React from 'react'

import * as BaseSorter from '@arch-log/webapp.shared/AddedProduct.v2/Sorter'
import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 *
 */
export const Default = ({
  components: {
    Title: titleComponents,
    keyword: keywordComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      Body = Layout.Body,
    } = {},
  } = {},
}) => {
  return (
    <Wrapper>
      <Header>
        <Containers.Title components={titleComponents} />
      </Header>
      <Body>
        <BaseSorter.SortByProductName />
        <BaseSorter.SortByRegisteredAt />
        <BaseSorter.SortByAddedAt />
        <BaseSorter.SortByProductCode />
        <BaseSorter.SortByManufacturer />
        <BaseSorter.SortByCategory />
      </Body>
    </Wrapper>
  )
}
