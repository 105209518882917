import React from 'react'

import { query } from './query'

import { Context } from './Context'
import { useQuery } from '@apollo/client'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

import * as Errors from '@arch-log/webapp.lib/errors'

/**
 */
export const Loader = ({
  //
  components: { Loading = null } = {},
  render: Renderer = ChildrenRenderer,
  throwNetworkError = true,
  throwError = false,
  ...props
}) => {
  const data = useQuery(query)

  const { loading, error } = data

  if (error && throwNetworkError) {
    Errors.handleGraphqlError(error)
  }

  if (error && throwError) {
    throw error
  }

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider value={data}>
      <Renderer {...data} {...props} />
    </Context.Provider>
  )
}
