import React from 'react'

import { Context } from './Context'

import * as Categories from '@arch-log/webapp.modules/product-category.v2/components/Scope/Categories'
import * as Referenced from './Referenced'

/**
 */
export const Initializer = ({ value, ...props }) => {
  const InnerComponent =
    value === undefined ? InnerWithDefaultValue : InnerWithValue

  return (
    <Categories.InitializeAllEntriesWithRemote>
      <InnerComponent value={value} {...props} />
    </Categories.InitializeAllEntriesWithRemote>
  )
}

/**
 */
const InnerWithDefaultValue = ({
  onSelected,
  defaultValue = null,
  ...props
}) => {
  const [selected, setSelected] = React.useState(defaultValue)

  return (
    <InnerWithValue
      {...props}
      value={selected}
      onSelected={(v) => {
        setSelected(v)
        if (onSelected) {
          onSelected(v)
        }
      }}
    />
  )
}

/**
 */
const InnerWithValue = ({ children, value = null, onSelected }) => {
  const { entries } = React.useContext(Categories.Context)

  const [hierarchy, setHierarchy] = React.useState([])

  const category = value ? entries.find((cat) => cat.id === value) : null

  //
  React.useEffect(() => {
    if (category) {
      setHierarchy([category])
    }
  }, [])

  const reset = () => {
    onSelected(null)
    setHierarchy([])
  }

  const push = (value) => {
    const category = entries.find((cat) => cat.id === value)

    if (category) {
      setHierarchy([...hierarchy, category])
      onSelected(value)
    }
  }

  const pop = () => {
    const poped = hierarchy.slice(0, hierarchy.length - 1)

    setHierarchy(poped)

    onSelected(poped[poped.length - 1]?.id ?? null)
  }

  const popUntil = (value) => {
    const poped = hierarchy.slice(
      0,
      hierarchy.findIndex((v) => v.id === value),
    )
    setHierarchy(poped)

    onSelected(poped[poped.length - 1]?.id ?? null)
  }

  const replace = (id) => {
    const category = id ? entries.find((cat) => cat.id === id) : null
    setHierarchy([category])

    onSelected(id)
  }

  return (
    <Referenced.Provider category={category}>
      <Context.Provider
        value={{
          selected: value,
          category,
          hierarchy,
          push,
          pop,
          popUntil,
          reset,
          replace,
        }}
      >
        {children}
      </Context.Provider>
    </Referenced.Provider>
  )
}
