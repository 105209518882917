import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'

import { createGroup } from './utils'
import * as Components from './Components'

//import * as PricesFields from "./Prices"
import * as ClassificationsFields from './Classifications'
import * as IdentifiersFields from './Identifiers'
import * as AppearancesFields from './Appearances'
import * as BasicInformationsFields from './BasicInformations'
import * as MaterialsFields from './Materials'
import * as StandardsFields from './Standards'
import * as SalesDatesFields from './SalesDates'
import * as EcosFields from './Ecos'
import * as SpecificationsFields from './Specifications'
import * as ArchitecturalClassificationFields from './ArchitecturalClassification'

/**
 */
export const Prices = createGroup(
  'Prices',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <Product.Properties.Costs.Sort
        sortKeys={Product.Properties.Costs.SortKeys}
      >
        <Product.Properties.Costs.Each>
          <Container>
            <Label>
              <Product.Properties.Cost.Label
                language={language}
                formatter={(v) => t(`Prices.${v}`)}
              />
            </Label>
            <Value>
              <Product.Properties.Cost.Value language={language} />
              <Product.Properties.Cost.Unit language={language} />
              <Product.Properties.Cost.Note
                prefix="("
                suffix=")"
                language={language}
              />
            </Value>
          </Container>
        </Product.Properties.Costs.Each>
      </Product.Properties.Costs.Sort>
    )
  },
  Product.Properties.HasCosts,
)

/**
 */
export const Classifications = createGroup(
  'Classifications',
  (props) => (
    <>
      <ClassificationsFields.Basic {...props} />
      <ClassificationsFields.Lightning {...props} />
    </>
  ),
  Product.Properties.HasClassifications,
)

/**
 */
export const Identifiers = createGroup(
  'Identifiers',
  (props) => (
    <>
      <IdentifiersFields.Author {...props} />
      <IdentifiersFields.BrandName {...props} />
      <IdentifiersFields.ProductFamily {...props} />
      <IdentifiersFields.ProductName {...props} />
      <IdentifiersFields.ProductId {...props} />
      <IdentifiersFields.ManufacturerCode {...props} />
      <IdentifiersFields.OldProductIds {...props} />
      <IdentifiersFields.ManufacturerUrl {...props} />
    </>
  ),
  Product.Properties.HasIdentifier,
)

/**
 */
export const Appearances = createGroup(
  'Appearances',
  (props) => (
    <>
      <AppearancesFields.Name {...props} />
      <AppearancesFields.Style {...props} />
      <AppearancesFields.Color {...props} />
      <AppearancesFields.Texture {...props} />
      <AppearancesFields.PatternType {...props} />
      <AppearancesFields.Shape {...props} />
    </>
  ),
  Product.Properties.HasAppearances,
)

/**
 */
export const BasicInformations = createGroup(
  'BasicInformations',
  (props) => (
    <>
      <BasicInformationsFields.SampleBook {...props} />
      <BasicInformationsFields.Components {...props} />
      <BasicInformationsFields.Stock {...props} />
      <BasicInformationsFields.Packing {...props} />
      <BasicInformationsFields.SalesUnits {...props} />
      <BasicInformationsFields.OemManufacturer {...props} />
      <BasicInformationsFields.ManufacturedIn {...props} />
      <BasicInformationsFields.CountryOfOrigin {...props} />
      <BasicInformationsFields.ShippingTargetAreaCountry {...props} />
      <BasicInformationsFields.Catalog {...props} />
    </>
  ),
  Product.Properties.HasBasicInformation,
)

/**
 */
export const Materials = createGroup(
  'Materials',
  (props) => (
    <>
      <MaterialsFields.Basic {...props} />
      <MaterialsFields.Composite {...props} />
      <MaterialsFields.Carpet {...props} />
      <MaterialsFields.Coating {...props} />
    </>
  ),
  Product.Properties.HasMaterials,
)

/**
 */
export const Standards = createGroup(
  'Standards',
  (props) => (
    <>
      <StandardsFields.FireRetardant {...props} />
      <StandardsFields.SoundInsulation {...props} />
      <StandardsFields.Standard {...props} />
      <StandardsFields.Environment {...props} />
      <StandardsFields.Other {...props} />
    </>
  ),
  Product.Properties.HasStandards,
)

/**
 */
export const SalesDates = createGroup(
  'SalesDates',
  (props) => (
    <>
      <SalesDatesFields.DateInformation {...props} />
    </>
  ),
  Product.Properties.HasSalesInfo,
)

/**
 */
export const Ecos = createGroup(
  'Ecos',
  (props) => (
    <>
      <EcosFields.EcoLabel {...props} />
    </>
  ),
  Product.Properties.HasEcos,
)

/**
 */
export const Specifications = createGroup(
  'Specifications',
  (props) => (
    <>
      <SpecificationsFields.Installation {...props} />
      <SpecificationsFields.Resistance {...props} />
      <SpecificationsFields.SpecialProcess {...props} />
      <SpecificationsFields.PhysicalResistance {...props} />
      <SpecificationsFields.SpecificationsAndOther {...props} />
      <SpecificationsFields.FumeHood {...props} />
      <SpecificationsFields.PaintAndPlastererMaterials {...props} />
      <SpecificationsFields.Lightning {...props} />
      <SpecificationsFields.Joinery {...props} />
      <SpecificationsFields.FireFightning {...props} />
      <SpecificationsFields.MotorEvokedPotential {...props} />
      <SpecificationsFields.Electric {...props} />
      <SpecificationsFields.Machine {...props} />
      <SpecificationsFields.FacilityManagement {...props} />
      <SpecificationsFields.AirConditionEquipment {...props} />
      <SpecificationsFields.WaterSupplyEquipment {...props} />
    </>
  ),
  Product.Properties.HasSpecifications,
)

/**
 */
export const ArchitecturalClassification = createGroup(
  'ArchitecturalClassification',
  (props) => (
    <>
      <ArchitecturalClassificationFields.OmniclassNumber {...props} />
      <ArchitecturalClassificationFields.OmniclassVersion {...props} />
      <ArchitecturalClassificationFields.UniformatCode {...props} />
      <ArchitecturalClassificationFields.UniformatVersion {...props} />
      <ArchitecturalClassificationFields.RevitFamilyCategoryId {...props} />
      <ArchitecturalClassificationFields.RevitFamilyCategoryNameJa {...props} />
      <ArchitecturalClassificationFields.RevitFamilyCategoryNameEn {...props} />
    </>
  ),
  Product.Properties.HasArchitecturalClassification,
)

/**
 */
export const Measurements = createGroup(
  'Measurements',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Field: {
        Container: FieldContainer = DefaultComponents.FieldContainer,
        Label: FieldLabel = DefaultComponents.FieldLabel,
        Value: FieldValue = DefaultComponents.FieldValue,
      } = {},
      Group: {
        Container: GroupContainer = DefaultComponents.FieldGroupContainer,
        Label: GroupLabel = DefaultComponents.FieldGroupLabel,
        Value: GroupValue = DefaultComponents.FieldGroupValue,
      } = {},
    } = components

    return (
      <Product.Properties.MeasurementGroups.Each>
        <GroupContainer>
          <GroupLabel>
            <Product.Properties.MeasurementGroup.Label
              formatter={(v) => t(`MeasurementGroups.${v.replace(' ', '_')}`)}
            />
          </GroupLabel>
          <GroupValue>
            <Product.Properties.MeasurementGroup.Measurements.Each>
              <FieldContainer>
                <FieldLabel>
                  <Product.Properties.MeasurementGroup.Measurement.Label
                    formatter={(v) => t(`Measurements.${v}`)}
                  />
                </FieldLabel>
                <FieldValue>
                  <Product.Properties.MeasurementGroup.Measurement.Value />
                  <Product.Properties.MeasurementGroup.Measurement.Unit />
                  <Product.Properties.MeasurementGroup.Measurement.Note
                    prefix="("
                    suffix=")"
                  />
                </FieldValue>
              </FieldContainer>
            </Product.Properties.MeasurementGroup.Measurements.Each>
          </GroupValue>
        </GroupContainer>
      </Product.Properties.MeasurementGroups.Each>
    )
  },
  Product.Properties.HasMeasurementGroups,
)
