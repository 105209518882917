import React from 'react'

import * as Languages from '@arch-log/webapp.modules/i18n/components/Languages'
import * as ProductRef from '@arch-log/webapp.modules/product/components/Reference'
import * as ScopeCategory from '@arch-log/webapp.modules/product-category.v2/components/Scope/Category'
import { createField, createFieldWithRows } from './utils'
import * as Components from './Components'
import { WebLink as DispatchLog } from '@arch-log/webapp.modules/log/components/Dispatches'

import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import * as ConfirmModal from '@arch-log/webapp.shared/ConfirmModal'
import * as Literals from './Literals'

/**
 */
export const ProductId = createField(
  'ProductId',
  ProductRef.Properties.ProductId,
  ProductRef.Properties.HasProductId,
)

/**
 */
export const ProductFamily = createField(
  'ProductFamily',
  ProductRef.Properties.Identifier.ProductFamily,
  ProductRef.Properties.Identifier.HasProductFamily,
)

/**
 */
export const ManufacturerName = createField(
  'ManufacturerName',
  ({ language }) => (
    <ProductRef.Properties.ManufacturerName
      showFullname={true}
      language={language}
    />
  ),
)

/**
 */
export const Name = createField('Name', ProductRef.Properties.ProductName)

/**
 */
export const Author = createField(
  'AuthorName',
  ProductRef.Properties.AuthorName,
)

/**
 */
export const Code = createField('Code', ProductRef.Properties.ProductCode)

/**
 */
export const Categories = createFieldWithRows(
  'Categories',
  ({ components = {} }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      List = DefaultComponents.List,
      ListItem = DefaultComponents.ListItem,
    } = components

    const { language } = React.useContext(Languages.Context)

    return (
      <List>
        <ProductRef.Properties.Categories.Each>
          <ListItem>
            <ProductRef.Properties.Category.Value
              render={({ value }) => (
                <ScopeCategory.InitializeWithRemote id={ value.toLowerCase() }>
                  <ScopeCategory.Properties.Label language={ language }/>
                </ScopeCategory.InitializeWithRemote>
              )}
            />
          </ListItem>
        </ProductRef.Properties.Categories.Each>
      </List>
    )
  },
  ProductRef.Properties.HasCategories,
)

/**
 */
export const Ieses = createFieldWithRows(
  'Ies',
  ({ disableLinks = false, components = {} }) => {
    const { product } = React.useContext(ProductRef.Context)
    const DefaultComponents = React.useContext(Components.Context)

    const {
      List = DefaultComponents.List,
      ListItem = DefaultComponents.ListItem,
    } = components

    const { showConfirm } = ConfirmModal.useConfirmModal()

    return (
      <List>
        <ProductRef.Properties.HasIeses>
          <ProductRef.Properties.Ieses.Each>
            <ListItem>
              <DispatchLog
                render={({ dispatchLog }) => {
                  if (disableLinks) {
                    return (
                      <ProductRef.Properties.Ies.Link
                        disableLink={true}
                        onClick={(e) => {
                          e.preventDefault()
                          showConfirm({
                            message: <Literals.NotInProjectMessage />,
                            cancel: false,
                          })
                        }}
                      />
                    )
                  }
                  return (
                    <ProductRef.Properties.Ies.Link
                      onClick={(e) => {
                        dispatchLog(e.target.href, product)
                      }}
                    />
                  )
                }}
              />
            </ListItem>
          </ProductRef.Properties.Ieses.Each>
        </ProductRef.Properties.HasIeses>
        <ProductRef.Properties.Specifications.Has label={'ies_download_url'}>
          <ProductRef.Properties.Specifications.One label="ies_download_url">
            <ListItem>
              <ProductRef.Properties.Specification.Value
                render={Renderers.LinkRenderer}
                target="_blank"
                download
                rel="noreferrer noopener"
              />
            </ListItem>
          </ProductRef.Properties.Specifications.One>
        </ProductRef.Properties.Specifications.Has>
      </List>
    )
  },
  ({ children }) => {
    const { product } = React.useContext(ProductRef.Context)

    if (
      (product?.ies && product?.ies?.length > 0) ||
      product?.specifications?.ies_download_url
    ) {
      return <>{children}</>
    }
    return null
  }, // Default Condition
)

/**
 */
export const Links = createFieldWithRows(
  'Links',
  ({ disableLinks = false, components = {} }) => {
    const { product } = React.useContext(ProductRef.Context)
    const DefaultComponents = React.useContext(Components.Context)

    const { showConfirm } = ConfirmModal.useConfirmModal()

    const {
      List = DefaultComponents.List,
      ListItem = DefaultComponents.ListItem,
    } = components

    return (
      <List>
        <ProductRef.Properties.Links.Each>
          <ListItem>
            <DispatchLog
              render={({ dispatchLog }) => {
                if (disableLinks) {
                  return (
                    <ProductRef.Properties.Links.Link
                      disableLink={true}
                      onClick={(e) => {
                        e.preventDefault()
                        showConfirm({
                          message: <Literals.NotInProjectMessage />,
                          cancel: false,
                        })
                      }}
                    />
                  )
                }
                return (
                  <ProductRef.Properties.Links.Link
                    onClick={(e) => {
                      dispatchLog(e.target.href, product)
                    }}
                  />
                )
              }}
            />
          </ListItem>
        </ProductRef.Properties.Links.Each>
      </List>
    )
  },
  ProductRef.Properties.HasLinks,
)

/**
 */
export const Features = createFieldWithRows(
  'Features',
  ({ components = {}, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      List = DefaultComponents.List,
      ListItem = DefaultComponents.ListItem,
    } = components

    return (
      <List>
        <ProductRef.Properties.Features.Each>
          <ListItem>
            <ProductRef.Properties.Feature.Value />
          </ListItem>
        </ProductRef.Properties.Features.Each>
      </List>
    )
  },
  ProductRef.Properties.HasFeatures,
)

/**
 */
export const Notices = createFieldWithRows(
  'Notices',
  ({ components = {}, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      List = DefaultComponents.List,
      ListItem = DefaultComponents.ListItem,
    } = components

    return (
      <List>
        <ProductRef.Properties.Notices.Each>
          <ListItem>
            <ProductRef.Properties.Notice.Value />
          </ListItem>
        </ProductRef.Properties.Notices.Each>
      </List>
    )
  },
  ProductRef.Properties.HasNotices,
)

/**
 * FIXME
 */
export const Notes = createFieldWithRows(
  'Notes',
  ({ components = {} }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      List = DefaultComponents.List,
      ListItem = DefaultComponents.ListItem,
    } = components

    return (
      <List>
        <ProductRef.Properties.Notes.Each>
          <ListItem>
            <ProductRef.Properties.Note.Value />
          </ListItem>
        </ProductRef.Properties.Notes.Each>
      </List>
    )
  },
  ProductRef.Properties.HasNotes,
)
