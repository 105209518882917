import React from 'react'

import { query } from './query'

import { Context } from './Context'
import { useQuery } from '@apollo/client'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Loader = ({
  //
  parent = null,
  components: { Loading = null } = {},
  render: Renderer = ChildrenRenderer,
  throwGatewayError = true,
  throwNetworkError = true,
  throwError = false,
  ...props
}) => {
  const data = useQuery(query, { variables: { parent } })

  const { loading } = data

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider value={data}>
      <Renderer {...data} {...props} />
    </Context.Provider>
  )
}
