import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Components from '../Components'
import { createField } from '../utils'

/**
 */
export const Name = createField(
  'Appearances_Name',
  ({ components = {}, language = null, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <Product.Properties.Appearances.OneBy label="name">
        <Container>
          <Value>
            <Product.Properties.Appearance.Value />
          </Value>
        </Container>
      </Product.Properties.Appearances.OneBy>
    )
  },
  (props) => <Product.Properties.Appearances.Has label="name" {...props} />,
)

/**
 */
export const Style = createField(
  'Appearances_Style',
  ({ components = {}, language = null, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Value = DefaultComponents.FieldValue,
    } = components
    return (
      <Product.Properties.Appearances.OneBy label="style">
        <Container>
          <Value>
            <Product.Properties.Appearance.Value />
          </Value>
        </Container>
      </Product.Properties.Appearances.OneBy>
    )
  },
  (props) => <Product.Properties.Appearances.Has label="style" {...props} />,
)
