import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

import { USAGE_SERVICE_TAG } from '@arch-log/webapp.modules/project.product.v2/consts'

/**
 */
export const Cancel = translated('app')('BulkDeleteProductModal.Cancel')

/**
 */
export const Submit = translated('app')('BulkDeleteProductModal.Submit')

/**
 */
export const Accept = translated('app')('BulkDeleteProductModal.Confirm.Accept')

/**
 */
export const Progress = translated('app')('BulkDeleteProductModal.Progress')

/**
 */
export const QueryProgress = translated('app')(
  'BulkDeleteProductModal.QueryProgress',
)

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('app')

  const formatFormError = (key, ...options) =>
    t(`BulkDeleteProductModal.Form.Error.${key}`, ...options)

  return {
    formatFormError,
  }
}

/**
 */
export const useConvertTagToServiceDescription = ({ tag }) => {
  const { t } = useTranslation('app')

  const key = ((tag) => {
    switch (tag) {
      case USAGE_SERVICE_TAG.FT:
        return 'BulkDeleteProductModal.Service.Ft.Description'

      default:
        return ''
    }
  })(tag)

  return {
    description: t(key),
  }
}

/**
 */
export const useConvertTagToServiceName = ({ tag }) => {
  const { t } = useTranslation('app')

  const key = ((tag) => {
    switch (tag) {
      case USAGE_SERVICE_TAG.FT:
        return 'BulkDeleteProductModal.Service.Ft.Name'

      default:
        return ''
    }
  })(tag)

  return {
    name: t(key),
  }
}

/**
 */
export const useProductValue = ({ product }) => {
  const {
    i18n: { language },
  } = useTranslation()

  const code = product.code
  const providerLabel = product.salesInfo.provider.labels[language] ?? ''

  return {
    code,
    providerLabel,
  }
}

/**
 */
export const formatProductProperty = ({
  code,
  providerLabel,
  separator = ' / ',
}) => `${code}${separator}${providerLabel}`

/**
 */
export const Title = translated('app')('BulkDeleteProductModal.Title')

/**
 */
export const Description = translated('app')(
  'BulkDeleteProductModal.Description',
)

/**
 */
export const SubDescription = translated('app')(
  'BulkDeleteProductModal.SubDescription',
)

/**
 */
export const ConfirmMessage = translated('app')(
  'BulkDeleteProductModal.Confirm.Message',
)

/**
 */
export const Placeholder = translated('app')(
  'BulkDeleteProductModal.Placeholder.DeletionConfirmation',
)

/**
 */
export const FormDescription = translated('app')(
  'BulkDeleteProductModal.FormDescription',
)
