import React from 'react'

import * as Content from './Content'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'

export const useActions = () => {
  const { show: showModal, hide } = React.useContext(Modal.Context)

  const show = (filtered, options) => {
    showModal(
      () => (
        <Content.Default
          products={filtered}
          onOk={() => {
            hide()
          }}
        />
      ),
      options,
    )
  }

  return {
    show,
    hide,
  }
}
