import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const [pattern, setPattern] = React.useState('')

  const resetPattern = () => {
    setPattern('')
  }

  return (
    <Context.Provider value={{ pattern, setPattern, resetPattern }}>
      {children}
    </Context.Provider>
  )
}
