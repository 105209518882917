import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('module.project.product')('Empty.Message')

/**
 */
export const SearchProducts = translated('app')('ProductList.SearchProducts')
