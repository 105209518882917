import React from 'react'

import { query } from './query.v2'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Context } from './Context'
import * as Scoped from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntries'
import { useAsync, useStateList } from 'react-use'

import { runSeq } from '@arch-log/webapp.lib/PromiseHelper'

/**
 */
export const AllLoader = ({
  children,
  projectId,
  size = 100,
  components: { Loading = React.Fragment } = {},
  ...props
}) => {
  const [error, setError] = React.useState(false)
  const [entries, setEntries] = React.useState([])
  const [loadingList, setLoadingList] = React.useState([])

  const loading = React.useMemo(
    () => !loadingList.length || loadingList.some((loging) => loging),
    [loadingList],
  )

  const [loadEntries] = useLazyQuery(query, { ...props })

  useAsync(async () => {
    const { data } = await loadEntries({
      variables: {
        projectId,
        size: 0,
      },
    })

    const total = data?.projectProductEntries?.total
    const counter = Array.from(
      { length: Math.ceil(total / size) },
      (_, index) => index,
    )

    setLoadingList(counter.map(() => true))

    return await runSeq(counter, async (index) => {
      await loadEntries({
        variables: {
          projectId,
          offset: size * index,
          size,
        },
        onCompleted: (data) => {
          if (!data?.projectProductEntries?.entries) {
            setError(true)

            return
          }

          setEntries((prev) =>
            [...prev, ...data?.projectProductEntries?.entries].filter((v) => v),
          )

          setLoadingList((prev) =>
            prev.map((loading, i) => (i === index ? false : loading)),
          )
        },
      })
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        entries,
        loading,
      }}
    >
      <Scoped.Initializer entries={entries}>{children}</Scoped.Initializer>
    </Context.Provider>
  )
}
