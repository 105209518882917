import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

export const NotInProjectMessage = translated('module.product')(
  'Link.NotInProjectMessage',
)

export const useLiterals = () => {
  const { t } = useTranslation('module.product')

  const formatLabel = (key, ...options) => {
    return t(`Field.Label.${key}`, ...options)
  }

  return {
    t,
    formatLabel,
  }
}
