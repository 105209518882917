import { gql } from '@apollo/client'

export const query = gql`
  query projectProductEntries($projectId: UUID!, $offset: Int, $size: Int) {
    projectProductEntries(
      projectV2Id: $projectId
      offset: $offset
      size: $size
    ) {
      entries {
        id
        oldId
        code
        # tags
        family {
          label
        }
        manufacturingInfo {
          manufacturer {
            name
            labels {
              ja
              en
            }
            shortLabels {
              ja
              en
            }
          }
          countryOfOrigin
          countryOfManufactured
        }
        salesInfo {
          provider {
            name
            labels {
              ja
              en
            }
            shortLabels {
              ja
              en
            }
          }
          publishedAt
          discontinuedAt
          targetAreaCountry
          salesUnit
          stock
          packing
          components
          sampleBook
          prices {
            valueDirty
            value
            unitLabel
            unitType
          }
        }
        categories {
          name
        }
        catalogs {
          label
          pages
        }
        measurements {
          name
          values {
            path
            name
            valueDirty
            unitLabel
          }
        }
        links {
          url
          label
        }
        bimInfo {
          bimType
          isProxySupported
          links {
            url
            label
          }
          plugins {
            revit {
              url
            }
            archicad {
              url
            }
            sketchup {
              url
            }
          }
        }
        specifications {
          valueDirty
          unitLabel
          note
        }
        standards {
          name
          valueDirty
          note
        }
        materials {
          name
          valueDirty
        }
        addedAt
      }
      total
      offset
      requestSize
    }
  }
`
