import React from 'react'

import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

/**
 */
export const useActions = () => {
  const { show: showModal, hide } = React.useContext(Modal.Context)

  const show = ({ copyProductIds, fromProject }) => {
    showModal(() => (
      <Content.Default
        copyProducts={copyProductIds}
        fromProject={fromProject}
      />
    ))
  }

  return {
    show,
    hide,
  }
}
