// extracted by mini-css-extract-plugin
export var CompareButton = "styles-module--CompareButton--F70Vj";
export var FieldsWrapper = "styles-module--FieldsWrapper--Y4zNX";
export var Icon = "styles-module--Icon--cengB";
export var Image = "styles-module--Image--TdAqH";
export var Layout_ButtonsAndIcons = "styles-module--Layout_ButtonsAndIcons--fSppl";
export var Layout_Image = "styles-module--Layout_Image--HmlQg";
export var Layout_ImageWrapper = "styles-module--Layout_ImageWrapper--jbMnd";
export var Layout_Info = "styles-module--Layout_Info--cAcjh";
export var Layout_Wrapper = "styles-module--Layout_Wrapper--9VhFA";
export var Link = "styles-module--Link--hEUnM";
export var ProductCode = "styles-module--ProductCode--3srA4";
export var TitleWrapper = "styles-module--TitleWrapper--MJAHi";