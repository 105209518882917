import React from 'react'

import * as Buttons from '@arch-log/webapp.shared/Buttons'
import { classNames } from '@arch-log/webapp.lib/CssHelper'
import * as Icons from '@arch-log/webapp.shared/Icons'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

export const SelectAllLabel = ({ children }) => (
  <label className={styles.CheckboxSelectAll}>
    {children}
    <div className={styles.CheckboxSelectAll_Text}>
      <Literals.SelectAll />
    </div>
  </label>
)

export const SelectAllCheckBox = ({
  checked,
  indeterminate,
  onChangeValue,
  ...props
}) => {
  const icon = indeterminate ? (
    <Icons.CheckBoxAll_Indeterminate
      className={styles.CheckBox_Indeterminate}
    />
  ) : checked ? (
    <Icons.CheckBoxAll_Checked className={styles.CheckBox_Selected} />
  ) : (
    <Icons.CheckBoxAll_Unchecked className={styles.CheckBox} />
  )

  return (
    <>
      {icon}
      <input
        type="checkbox"
        value="0"
        checked={checked}
        className={styles.Hidden}
        ref={(el) => el && (el.indeterminate = indeterminate)}
        onChange={(e) => {
          e.stopPropagation()

          if (onChangeValue) {
            onChangeValue(e.target.checked)
          }
        }}
      />
    </>
  )
}

export const RemoveEntriesButton = ({ onClick }) => (
  <div className={styles.ProductFotterButton}>
    <Buttons.LineButton
      onClick={onClick}
      // className={styles.RemoveEntriesButton}
    >
      <Literals.RemoveEntries />
    </Buttons.LineButton>
  </div>
)

export const AddToSampleRequestButton = ({ onClick }) => (
  <div className={styles.ProductFotterButton}>
    <Buttons.LineButton onClick={onClick}>
      <Literals.AddToSampleRequestButton />
    </Buttons.LineButton>
  </div>
)

export const BulkRequestBimsButton = ({ children, onClick }) => (
  <div className={styles.ProductFotterButton}>
    <Buttons.LineButton onClick={onClick}>{children}</Buttons.LineButton>
  </div>
)

export const BulkCopyProjectProductsButton = ({ children, onClick }) => (
  <div className={styles.ProductFotterButton}>
    <Buttons.LineButton onClick={onClick}>{children}</Buttons.LineButton>
  </div>
)

export const BulkTagEditButton = ({ children, onClick }) => (
  <div className={styles.ProductFotterButton}>
    <Buttons.LineButton onClick={onClick}>{children}</Buttons.LineButton>
  </div>
)

export const BulkActionDispatcherWrapper = ({ children, ...props }) => (
  <dl className={styles.BulkActionDispatcherWrapper} {...props}>
    {children}
  </dl>
)

export const BulkActionDispatcherToggle = ({
  children,
  toggle,
  disabled = false,
}) => (
  <dt
    className={classNames([
      styles.BulkActionDispatcherToggle,
      disabled ? styles.BulkActionDispatcherToggle_Disabled : null,
    ])}
    onClick={() => toggle()}
  >
    <Icons.DispatchBulkAction
      className={styles.BulkActionDispatcherToggle_Icon}
    />
    <span className={styles.BulkActionDispatcherToggle_Text}>{children}</span>
    <Icons.Dropdown
      className={styles.BulkActionDispatcherToggle_DropdownIcon}
    />
  </dt>
)

export const BulkActionDispatcherItems = ({
  children,
  className = styles.BulkActionDispatcherItems,
  isVisible,
}) => (
  <dd
    className={classNames([
      className,
      isVisible ? null : styles.BulkActionDispatcherItems_Hidden,
    ])}
  >
    <ul>{children}</ul>
  </dd>
)

export const BulkActionDispatcherItem = ({ children }) => (
  <li className={styles.BulkActionDispatcherItem}>{children}</li>
)
