import React from 'react'

import * as Content from './Content'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'

export const useActions = () => {
  const { show: showModal, hide } = React.useContext(Modal.Context)

  const show = ({ projectId, products }) => {
    showModal(() => (
      <Content.Default products={products} project_id={projectId} />
    ))
  }

  return {
    show,
    hide,
  }
}
