import React from 'react'
import * as styles from './layout.module.scss'

export * from '../Template/Layout'

export const Row = ({ children }) => (
  <div className={styles.Row}>{children}</div>
)

export const Conditions = ({ children }) => (
  <div className={styles.Conditions}>{children}</div>
)

export const Buttons = ({ children }) => (
  <div className={styles.Buttons}>{children}</div>
)

export const ConditionKeyword = ({ children }) => (
  <div className={styles.ConditionKeyword}>{children}</div>
)

export const ConditionMisc = ({ children }) => (
  <div className={styles.ConditionMisc}>{children}</div>
)

export const ConditionGroup = ({ children }) => (
  <div className={styles.ConditionGroup}>{children}</div>
)
