import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Label = ({ children }) => (
  <div className={ styles.Label }>{ children }</div>
)

