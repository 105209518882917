import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Components from '../Components'
import * as Labels from './labels'
import { createGroup } from '../utils'

/**
 */
const ValueFormatter = (v, { t }) => {
  if (typeof v === 'boolean') {
    return t(v ? 'Values.Boolean.Yes' : 'Values.Boolean.No')
  }

  return v
  //return t(v ?? '')
}

const Specification = ({
  language,
  t,
  components = {},
  valueFormatter = ValueFormatter,
}) => {
  const DefaultComponents = React.useContext(Components.Context)

  const {
    Container = DefaultComponents.FieldContainer,
    Label = DefaultComponents.FieldLabel,
    Value = DefaultComponents.FieldValue,
  } = components
  return (
    <Container>
      <Label>
        <Product.Properties.Specification.Label
          language={language}
          formatter={(v) => t(`Specifications.${v}`)}
        />
      </Label>
      <Value>
        <Product.Properties.Specification.Value
          formatter={valueFormatter}
          t={t}
        />
        <Product.Properties.Specification.Unit />
        <Product.Properties.Specification.Note prefix="(" suffix=")" />
      </Value>
    </Container>
  )
}

/**
 */
export const Installation = createGroup(
  'Specifications_Installation',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.Installation}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.Installation}
      {...props}
    />
  ),
)

/**
 */
export const Resistance = createGroup(
  'Specifications_Resistance',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.Resistance}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.Resistance}
      {...props}
    />
  ),
)

/**
 */
export const SpecialProcess = createGroup(
  'Specifications_SpecialProcess',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.SpecialProcess}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.SpecialProcess}
      {...props}
    />
  ),
)

/**
 */
export const PhysicalResistance = createGroup(
  'Specifications_PhysicalResistance',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter
      labels={Labels.PhysicalResistance}
    >
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.PhysicalResistance}
      {...props}
    />
  ),
)

/**
 */
export const SpecificationsAndOther = createGroup(
  'Specifications_SpecificationsAndOther',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter
      labels={Labels.SpecificationsAndOther}
    >
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.SpecificationsAndOther}
      {...props}
    />
  ),
)

/**
 */
export const FumeHood = createGroup(
  'Specifications_FumeHood',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.FumeHood}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.FumeHood}
      {...props}
    />
  ),
)

/**
 */
export const PaintAndPlastererMaterials = createGroup(
  'Specifications_PaintAndPlastererMaterials',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter
      labels={Labels.PaintAndPlastererMaterials}
    >
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.PaintAndPlastererMaterials}
      {...props}
    />
  ),
)

/**
 */
export const Lightning = createGroup(
  'Specifications_Lightning',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.Lightning}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.Lightning}
      {...props}
    />
  ),
)

/**
 */
export const Joinery = createGroup(
  'Specifications_Joinery',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.Joinery}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has labels={Labels.Joinery} {...props} />
  ),
)

/**
 */
export const FireFightning = createGroup(
  'Specifications_FireFightning',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.FireFightning}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.FireFightning}
      {...props}
    />
  ),
)

/**
 */
export const MotorEvokedPotential = createGroup(
  'Specifications_MotorEvokedPotential',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter
      labels={Labels.MotorEvokedPotential}
    >
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.MotorEvokedPotential}
      {...props}
    />
  ),
)

/**
 */
export const Electric = createGroup(
  'Specifications_Electric',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.Electric}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.Electric}
      {...props}
    />
  ),
)

/**
 */
export const Machine = createGroup(
  'Specifications_Machine',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter labels={Labels.Machine}>
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has labels={Labels.Machine} {...props} />
  ),
)

/**
 */
export const FacilityManagement = createGroup(
  'Specifications_FacilityManagement',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter
      labels={Labels.FacilityManagement}
    >
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.FacilityManagement}
      {...props}
    />
  ),
)

/**
 */
export const AirConditionEquipment = createGroup(
  'Specifications_AirConditionEquipment',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter
      labels={Labels.AirConditionEquipment}
    >
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.AirConditionEquipment}
      {...props}
    />
  ),
)

/**
 */
export const WaterSupplyEquipment = createGroup(
  'Specifications_WaterSupplyEquipment',
  ({ ...props }) => (
    <Product.Properties.Specifications.Filter
      labels={Labels.WaterSupplyEquipment}
    >
      <Product.Properties.Specifications.Each>
        <Specification {...props} />
      </Product.Properties.Specifications.Each>
    </Product.Properties.Specifications.Filter>
  ),
  (props) => (
    <Product.Properties.Specifications.Has
      labels={Labels.WaterSupplyEquipment}
      {...props}
    />
  ),
)
