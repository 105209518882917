import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('module.project.product.tag')

  const formatPlaceholder = (key, ...options) => {
    return t(`Placeholder.${key}`, ...options)
  }

  return {
    formatPlaceholder,
  }
}
