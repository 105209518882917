import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

// name: String
// labels: Localized!
// shortLabels: Localized!

/**
 */
export const DisplayName = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  lang = 'ja',
  ...props
}) => {
  const localedValue = value?.[lang] ?? defaultValue

  return <Renderer value={localedValue} {...props} />
}
