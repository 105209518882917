// extracted by mini-css-extract-plugin
export var ButtonContainer = "styles-module--ButtonContainer--reBvj";
export var CopyButton = "styles-module--CopyButton--N4r31";
export var CopyFailure = "styles-module--CopyFailure--jXsoL";
export var CopyProductListDescription = "styles-module--CopyProductListDescription--TnEak";
export var CopyProductListTitle = "styles-module--CopyProductListTitle--sKjqQ";
export var CopyProductListWrapper = "styles-module--CopyProductListWrapper--Gdru6";
export var IncludePrivateProductError = "styles-module--IncludePrivateProductError--nFRpO";
export var LinkToCreateProjectButton = "styles-module--LinkToCreateProjectButton--oXlvt";
export var NoCopyProductsButton = "styles-module--NoCopyProductsButton--A7B1e";
export var NotTargetProjectDescription = "styles-module--NotTargetProjectDescription--NF17S";
export var ProductWrapper = "styles-module--ProductWrapper--JYDCt";
export var Select = "styles-module--Select--MwLr0";
export var SelectLabel = "styles-module--SelectLabel--Tig49";
export var Title = "styles-module--Title--EjbIM";