import React from 'react'

import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'
import { useDebounce } from 'react-use'

import * as Languages from '@arch-log/webapp.modules/i18n/components/Languages'

import * as Manufacturer from '@arch-log/webapp.modules/manufacturer.v2/components'
import * as ManufacturerOrder from '@arch-log/webapp.modules/manufacturer.v2/order'

import * as Selected from './Selected'
import * as Literals from './Literals'
import * as Filter from './Filter'
import * as Styled from './Styled'
import * as Setting from './Setting'

/**
 */
export const FilterInput = ({
  components: { Input = Styled.FilterInput } = {},
}) => {
  const { pattern, setPattern } = React.useContext(Filter.Pattern.Context)
  const { formatPlaceholder } = Literals.useLiterals()

  return (
    <Input
      onChange={(e) => {
        setPattern(e.target.value)
      }}
      value={pattern}
      placeholder={formatPlaceholder('Search.EntryManufacturer')}
    />
  )
}

const SelectionsInner = ({
  styles,
  components: { Wrapper = Styled.SelectionsWrapper } = {},
}) => {
  const { enables } = React.useContext(Filter.Enabled.Context)
  const { pattern } = React.useContext(Filter.Pattern.Context)
  const { language } = React.useContext(Languages.Context)
  const { sortEntries, reset, filterEntries } =
    Manufacturer.Scope.Entries.useContext()

  useDebounce(
    () => {
      if (enables.length > 0 || (pattern ?? null) !== null) {
        reset()
        filterEntries((man) => {
          //
          const filterByEnables = (v) => {
            if (enables.length > 0) {
              return enables.includes(v?.uniqueName)
            }
            return true
          }

          const filterByPattern = (v) => {
            if (pattern) {
              const names = [
                v?.displayNames?.en,
                v?.displayNames?.ja,
                v?.displayShortNames?.en,
                v?.displayShortNames?.ja,
                v?.sortNames?.en,
                v?.sortNames?.ja,
              ]

              return names.find(
                (name) =>
                  name && name.toLowerCase().includes(pattern.toLowerCase()),
              )
            }

            return true
          }

          const filterByProductCount = (v) => {
            return v.countProducts > 0 || v.oemSuppliers.length > 0
          }

          return (
            filterByEnables(man) &&
            filterByPattern(man) &&
            filterByProductCount(man)
          )
        })

        sortEntries(
          ManufacturerOrder.Sorter(
            ManufacturerOrder.OrderBy.Name,
            ManufacturerOrder.Direction.Asc,
            { language },
          ),
        )
      }
    },
    300,
    [enables, pattern],
  )

  return (
    <Wrapper styles={styles}>
      <SelectionItems styles={styles} />
    </Wrapper>
  )
}

/**
 */
export const Selections = (props) => {
  const [load, isLoaded] = Manufacturer.Remote.Queries.Entries.useLoad()

  React.useEffect(() => {
    load()
  }, [load])

  if (!isLoaded) {
    return <>Loading</>
  }

  return <SelectionsInner {...props} />
}

/**
 */
const SelectionsEmpty = ({ components: { Wrapper = Styled.Empty } = {} }) => (
  <Wrapper>
    <Literals.Empty />
  </Wrapper>
)

/**
 */
const SelectionItems = ({ styles }) => {
  const { entries } = Manufacturer.Scope.Entries.useContext()

  if (entries.length === 0) {
    return <SelectionsEmpty />
  }

  return (
    <Manufacturer.Scope.Entries.Each>
      <Selection styles={styles} />
    </Manufacturer.Scope.Entries.Each>
  )
}

/**
 */
export const Selection = ({
  styles,
  components: { Wrapper = Styled.SelectionWrapper } = {},
}) => {
  const { selected, setSelected } = React.useContext(Selected.Context)
  const { entry } = Manufacturer.Scope.Entry.useContext()

  return (
    <Wrapper
      styles={styles}
      onSelect={() => setSelected(entry?.uniqueName)}
      isSelected={selected && selected === entry?.uniqueName}
      isPrivate={entry.isPrivate}
    >
      <ManufacturerName />
      <ProductCount />
    </Wrapper>
  )
}

/**
 */
const ManufacturerName = withLanguage(
  Manufacturer.Scope.Entry.Properties.DisplayShortName,
)

/**
 */
const ProductCount = () => {
  const { showProductCount } = React.useContext(Setting.Context)

  if (showProductCount) {
    return (
      <Manufacturer.Scope.Entry.Properties.Properties.ProductCount
        prefix="("
        suffix=")"
      />
    )
  }

  return null
}
