import React from 'react'

import * as ProjectProduct from '@arch-log/webapp.modules/project.product.v2/components'

/**
 */
export const ValidateDeleteProjectProductEntriesInitializer = ({
  projectId,
  productIds,
  children,
  ...props
}) => {
  const { entries } = ProjectProduct.Scope.DeprecatedEntries.useEntries()

  const productV2Ids = productIds
    .map((id) => entries.find((e) => e.oldId === id)?.id)
    .filter((v) => v)

  return (
    <ProjectProduct.Remote.Queries.ValidateDeleteProjectProductEntries.Loader
      projectId={projectId}
      productIds={productV2Ids}
      {...props}
    >
      {children}
    </ProjectProduct.Remote.Queries.ValidateDeleteProjectProductEntries.Loader>
  )
}
