import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

/**
 */
export const Label = withContextValue(
  Context,
  (data) => data.entry.manufacturingInfo.manufacturer.labels,
)(Renderers.ManufacturingInfo.Manufacturer.Label)

/**
 */
export const ShortLabel = withContextValue(
  Context,
  (data) => data.entry.manufacturingInfo.manufacturer.shortLabels,
)(Renderers.ManufacturingInfo.Manufacturer.ShortLabel)

/**
 */
export const BrandName = withContextValue(
  Context,
  (data) => data.entry.manufacturingInfo.manufacturer.brandName,
)(Renderers.ManufacturingInfo.Manufacturer.BrandName)

/**
 */
export const Url = withContextValue(
  Context,
  (data) => data.entry.manufacturingInfo.manufacturer.url,
)(Renderers.ManufacturingInfo.Manufacturer.Url)

/**
 */
export const Code = withContextValue(
  Context,
  (data) => data.entry.manufacturingInfo.manufacturer.code,
)(Renderers.ManufacturingInfo.Manufacturer.Code)
