import React from 'react'

import * as styles from './styles.module.scss'
import { classNames } from '@arch-log/webapp.lib/CssHelper'

/**
 */
export const MenuButton = ({
  children,
  isActive,
  className = styles.Menu,
  activeClassName = styles.Menu_Active,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={classNames([className, isActive ? activeClassName : null])}
  >
    {children}
  </div>
)
