import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const ListSection = ({ children }) => (
  <div className={styles.ListSection}>{children}</div>
)

/**
 */
export const ExpandSection = ({ children }) => (
  <div className={styles.ExpandSection}>{children}</div>
)
