import React from 'react'

import moment from 'moment'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import { withManufacturer } from '../utils'

/**
 */
export const Name = withManufacturer(
  ({ manufacturer, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { name: value = null } = manufacturer

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const DisplayName = withManufacturer(
  ({
    //
    manufacturer,
    render: Renderer = Renderers.TextRenderer,
    language = 'ja',
    ...props
  }) => {
    // extract value
    const {
      name: { 
        [language]: name = null,
      } = {},
      display_thumbnail_name: { [language]: displayName = null } = {},
    } = manufacturer

    // render value
    return <Renderer value={displayName ?? name} {...props} />
  },
)

/**
 */
export const ProductCount = withManufacturer(
  ({ manufacturer, render: Renderer = Renderers.NumberRenderer, format = '0,0', ...props }) => {
    // extract value
    const { productCount: value = null } = manufacturer

    // render value
    return <Renderer value={value} format={ format } {...props} />
  },
)
