import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  onReset,
  onBack,
  components: {
    //Title = DefaultComponents.Title,
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      HeaderLeft = Layout.HeaderLeft,
      HeaderCenter = Layout.HeaderCenter,
      HeaderRight = Layout.HeaderRight,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
}) => (
  <Wrapper>
    <Header>
      <HeaderLeft>
        <Containers.Back onBack={onBack} />
        <Containers.Title />
      </HeaderLeft>
      <HeaderCenter>
        <Containers.PatternFilter />
      </HeaderCenter>
      <HeaderRight>
        <Containers.Reset onReset={onReset} />
      </HeaderRight>
    </Header>
    <Body>
      <Containers.Selector />
    </Body>
    <Footer></Footer>
  </Wrapper>
)
