import React from 'react'

import { Context } from './Context'

/**
 */
export const Provider = ({
  //
  id,
  labels,
  countProducts,
  ...props
}) => {
  return (
    <Context.Provider
      value={{
        id,
        labels,
        countProducts,
      }}
      {...props}
    />
  )
}
