import React from 'react'

import * as Filter from './Filter'
import * as Mode from './Mode'
import * as Selected from './Selected'

/**
 */
export const Initializer = ({
  isRelatedProductCountEnabled = false,
  filterByRelatedProductCount = null,
  children,
  defaultValue,
  value,
  onSelected,
  enables,
}) => {
  return (
    <Mode.Initializer
      isRelatedProductCountEnabled={isRelatedProductCountEnabled}
    >
      <Filter.Enabled.Initializer enables={enables}>
        <Selected.Initializer
          defaultValue={defaultValue}
          value={value}
          onSelected={onSelected}
        >
          <Filter.Pattern.Initializer>{children}</Filter.Pattern.Initializer>
        </Selected.Initializer>
      </Filter.Enabled.Initializer>
    </Mode.Initializer>
  )
}
