import React from 'react'

import * as Category from '@arch-log/webapp.modules/product-category.v2/components/Scope/Category'

import { Context } from './Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Each = ({
  //
  render: Renderer = ChildrenRenderer,
  ...props
}) => {
  const { entries } = React.useContext(Context)

  return entries.map((entry, index) => {
    return (
      <Category.Provider key={index} {...entry}>
        <Renderer {...props} />
      </Category.Provider>
    )
  })
}
