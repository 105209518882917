import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'
import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => (
  <h2 className={styles.Title}>{children}</h2>
)

/**
 */
export const SelectLabel = ({ children }) => {
  return <h3 className={styles.SelectLabel}>{children}</h3>
}

/**
 */
export const Select = ({ onChange, value, children }) => {
  return (
    <select className={styles.Select} onChange={onChange} value={value}>
      {children}
    </select>
  )
}

/**
 */
export const SelectOption = ({ children, value }) => (
  <option value={value}>{children}</option>
)

/**
 */
export const ButtonContainer = ({ children }) => (
  <div className={styles.ButtonContainer}>{children}</div>
)

/**
 */
export const CopyButton = ({ onClick, children }) => (
  <button onClick={onClick} type="button" className={styles.CopyButton}>
    {children}
  </button>
)

/**
 */
export const NoCopyProductsButton = ({ onClick, children }) => (
  <button
    onClick={onClick}
    type="button"
    className={styles.NoCopyProductsButton}
  >
    {children}
  </button>
)

/**
 */
export const CopyProductListWrapper = ({ children }) => {
  return <div className={styles.CopyProductListWrapper}>{children}</div>
}

/**
 */
export const ProductWrapper = ({ children }) => {
  return <div className={styles.ProductWrapper}>{children}</div>
}

/**
 */
export const CopyProjectSelectorWrapper = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const NotTargetProjectWrapper = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const NotTargetProjectDescription = ({ children }) => (
  <div className={styles.NotTargetProjectDescription}>{children}</div>
)

/**
 */
export const CopyFailure = ({ children }) => (
  <div className={styles.CopyFailure}>{children}</div>
)

/**
 */
export const IncludePrivateProductError = ({ children }) => (
  <div className={styles.IncludePrivateProductError}>{children}</div>
)

/**
 */
export const CopyProductListTitle = ({ children }) => (
  <span className={styles.CopyProductListTitle}>{children}</span>
)

/**
 */
export const CopyProductListDescription = ({ children }) => (
  <span className={styles.CopyProductListDescription}>{children}</span>
)

/**
 */
export const Progress = ({ children }) => {
  return <Loader>{children}</Loader>
}
