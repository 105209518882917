import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { Context } from './Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { withPropsConverter } from '@arch-log/webapp.modules/utils/RendererHelper.js'

/**
 */
export const Image = withContextValue(Context)(
  withPropsConverter(Renderers.Image.Image)(({ value = {} }) => {
    const { url, label } = value

    return {
      value: { src: url, alt: label, ...value },
    }
  }),
)

/**
 */
export const Thumbnail = withContextValue(Context)(Renderers.Image.Thumbnail)

/**
 */
export const Label = withContextValue(
  Context,
  (data) => data.label,
)(Renderers.Image.Label)
