import React from 'react'

import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntries/Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { useEntries } from './useHooks'

import * as Entry from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntry'

/**
 * Select One Entry
 *
 * <One by={ (entry) => entry.id === 1}/>
 * <One id="1"/>
 *
 * Priority or properties
 *   - selector
 *   - id
 */
export const SelectOne = withContextValue(
  Context,
  ({ entries }) => entries,
)(
  /**
   */
  ({ value: entries, id, by: selector, children }) => {
    const selected = React.useMemo(() => {
      if (selector) {
        return entries.find(selector)
      } else if (id) {
        return entries.find((entry) => entry.id === id)
      }
      // other conditional selector

      return null
    }, [id, selector, entries])

    if (!selected) {
      throw new Error('Not Selected')
    }

    return <Entry.Provider entry={selected}>{children}</Entry.Provider>
  },
)
