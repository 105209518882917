import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children, onClick = () => {}, ...props}) => (
  <div className={styles.Container} onClick={onClick} {...props}>
    {children}
  </div>
)
