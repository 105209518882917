import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as DefaultComponents from './DefaultComponents'
import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      BIMIcon = DefaultComponents.BIMIcon,
      ParentIcon = DefaultComponents.ParentIcon,
      Background = DefaultComponents.Background,
      Title = DefaultComponents.Title,
      Layout: { Container = Layout.Container } = {},
    } = {},
    onClickEntry = DefaultComponents.DefaultOnSelectProduct,
  }) => {
    const { product } = React.useContext(Product.Context)

    return (
      <Container onClick={() => onClickEntry(product)} title={ product.updatedAt }>
        <Background />
        <BIMIcon />
        <ParentIcon />
        <Containers.ParentChildIcon />
        <Containers.PrivateIcon />
        <Title />
      </Container>
    )
  },
)
