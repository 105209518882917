import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const [condition, setCondition] = React.useState({})

  const resetCondition = () => {
    setCondition({})
  }

  return (
    <Context.Provider
      value={{
        condition,
        setCondition,
        resetCondition,
      }}
    >
      {children}
    </Context.Provider>
  )
}
