import React from 'react'

import * as styles from './layout.module.scss'

import * as Base from '@arch-log/webapp.shared/AddedProduct.v2/List/Layout'

/**
 */
export const Wrapper = ({ children }) => (
  <Base.Container additionalClassName={styles.Wrapper}>
    {children}
  </Base.Container>
)

export const Footer = ({ children }) => (
  <div className={styles.Footer}>
    <div className={styles.Footer_Contents}>{children}</div>
  </div>
)

export const Header = Base.Header

export const HeaderCondition = ({ children }) => <div>{children}</div>

export const HeaderControl = ({ children }) => (
  <div className={styles.HeaderControl}>{children}</div>
)

export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)
