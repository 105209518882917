import React from 'react'

import { connect } from 'react-redux'
import * as selectors from '../../selectors'

import { Context } from './Context'

/**
 * Provide only used manufacturer by project
 */
export const Provider = connect(
  // mapStateToProps
  (state) => ({
    entries: selectors.selectEntriesData(state),
  }),
)(
  // Base Component
  ({
    //
    children,
    entries,
  }) => {
    const manufacturers = entries.map(
      ({ product }) => product.identifier.manufacturer,
    )

    const uniqueManufs = [
      ...new Map(manufacturers.map((manuf) => [manuf.name.en, manuf])).values(),
    ]

    return (
      <Context.Provider value={{ manufacturers: uniqueManufs }}>
        {children}
      </Context.Provider>
    )
  },
)
