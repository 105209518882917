import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Message = ({ children, ...props }) => (
  <div {...props} className={styles.Message}>
    {children}
  </div>
)

/**
 */
export const AcceptButton = ({ children, onClick, ...props }) => (
  <button
    type="button"
    className={styles.AcceptButton}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
)

/**
 */
export const FormDescription = ({ children }) => (
  <div className={styles.FormDescription}>{children}</div>
)

/**
 */
export const Description = ({ children }) => (
  <div className={styles.Description}>{children}</div>
)

/**
 */
export const SubDescription = ({ children }) => (
  <div className={styles.SubDescription}>{children}</div>
)

/**
 */
export const ServiceDescriptionList = ({ children }) => (
  <div className={styles.ServiceDescriptionList}>{children}</div>
)

/**
 */
export const ServiceDescriptionContainer = ({ children }) => (
  <div className={styles.ServiceDescriptionContainer}>{children}</div>
)

/**
 */
export const ServiceDescription = ({ children }) => (
  <div className={styles.ServiceDescription}>{children}</div>
)

/**
 */
export const ServiceProductList = ({ children }) => (
  <div className={styles.ServiceProductList}>{children}</div>
)

/**
 */
export const ServiceProduct = ({ children }) => (
  <div className={styles.ServiceProduct}>{children}</div>
)

/**
 */
export const ServiceName = ({ children }) => (
  <div className={styles.ServiceName}>{children}</div>
)

/**
 */
export const Products = ({ children }) => (
  <div className={styles.Products}>{children}</div>
)

/**
 */
export const Product = ({ children }) => (
  <div className={styles.Product}>{children}</div>
)

/**
 */
export const ProductValue = ({ children }) => (
  <div className={styles.ProductValue}>{children}</div>
)

/**
 */
export const Prefix = ({ children }) => (
  <div className={styles.Prefix}>{children}</div>
)

/**
 */
export const CancelButton = ({ onClick, children }) => (
  <button className={styles.CancelButton} type="button" onClick={onClick}>
    {children}
  </button>
)

/**
 */
export const SubmitButton = ({ disabled, children }) => (
  <button type="submit" className={styles.SubmitButton} disabled={disabled}>
    {children}
  </button>
)

/**
 */
export const ConfirmModalErros = ({ children }) => {
  return <div className={styles.ConfirmModalErros}>{children}</div>
}
