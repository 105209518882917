import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as BulkActions from '../../BulkActions'
import * as Icons from '@arch-log/webapp.shared/Icons'
import * as ProductIcons from '@arch-log/webapp.shared/ProductIcons'

import * as styles from './styles.module.scss'

export * from '@arch-log/webapp.shared/ProductList/Entry/DefaultComponents'

/**
 *
 */
export const Checkbox = () => {
  return (
    <div className={styles.SelectEntry}>
      <BulkActions.Select
        components={{
          Label: ({ children, checked, ...props }) => (
            <label
              {...props}
              className={styles.Label}
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </label>
          ),
          CheckBox: ({ checked, children, onChangeValue, ...props }) => {
            const icon = checked ? (
              <Icons.CheckBox_Checked className={styles.CheckBox_Selected} />
            ) : (
              // <Icons.CheckBox_Unchecked className={styles.CheckBox} />
              <div className={styles.CheckBox} />
            )
            return (
              <>
                {icon}
                <input
                  type="checkbox"
                  className={styles.Hidden}
                  {...props}
                  checked={checked}
                  onChange={(e) => {
                    onChangeValue(!checked, e.nativeEvent.shiftKey)
                  }}
                />
              </>
            )
          },
        }}
      />
    </div>
  )
}
