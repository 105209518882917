import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import * as Renderers from '@arch-log/webapp.modules/product.v2/components/PropertyRenderers'

// EntryContext
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntry/Context'

/**
 */
export const Name = withContextValue(
  Context,
  (data) => data.entry.name,
)(Renderers.Name)

/**
 */
export const Code = withContextValue(
  Context,
  (data) => data.entry.code,
)(Renderers.Code)

/**
 */
export const CreatedAt = withContextValue(
  Context,
  (data) => data.entry.createdAt,
)(Renderers.RegisteredAt)

/**
 */
export const UpdatedAt = withContextValue(
  Context,
  (data) => data.entry.updatedAt,
)(Renderers.UpdatedAt)
