import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Components from '../Components'
import * as Labels from './labels'
import { createGroup } from '../utils'

/**
 */
export const Color = createGroup(
  'Appearances_Color',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <Product.Properties.Appearances.Filter labels={Labels.Color}>
        <Product.Properties.Appearances.Each>
          <Container>
            <Label>
              <Product.Properties.Appearance.Label
                language={language}
                formatter={(v) => t(`Appearances.${v}`)}
              />
            </Label>
            <Value>
              <Product.Properties.Appearance.Value />
            </Value>
          </Container>
        </Product.Properties.Appearances.Each>
      </Product.Properties.Appearances.Filter>
    )
  },
  (props) => (
    <Product.Properties.Appearances.Has labels={Labels.Color} {...props} />
  ),
)

/**
 */
export const Texture = createGroup(
  'Appearances_Texture',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components
    return (
      <div>
        <Product.Properties.Appearances.Filter labels={Labels.Texture}>
          <Product.Properties.Appearances.Each>
            <Container>
              <Label>
                <Product.Properties.Appearance.Label
                  formatter={(v) => t(`Appearances.${v}`)}
                  language={language}
                />
              </Label>
              <Value>
                <Product.Properties.Appearance.Value />
              </Value>
            </Container>
          </Product.Properties.Appearances.Each>
        </Product.Properties.Appearances.Filter>
      </div>
    )
  },
  (props) => (
    <Product.Properties.Appearances.Has labels={Labels.Texture} {...props} />
  ),
)

/**
 */
export const PatternType = createGroup(
  'Appearances_PatternType',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components
    return (
      <div>
        <Product.Properties.Appearances.Filter labels={Labels.PatternType}>
          <Product.Properties.Appearances.Each>
            <Container>
              <Label>
                <Product.Properties.Appearance.Label
                  formatter={(v) => t(`Appearances.${v}`)}
                  language={language}
                />
              </Label>
              <Value>
                <Product.Properties.Appearance.Value />
                <Product.Properties.Appearance.Unit />
                <Product.Properties.Appearance.Note prefix="(" suffix=")" />
              </Value>
            </Container>
          </Product.Properties.Appearances.Each>
        </Product.Properties.Appearances.Filter>
      </div>
    )
  },
  (props) => (
    <Product.Properties.Appearances.Has
      labels={Labels.PatternType}
      {...props}
    />
  ),
)

/**
 */
export const Shape = createGroup(
  'Appearances_Shape',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <div>
        <Product.Properties.Appearances.Filter labels={Labels.Shape}>
          <Product.Properties.Appearances.Each>
            <Container>
              <Label>
                <Product.Properties.Appearance.Label
                  formatter={(v) => t(`Appearances.${v}`)}
                  language={language}
                />
              </Label>
              <Value>
                <Product.Properties.Appearance.Value />
                <Product.Properties.Appearance.Unit />
                <Product.Properties.Appearance.Note prefix="(" suffix=")" />
              </Value>
            </Container>
          </Product.Properties.Appearances.Each>
        </Product.Properties.Appearances.Filter>
      </div>
    )
  },
  (props) => (
    <Product.Properties.Appearances.Has labels={Labels.Shape} {...props} />
  ),
)
