import React from 'react'

import * as Tag from '@arch-log/webapp.modules/project.product.tag/components'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  project_id,
  products,
  children,
  components: {
    Wrapper = Layout.Wrapper,
    Header = Layout.Header,
    Body = Layout.Body,
    Footer = Layout.Footer,
  } = {},
}) => {
  return (
    <DefaultInitializer project_id={project_id}>
      <Wrapper>
        <Header>
          <Containers.Title />
        </Header>
        <Body>
          <Containers.Form products={products} />
          <Containers.Description />
        </Body>
        <Footer>
          <Containers.Close />
        </Footer>
      </Wrapper>
    </DefaultInitializer>
  )
}

/**
 */
const DefaultInitializer = ({ children, project_id }) => (
  <Tag.Namespaces.Loaders.AtOnce>
    <DefaultContentOrError project_id={project_id}>
      {children}
    </DefaultContentOrError>
  </Tag.Namespaces.Loaders.AtOnce>
)

const DefaultContentOrError = ({ children, project_id }) => {
  const { namespaces } = React.useContext(Tag.Namespaces.Context)

  if (namespaces.length > 0) {
    return <>{children}</>
  }

  return (
    <>
      <Containers.NamespaceNotDefined project_id={project_id} />
    </>
  )
}
