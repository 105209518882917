import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

/**
 */
export const Value = withContextValue(
  Context,
  (data) => data.entry.bimInfo.revitExtended.uniformatAssemblyCode.value,
)(Renderers.BimInfo.RevitExtended.UniformatAssemblyCode.Value)

/**
 */
export const Version = withContextValue(
  Context,
  (data) => data.entry.bimInfo.revitExtended.uniformatAssemblyCode.version,
)(Renderers.BimInfo.RevitExtended.UniformatAssemblyCode.Version)
