import React from 'react'

import { BaseForm } from '@arch-log/webapp.modules/utils/components/ReactHookFormTemplate'
import { useForm, FormProvider } from 'react-hook-form'

/**
 */
export const Initializer = ({
  defaultValues = null,
  onSubmit,
  errors,
  values,
  children,
  ...props
}) => {
  const formData = useForm({
    defaultValues,
    mode: 'onSubmit',
  })

  React.useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        formData.setError(key, {
          type: 'custom',
          message: value,
        })
      })
    }
  }, [errors])

  React.useEffect(() => {
    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        formData.setValue(key, value)
      })
    }
  }, [])

  return (
    <FormProvider {...formData}>
      <BaseForm
        onSubmit={(e) => {
          formData.clearErrors('_form')
          formData.handleSubmit(onSubmit)(e)
        }}
        {...props}
      >
        {children}
      </BaseForm>
    </FormProvider>
  )
}
