import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Empty = translated('module.product')('ManufacturerFilter.Empty')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('module.product')

  const formatPlaceholder = (placeholder, ...options) => {
    return t(placeholder, ...options)
  }

  return { formatPlaceholder }
}
