import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as DefaultComponents from './DefaultComponents'

import { createField } from '../../utils'

/**
 */
export const Catalogs = createField(
  'BasicInformations_Catalog_Catalogs',
  ({
    components: {
      Catalog = DefaultComponents.Catalog,
      CatalogTitle = DefaultComponents.CatalogTitle,
      CatalogPages = DefaultComponents.CatalogPages,
      CatalogPage = DefaultComponents.CatalogPage,
    } = {},
  }) => (
    <Product.Properties.BasicInformation.Catalogs.Each>
      <Catalog>
        <CatalogTitle>
          <Product.Properties.BasicInformation.Catalog.Name />
        </CatalogTitle>
        <CatalogPages>
          <Product.Properties.BasicInformation.Catalog.Pages.Each>
            <CatalogPage>
              <Product.Properties.BasicInformation.Catalog.Page.Value
                prefix="("
                suffix=")"
              />
            </CatalogPage>
          </Product.Properties.BasicInformation.Catalog.Pages.Each>
        </CatalogPages>
      </Catalog>
    </Product.Properties.BasicInformation.Catalogs.Each>
  ),
  //FIXME
)
