import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query LoadAuthenticated {
    authorization: authorizationOn202405 {
      role {
        name
      }
    }
  }
`
