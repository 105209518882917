import React from 'react'

import * as ProjectProduct from '@arch-log/webapp.modules/project.product.v2/components'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Products from '@arch-log/webapp.modules/product/components'
import * as Base from '@arch-log/webapp.shared/ProductList/Entry'
import * as Links from '@arch-log/webapp.shared/Links'
import * as CompleteModal from './CompleteModal'
import * as BulkCopyProductModal from '../../BulkCopyProductModal'
import * as TargetProjects from './TargetProjects'
import * as Literals from './Literals'
import * as Layout from './Layout'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const Initializer = ({ children, copyProducts, fromProject }) => {
  return (
    <Products.Loaders.AllLoader
      condition={{
        ids: copyProducts,
        includeChildProducts: true,
        includeDiscontinued: true,
      }}
    >
      <Project.Remote.Queries.MyEntries.Loader>
        <TargetProjects.Initializer
          components={{ NotTargetProject }}
          fromProject={fromProject}
        >
          <TargetProjects.Selected.Initializer>
            <ProjectProductCopyEntryInitializer fromProject={fromProject}>
              {children}
            </ProjectProductCopyEntryInitializer>
          </TargetProjects.Selected.Initializer>
        </TargetProjects.Initializer>
      </Project.Remote.Queries.MyEntries.Loader>
    </Products.Loaders.AllLoader>
  )
}

/**
 */
export const ProjectProductCopyEntryInitializer = ({
  fromProject,
  children,
}) => {
  const { targetProject } = React.useContext(TargetProjects.Selected.Context)
  const { products: copyProducts } = React.useContext(Products.Context)
  const { showModal: showCompleteModal } = CompleteModal.useActions()

  return (
    <ProjectProduct.Remote.Mutations.CopyEntry.Initializer
      fromProject={fromProject}
      toProject={targetProject}
      productIds={copyProducts.map((product) => product.id)}
      components={{
        ProgressOrContent,
        Progress,
      }}
      onCompleted={(data) =>
        showCompleteModal({
          copyData: data.copyProjectProducts.entries,
        })
      }
      onError={(error) => console.error(error)}
    >
      {children}
    </ProjectProduct.Remote.Mutations.CopyEntry.Initializer>
  )
}

/**
 */
export const Progress = ({
  components: {
    Layout: {
      Wrapper = Layout.ProgressWrapper,
      Body = Layout.ProgressBody,
    } = {},
    Decorator = Styled.Progress,
  } = {},
}) => {
  return (
    <Wrapper>
      <Body>
        <Decorator>
          <Literals.Progress />
        </Decorator>
      </Body>
    </Wrapper>
  )
}

/**
 */
const ProgressOrContent = ({
  components: { Progress = null } = {},
  children,
}) => {
  const { loading } = React.useContext(
    ProjectProduct.Remote.Mutations.CopyEntry.Context,
  )

  if (loading && Progress) {
    return <Progress />
  }

  return <>{children}</>
}

/**
 */
const NotTargetProjectDescription = ({
  components: { Decorator = Styled.NotTargetProjectDescription } = {},
}) => (
  <Decorator>
    <Literals.NotTargetProjectDescription />
  </Decorator>
)

/**
 */
const LinkToCreateProject = () => {
  const { hide } = BulkCopyProductModal.useActions()

  return (
    <Links.Project.ToCreateProject
      className={styles.LinkToCreateProjectButton}
      onClick={() => {
        hide()
      }}
    >
      <Literals.LinkToCreateProject />
    </Links.Project.ToCreateProject>
  )
}

/**
 */
const NotTargetProject = ({
  components: {
    Decorator = Layout.Wrapper,
    Description = Styled.NotTargetProjectDescription,
  } = {},
}) => {
  return (
    <Decorator>
      <Description>
        <NotTargetProjectDescription />
      </Description>
      <LinkToCreateProject />
    </Decorator>
  )
}

/**
 */
export const Title = ({ components: { Decorator = Styled.Title } = {} }) => (
  <Decorator>
    <Literals.Title />
  </Decorator>
)

/**
 */
export const CopyFailure = ({
  components: { Decorator = Styled.CopyFailure } = {},
}) => {
  const { error } = React.useContext(
    ProjectProduct.Remote.Mutations.CopyEntry.Context,
  )

  if (error) {
    return (
      <Decorator>
        <Literals.CopyFailure />
      </Decorator>
    )
  }

  return null
}

/**
 */
export const CopyProjectSelector = ({
  components: {
    Decorator = Styled.CopyProjectSelectorWrapper,
    Label = Styled.SelectLabel,
    Select = Styled.Select,
    Option = Styled.SelectOption,
  } = {},
}) => {
  const { targetProjects } = React.useContext(TargetProjects.Context)

  const { targetProject, setTargetProject } = React.useContext(
    TargetProjects.Selected.Context,
  )

  return (
    <Decorator>
      <Label>
        <Literals.TargetProjectName />
      </Label>
      <Select
        onChange={(e) => {
          setTargetProject(e.target.value)
        }}
        value={targetProject}
      >
        {targetProjects.map((targetProject) => {
          return (
            <Option key={targetProject.id} value={targetProject.id}>
              {targetProject.label}
            </Option>
          )
        })}
      </Select>
    </Decorator>
  )
}

/**
 */
export const CopyProductListTitle = ({
  components: { Decorator = Styled.CopyProductListTitle } = {},
}) => (
  <Decorator>
    <Literals.CopyProductListTitle />
  </Decorator>
)

/**
 */
export const CopyProductListDescription = ({
  components: { Decorator = Styled.CopyProductListDescription } = {},
}) => (
  <Decorator>
    <Literals.CopyProductListDescription />
  </Decorator>
)

/**
 */
export const IncludePrivateProductError = ({
  components: { Decorator = Styled.IncludePrivateProductError } = {},
  copyProducts,
}) => {
  const { products } = React.useContext(Products.Context)

  const includePrivateProduct = copyProducts.length !== products.length

  if (includePrivateProduct) {
    return (
      <Decorator>
        <Literals.IncludePrivateProductError />
      </Decorator>
    )
  }

  return null
}

/**
 */
export const CopyProductList = ({
  components: {
    Decorator = Styled.CopyProductListWrapper,
    ProductWrapper = Styled.ProductWrapper,
  } = {},
}) => {
  return (
    <Decorator>
      <Products.Each>
        <ProductWrapper>
          <Base.Default />
        </ProductWrapper>
      </Products.Each>
    </Decorator>
  )
}

/**
 */
export const Copy = ({
  components: {
    Decorator = Styled.ButtonContainer,
    Button = Styled.CopyButton,
    NoCopyProductsButton = Styled.NoCopyProductsButton,
  } = {},
}) => {
  const { hide } = BulkCopyProductModal.useActions()

  const { copyEntry, productIds } = React.useContext(
    ProjectProduct.Remote.Mutations.CopyEntry.Context,
  )

  if (productIds.length === 0) {
    return (
      <NoCopyProductsButton onClick={() => hide()}>
        <Literals.NoCopyProductsButton />
      </NoCopyProductsButton>
    )
  }

  return (
    <Decorator>
      <Button onClick={() => copyEntry()}>
        <Literals.Copy />
      </Button>
    </Decorator>
  )
}
