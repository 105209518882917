import React from 'react'

import { Context } from './Context'
import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'

const DefaultLabelRenderer = ({ children }) => <label>{children}</label>

const DefaultCheckBoxRenderer = ({
  checked,
  indeterminate,
  onChangeValue,
  ...props
}) => {
  return (
    <input
      type="checkbox"
      indeterminate={indeterminate}
      {...props}
      onChange={(e) => onChangeValue(indeterminate || checked ? true : false)}
    />
  )
}

/**
 */
export const SelectAll = ({
  components: {
    Label = DefaultLabelRenderer,
    CheckBox = DefaultCheckBoxRenderer,
  } = {},
  ...props
}) => {
  const { selectedIds, addAll, removeAll } = React.useContext(Context)

  const { addedProducts } = React.useContext(AddedProducts.Context)

  const productIds = React.useMemo(
    () => addedProducts.map((product) => product.product_id),
    [addedProducts],
  )

  const isAllSelected = selectedIds.length === productIds.length

  const isIndeterminate = !isAllSelected && selectedIds.length > 0

  return (
    <Label checked={isAllSelected}>
      <CheckBox
        checked={isAllSelected}
        indeterminate={isIndeterminate}
        onChangeValue={(value) => {
          if (value) {
            addAll(...productIds)
          } else {
            removeAll()
          }
        }}
        {...props}
      />
    </Label>
  )
}

export const Select = ({
  components: {
    Label = DefaultLabelRenderer,
    CheckBox = DefaultCheckBoxRenderer,
  } = {},
  ...props
}) => {
  const { selectedIds, add, addRange, remove, removeRange } = React.useContext(
    Context,
  )
  const { product } = React.useContext(Product.Context)

  const isSelected = selectedIds.includes(product.id)

  return (
    <Label checked={isSelected}>
      <CheckBox
        checked={isSelected}
        onChangeValue={(value, isShifted = false) => {
          if (value) {
            if (isShifted) {
              addRange(product.id)
            } else {
              add(product.id)
            }
          } else {
            if (isShifted) {
              removeRange(product.id)
            } else {
              remove(product.id)
            }
          }
        }}
        {...props}
      />
    </Label>
  )
}
