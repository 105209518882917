import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const [selected, setSelected] = React.useState()

  return (
    <Context.Provider value={{ selected, setSelected }}>
      {children}
    </Context.Provider>
  )
}
