import React from 'react'

import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const Message = ({
  components: { Decorator = Styled.Message } = {},
}) => (
  <Decorator>
    <Literals.Message />
  </Decorator>
)

/**
 */
export const Ok = ({
  components: { Decorator = Styled.OkButton } = {},
  onClick,
}) => (
  <Decorator onClick={onClick}>
    <Literals.Ok />
  </Decorator>
)
