import React from 'react'

import { Context } from './Context'

import * as Manufacturer from '@arch-log/webapp.modules/manufacturer.v2/components'
import * as Referenced from './Referenced'

/**
 */
export const Initializer = ({ value, ...props }) => {
  return <InitializerWithValue value={value} {...props} />
}

/**
 */
const InitializerWithValue = ({
  children,
  value: defaultValue = null,
  onSelected,
}) => {
  const [value, setValue] = React.useState(defaultValue)
  const [load, isLoaded] = Manufacturer.Remote.Queries.Entry.useLoad()

  const manufacturer = Manufacturer.Remote.Queries.Entry.useEntry()

  const selectedManufacturer = React.useMemo(() => {
    return manufacturer?.uniqueName === value ? manufacturer : null
  }, [value, manufacturer])

  React.useEffect(() => {
    if (value) {
      load(value)
    }
  }, [value])

  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const setSelected = (selected) => {
    setValue(selected)

    onSelected(selected ?? null)
  }

  const resetSelected = () => {
    setValue(null)

    onSelected(null)
  }

  return (
    <Referenced.Provider selected={selectedManufacturer}>
      <Context.Provider
        value={{
          selected: value,
          manufacturer: selectedManufacturer,
          setSelected,
          resetSelected,
        }}
      >
        {children}
      </Context.Provider>
    </Referenced.Provider>
  )
}
