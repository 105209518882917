import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const DefaultImageRenderer = ({
  value,
  defaultValue = {},
  onClick = (e) => {},
  ...props
}) => {
  const { alt, src } = value ?? defaultValue

  return (
    <img
      {...props}
      src={src}
      alt={alt}
      onClick={(e) => onClick(e, value)}
      css={css`
        height: 100%;
        object-fit: contain;
        width: 100%;
      `}
    />
  )
}

/**
 */
export const Image = ({
  value,
  render: Renderer = DefaultImageRenderer,
  onClick = (value, e) => {},
  ...props
}) => {
  const { url: src = null, label: alt = null } = value

  return (
    <Renderer
      value={{ src, alt }}
      {...props}
      onClick={(e) => onClick({ ...e, data: value })}
    />
  )
}

/**
 */
export const Thumbnail = ({
  value,
  render: Renderer = DefaultImageRenderer,
  size: preferSize,
  recoverWith = 'first', // "first" or "original"
  onClick = (e) => {},
  ...props
}) => {
  const { url: originalSrc, label: alt = '', thumbnails = [] } = value

  const size = (() => {
    switch (recoverWith) {
      case 'original':
        return thumbnails.find((thumb) => thumb.name === preferSize)
      case 'first':
      default:
        return [preferSize, thumbnails.map((v) => v.name)]
          .flat()
          .find((size) => {
            // true if the size existed.
            return thumbnails.find((thumb) => thumb.name === size)
          })
    }
  })()

  const { url: src = originalSrc } =
    thumbnails.find((thumb) => thumb.name === size) ?? {}

  return (
    <Renderer
      value={{ src, alt }}
      {...props}
      onClick={(e) => onClick({ ...e, data: value })}
    />
  )
}
