import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as config from '@arch-log/webapp.modules/config/config'
import * as Styled from './Styled'

export const Default = ({
  components: {
    ParentIcon = Styled.ParentIcon,
    ChildIcon = Styled.ChildIcon,
  } = {},
}) => {
  const {
    product: { isParentProduct, isChildProduct },
  } = React.useContext(Product.Context)

  if (config.get('product.debug_icon_enabled')) {
    if (isParentProduct) {
      return <ParentIcon />
    }

    if (isChildProduct) {
      return <ChildIcon />
    }
  }

  return null
}
