import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Defaults from '../DefaultComponents'
import { createGroup } from '../utils'

import * as CatalogFields from './Catalog'

/**
 */
export const Catalog = createGroup(
  'BasicInformations_Catalog',
  (props) => (
    <>
      <CatalogFields.Catalogs {...props} />
    </>
  ),
  Product.Properties.BasicInformation.HasCatalogs,
)
