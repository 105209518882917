import React from 'react'

import image from './images/archicad.svg'
import imageGray from './images/archicad_gray.svg'
import * as styles from './styles.module.scss'

import { classNames } from '@arch-log/webapp.lib/CssHelper'

/**
 */
export const ArchiCAD = ({ className, grayscale = false }) => (
  <img
    src={ grayscale ? imageGray : image }
    alt="archicad"
    title="archicad"
    className={
      className ?? styles.Image
    }
  />
)
