import React from 'react'

import { Context } from './Context'
import { query } from './query'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({
  fromProject,
  toProject,
  productIds,
  components: { ProgressOrContent, Progress } = {},
  onCompleted,
  onError,
  children,
  ...props
}) => {
  const [copyProjectProducts, { data, loading, error, called }] = useMutation(
    query,
    {
      variables: {
        fromProjectId: String(fromProject),
        toProjectId: String(toProject),
        productIds: productIds,
      },
      onCompleted,
      onError,
    },
  )

  return (
    <Context.Provider
      value={{
        copyEntry: copyProjectProducts,
        productIds,
        data,
        loading,
        called,
        error,
        ...props,
      }}
    >
      <ProgressOrContent components={{ Progress }}>
        {children}
      </ProgressOrContent>
    </Context.Provider>
  )
}
