import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Id = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Label = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Name = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Code = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const RegisteredAt = ({
  render: Renderer = ValueRenderers.DateTimeRenderer,
  value,
  defaultValue = null,
  locale = 'ja',
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} locale={locale} {...props} />
}

/**
 */
export const UpdatedAt = ({
  render: Renderer = ValueRenderers.DateTimeRenderer,
  value,
  defaultValue = null,
  locale = 'ja',
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} locale={locale} {...props} />
}
