import React from 'react'

import * as SwitchableContent from '@arch-log/webapp.atomics/components/SwitchableContent'
import * as Layout from './Layout'
import * as Styled from './Styled'

/**
 */
export const Container = ({
  children,
  default: defaultContent,
  components: { Layout: { Wrapper = Layout.Container } = {} } = {},
}) => (
  <SwitchableContent.Initializer default={defaultContent}>
    <Wrapper>{children}</Wrapper>
  </SwitchableContent.Initializer>
)

/**
 */
export const Menus = ({
  children,
  components: { Layout: { Container = Layout.MenuSection } = {} } = {},
}) => <Container>{children}</Container>

/**
 */
export const Menu = ({
  children,
  target,
  components: {
    Button = Styled.MenuButton,
    Layout: { Wrapper = Layout.MenuItem } = {},
  } = {},
}) => {
  const { selected, setSelected } = React.useContext(SwitchableContent.Context)

  return (
    <Wrapper>
      <Button
        isActive={selected === target}
        onClick={() => setSelected(target)}
      >
        {children}
      </Button>
    </Wrapper>
  )
}

/**
 */
export const Content = ({
  children,
  name,
  components: { Layout: { Wrapper = Layout.ContentSection } = {} } = {},
}) => (
  <SwitchableContent.Content name={name}>
    <Wrapper>{children}</Wrapper>
  </SwitchableContent.Content>
)
