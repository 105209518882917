import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as DefaultComponents from '../DefaultComponents'

import { createField, createFieldWithRows } from '../utils'

/**
 */
export const Author = createField(
  'Identifiers_Author',
  () => <Product.Properties.Identifier.Author />,
  Product.Properties.Identifier.HasAuthor,
)

/**
 */
export const BrandName = createField(
  'Identifiers_BrandName',
  () => <Product.Properties.Identifier.Manufacturer.BrandName />,
  Product.Properties.Identifier.Manufacturer.HasBrandName,
)

/**
 */
export const ProductFamily = createField(
  'Identifiers_ProductFamily',
  () => <Product.Properties.Identifier.ProductFamily />,
  Product.Properties.Identifier.HasProductFamily,
)

/**
 */
export const ProductName = createField(
  'Identifiers_ProductName',
  () => <Product.Properties.Identifier.Name />,
  Product.Properties.Identifier.HasName,
)

/**
 */
export const ProductId = createField(
  'Identifiers_ProductId',
  () => <Product.Properties.Identifier.Codes.Current />,
  Product.Properties.Identifier.Codes.HasCurrent,
)

/**
 */
export const OldProductIds = createFieldWithRows(
  'Identifiers_OldProductIds',
  ({
    components: {
      List: {
        Container = DefaultComponents.List,
        Item = DefaultComponents.ListItem,
      } = {},
    } = {},
  }) => (
    <Container>
      <Product.Properties.Identifier.Codes.Olds.Each>
        <Item>
          <Product.Properties.Identifier.Codes.Old.Value />
        </Item>
      </Product.Properties.Identifier.Codes.Olds.Each>
    </Container>
  ),
  //FIXME
  Product.Properties.Identifier.Codes.HasOlds,
)

/**
 */
export const ManufacturerCode = createField(
  'Identifiers_ManufacturerCode',
  () => <Product.Properties.Identifier.Manufacturer.Code />,
  Product.Properties.Identifier.Manufacturer.HasCode,
)

/**
 */
export const ManufacturerUrl = createField(
  'Identifiers_ManufacturerUrl',
  () => (
    <Product.Properties.Identifier.Manufacturer.Url
      target="_blank"
      rel="noreferrer noopener"
    />
  ),
  Product.Properties.Identifier.Manufacturer.HasUrl,
)
