import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import { createField } from '../../utils'

/**
 */
export const ApplianceType = createField(
  'Classifications_Lightning_ApplianceType',
  () => (
    <>
      <Product.Properties.Classifications.ApplianceType />
    </>
  ),
  Product.Properties.Classifications.HasApplianceType,
)

/**
 */
export const WattageType = createField(
  'Classifications_Lightning_WattageType',
  () => (
    <>
      <Product.Properties.Classifications.WattageType />
    </>
  ),
  Product.Properties.Classifications.HasWattageType,
)

/**
 */
export const LampType = createField(
  'Classifications_Lightning_LampType',
  () => (
    <>
      <Product.Properties.Classifications.LampType />
    </>
  ),
  Product.Properties.Classifications.HasLampType,
)

/**
 */
export const LightSourceColorDescription = createField(
  'Classifications_Lightning_LightSourceColorDescription',
  () => (
    <>
      <Product.Properties.Classifications.LightSourceColorDescription />
    </>
  ),
  Product.Properties.Classifications.HasLightSourceColorDescription,
)

/**
 */
export const LightDistributionType = createField(
  'Classifications_Lightning_LightDistributionType',
  () => (
    <>
      <Product.Properties.Classifications.LightDistributionType />
    </>
  ),
  Product.Properties.Classifications.HasLightDistributionType,
)

/**
 */
export const LightBeamAngleType = createField(
  'Classifications_Lightning_LightBeamAngleType',
  () => (
    <>
      <Product.Properties.Classifications.LightBeamAngleType />
    </>
  ),
  Product.Properties.Classifications.HasLightBeamAngleType,
)

/**
 */
export const SurfaceMountedOrRecessed = createField(
  'Classifications_Lightning_SurfaceMountedOrRecessed',
  () => (
    <>
      <Product.Properties.Classifications.SurfacemountedOrRecessed />
    </>
  ),
  Product.Properties.Classifications.HasSurfacemountedOrRecessed,
)
