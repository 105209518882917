import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const ParentIcon = () => (
  <div className={ styles.ParentIcon }>P</div>
)

/**
 */
export const ChildIcon = () => (
  <div className={ styles.ChildIcon }>C</div>
)
