import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

export const Reset = translated('module.product')('Search.Reset')

export const BIMSearch = translated('module.product')('Search.BimSearch')

export const Keyword = translated('module.product')('Search.Keyword')

export const useEmbeddedTranslated = () => {
  const { t } = useTranslation('module.project.product')

  return {
    keywordPlaceholder: t('List.Filter.Keyword.Placeholder'),
  }
}
