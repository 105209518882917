import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

export const Default = ({
  condition,
  onCategory,
  onManufacturer,
  onChangeCondition,
  onReset,
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Row = Layout.Row,
      Conditions = Layout.Conditions,
      Buttons = Layout.Buttons,
      ConditionKeyword = Layout.ConditionKeyword,
      ConditionMisc = Layout.ConditionMisc,
      ConditionGroup = Layout.ConditionGroup,
    } = {},
  } = {},
}) => (
  <Wrapper>
    <Row>
      <Conditions>
        <ConditionKeyword>
          <Containers.KeywordFilter
            onChangeValue={(v) => {
              onChangeCondition({ keyword: v })
            }}
            value={condition.keyword}
          />
        </ConditionKeyword>
        <ConditionMisc>
          <Containers.BIMFilter
            onChangeValue={(v) => {
              onChangeCondition({ onlyBimSupport: v })
            }}
            value={condition.onlyBimSupport}
          />
        </ConditionMisc>
        <ConditionGroup>
          <ConditionMisc>
            <Containers.CategoryFilter onClick={onCategory} />
          </ConditionMisc>
          <ConditionMisc>
            <Containers.ManufacturerFilter onClick={onManufacturer} />
          </ConditionMisc>
        </ConditionGroup>
      </Conditions>
      <Buttons>
        <Containers.Reset onReset={onReset} />
      </Buttons>
    </Row>
  </Wrapper>
)
