export const FireRetardant = [
  'fire_retardant_code',
  'fire_retardant_class',
  'fire_retardant',
  'incombustibility_certification',
  'incombustibility_certification_number',
  'quasi_noncombustible_certification',
  'quasi_noncombustible_certification_number',
  'flame_retardant',
  'flame_retardant_certification_number',
]

export const SoundInsulation = [
  'sound_insulation_estimated',
  'sound_insulation_delta_l_rating',
  'sound_insulation_tld_value',
]

export const Standard = [
  'jis',
  'jan',
  'heat_resistance_class_jis',
  'light_shielding_grade',
]

export const Environment = [
  'seaa_mark',
  'sek_mark',
  'wallpaper_sv_mark',
  'formaldehyde_radiation_grade',
  'low_voc',
  'four_voc',
  'energy_saving_label',
]

export const Other = ['safe_goods_mark', 'better_living_mark']
