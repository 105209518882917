import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import * as Base from '@arch-log/webapp.shared/VerticalAddedProductSummary.v2'

import * as Layout from './Layout'
import * as DefaultComponents from './DefaultComponents'
import * as Selected from './Selected'

/**
 */
export const Default = withDefaultRenderComponent((props) => {
  const {
    components: {
      Layout: { Container = Layout.Container } = {},
      CloseButton = DefaultComponents.CloseButton,
      Content: contentComponents,
    },
  } = props

  const { setSelected } = React.useContext(Selected.Context)

  return (
    <Container>
      <CloseButton onClose={() => setSelected(null)} />
      <Base.Default {...props} components={contentComponents} />
    </Container>
  )
})
