import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ProductCategoryEntries {
    productCategories(size: 10000) {
      entries {
        id
        labels {
          en
          ja
        }
        depth
        countProducts
      }
      total
      requestSize
      offset
    }
  }
`
