import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  copyData,
  components: {
    Message: messageComponents,
    Complete: completeComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
  onComplete,
}) => {
  return (
    <Wrapper>
      <Body>
        <Containers.Message
          components={messageComponents}
          copyData={copyData}
        />
      </Body>
      <Footer>
        <Containers.CompleteButton
          onComplete={() => onComplete()}
          components={completeComponents}
        />
      </Footer>
    </Wrapper>
  )
}
