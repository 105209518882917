import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  components: { Layout: { Wrapper = Layout.Wrapper } = {} } = {},
}) => (
  <Wrapper>
    <Containers.ArchiCAD/>
    <Containers.Revit/>
    <Containers.SketchUp/>
  </Wrapper>
)
