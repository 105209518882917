import React from 'react'

import * as BaseFilter from '@arch-log/webapp.shared/AddedProduct.v2/List/Condition/Filter'
import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const CategoryFilter = ({
  onClick,
  components: { Button = Styled.ToggleButton } = {},
}) => {
  return (
    <Button onClick={onClick}>
      <BaseFilter.Category.Selected.Reference />
    </Button>
  )
}

/**
 */
export const ManufacturerFilter = ({
  onClick,
  components: { Button = Styled.ToggleButton } = {},
}) => {
  return (
    <Button onClick={onClick}>
      <BaseFilter.Manufacturer.Selected.Reference />
    </Button>
  )
}

/**
 * FIX ME
 *  <Literals.Keyword />
 */
export const KeywordFilter = ({
  onChangeValue,
  value = '',
  components: { Input = Styled.KeywordInput } = {},
}) => {
  const { keywordPlaceholder: placeholder } = Literals.useEmbeddedTranslated()

  const [keyword, setKeyword] = React.useState(value)

  React.useEffect(() => {
    setKeyword(value)
  }, [value])

  return (
    <Input
      placeholder={placeholder}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && value !== e.target.value) {
          onChangeValue(e.target.value, e)
        }
      }}
      onChange={(e) => {
        setKeyword(e.target.value)
      }}
      onBlur={(e) => {
        if (value !== e.target.value) {
          onChangeValue(e.target.value, e)
        }
      }}
      value={keyword}
    />
  )
}

/**
 * FIX ME
 */
export const BIMFilter = ({
  onChangeValue,
  value = false,
  components: { CheckBox = Styled.BimCheckBox } = {},
}) => {
  return (
    <CheckBox onChangeValue={onChangeValue} value={value}>
      <Literals.BIMSearch />
    </CheckBox>
  )
}

export const Reset = ({
  onReset,
  components: { Button = Styled.ResetButton } = {},
}) => {
  return (
    <Button onReset={onReset}>
      <Literals.Reset />
    </Button>
  )
}
