import React from 'react'

import { Context } from './Context'
import { Context as ParentContext } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

import {
  withInitContext,
  selectContextValue,
  withContextValue,
} from '@arch-log/webapp.modules/utils/withContext'

/**
 *
 */
export const FilterByNames = withInitContext(Context, () => {
  return selectContextValue(ParentContext)((data) => data.entry.appearances)
})(
  withContextValue(
    Context,
    /* selector = */
  )(({ children, value: values, names, ...props }) => {
    const filtered = values.filter(({ name }) => {
      return names.includes(name)
    })

    return <Context.Provider value={filtered}>{children}</Context.Provider>
  }),
)
