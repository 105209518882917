import { Context } from './Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import * as Renderers from '@arch-log/webapp.modules/product.v2/components/PropertyRenderers/Image'

/**
 * Scoped Renderer
 */
export const Thumbnail = withContextValue(Context, (data) => ({
  src: data.thumbnailUrl,
  originalUrl: data.originalUrl,
  thumbnailUrl: data.thumbnailUrl,
}))(Renderers.Image)

/**
 */
export const Original = withContextValue(Context, (data) => ({
  src: data.originalUrl,
  originalUrl: data.originalUrl,
  thumbnailUrl: data.thumbnailUrl,
}))(Renderers.Image)
