// extracted by mini-css-extract-plugin
export var AppendButton = "styles-module--AppendButton--SeqFS";
export var ButtonContainer = "styles-module--ButtonContainer--CGjcB";
export var CloseButton = "styles-module--CloseButton--EEx7E";
export var Description_Container = "styles-module--Description_Container--ljiXA";
export var Description_Text = "styles-module--Description_Text--koXuP";
export var InputLabel = "styles-module--InputLabel--hRBbO";
export var LinkToTagsButton = "styles-module--LinkToTagsButton--Et4xd";
export var NamespaceNotDefined_Discription = "styles-module--NamespaceNotDefined_Discription--atmCE";
export var RemoveButton = "styles-module--RemoveButton--SzTtM";
export var SelectLabel = "styles-module--SelectLabel--QjKaX";
export var SelectTagGroup = "styles-module--SelectTagGroup--YT0Oz";
export var TagNameInput = "styles-module--TagNameInput--2dTZ5";
export var Title = "styles-module--Title--CjAal";