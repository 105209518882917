import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ProductCategoryChildEntries($parent: String) {
    productCategories(parent: $parent, size: 10000) {
      entries {
        id
        depth
        labels {
          en
          ja
        }
        countProducts
      }
      total
    }
  }
`
