import React from 'react'

import * as Category from '@arch-log/webapp.modules/product-category.v2/components/Scope/Category'
import * as Languages from '@arch-log/webapp.modules/i18n/components/Languages'

import { Context } from './Context'
import * as Containers from './Containers'

/**
 */
const DefaultRenderer = ({ category, ...props }) => {
  const { language } = React.useContext(Languages.Context)

  if (!category) {
    return <Containers.NotSelected />
  }

  return <Category.Properties.Label language={language} {...props} />
}

/**
 * <Reference components={{ Empty }}>
 * </Reference>
 */
export const Reference = ({
  children,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const { category } = React.useContext(Context)

  return (
    <Category.Provider {...category}>
      <Renderer category={category} {...props} />
    </Category.Provider>
  )
}
