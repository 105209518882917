import React from 'react'

import * as Literals from './Literals'
import * as styles from './styles.module.scss'

export const HasParent = () => (
  <div className={styles.Icon} title="Parent Product">
    <Literals.HasParent />
  </div>
)
