import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Selected from './Selected'
import * as Styled from './Styled'
import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as styles from './styles.module.scss'

/**
 */
export const SelectedImage = withDefaultRenderComponent(({}) => {
  const { selected } = React.useContext(Selected.Context)

  return (
    <Product.Properties.Image.Full
      image={selected}
      className={styles.SelectedImage}
    />
  )
})

/**
 */
export const Info = ({
  components: { LabelDecorator = Styled.Label } = {},
}) => {
  const { selected } = React.useContext(Selected.Context)

  const formatter = (label) => {
    switch (label) {
      case 'example':
        return '施工例画像'

      default:
        return label
    }
  }

  return (
    <>
      <Product.Properties.ManufacturerName
        className={styles.ManufacturerName}
      />
      <Product.Properties.ProductCode className={styles.ProductCode} />
      <LabelDecorator>{formatter(selected.label)}</LabelDecorator>
    </>
  )
}

/**
 */
export const Thumbnails = ({}) => (
  <Product.Properties.Images.Each>
    <Thumbnail />
  </Product.Properties.Images.Each>
)

export const Thumbnail = () => {
  const { selected, setSelected } = React.useContext(Selected.Context)

  // Should be use thumbnail but cause of publishing process, now use full size image for here
  return (
    <Product.Properties.Image.Full
      render={({ image, src }) => {
        return (
          <Product.Properties.Image.Full
            className={
              selected.src == src
                ? styles.ThumbnailImage_Selected
                : styles.ThumbnailImage
            }
            onClick={() => {
              setSelected(image)
            }}
          />
        )
      }}
    />
  )
}
