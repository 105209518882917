import React from 'react'

import * as BoundProduct from '@arch-log/webapp.modules/product/components/Reference'
import * as BoundNamespace from '@arch-log/webapp.modules/project.product.tag/components/Namespaces/Reference'
import * as BoundTags from '@arch-log/webapp.modules/project.product.tag/components/Tags'
import * as BoundTag from '@arch-log/webapp.modules/project.product.tag/components/Tags/Reference'

import * as Literals from './Literals'
import * as Layout from './Layout'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 * Default Container for NamespacedTags
 *
 * Should be Initialized
 *   - @arch-log/webapp.modules/project.product.tag/components/Tags/Initializer
 *   - (@arch-log/webapp.modules/project.product.tag/components/Tags/Actions/Initializer)
 */
export const Tags = (props) => {
  const { product } = React.useContext(BoundProduct.Context)

  return (
    <BoundNamespace.Tags.Initializer product={product.id} withActions={true}>
      <BaseTags {...props} />
    </BoundNamespace.Tags.Initializer>
  )
}

const BaseTags = ({
  editable = true,
  components: {
    Tag: tagComponents,
    Layout: { List = Layout.TagList, ListItem = Layout.TagListItem } = {},
  } = {},
  //onTagAppend = DefaultOnTagAppend,
}) => {
  const { addTag, removeTag } = React.useContext(BoundTags.Actions.Context)

  const onTagAppend = (v, e) => {
    addTag(v)
  }

  const onTagDelete = (v) => {
    removeTag(v)
  }

  return (
    <>
      <List>
        <BoundTags.Each
          render={({ namespace: { label } }) => (
            <ListItem>
              <Tag
                components={tagComponents}
                canDelete={editable}
                onDelete={() => onTagDelete(label)}
              />
            </ListItem>
          )}
        />
      </List>
      <TagAppender onTagAppend={onTagAppend} canAppend={editable} />
    </>
  )
}

/**
 * Default Container for NamespacedTag
 */
export const Tag = ({
  canDelete = true,
  onDelete,
  components: { TagName = Styled.TagName } = {},
}) => {
  return (
    <TagName onDelete={onDelete} canDelete={canDelete}>
      <BoundTag.Properties.Label />
    </TagName>
  )
}

const Fragment = ({ children }) => <>{children}</>

/**
 */
export const TagAppender = ({
  wrapper: Wrapper = Fragment,
  canAppend = true,
  onTagAppend,
}) => {
  if (!canAppend || !onTagAppend) {
    return null
  }

  const { formatPlaceholder } = Literals.useLiterals()

  return (
    <Wrapper>
      <input
        className={styles.TagAppender}
        type="text"
        placeholder={formatPlaceholder('TagAppender')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()

            // onTagAppend
            if (onTagAppend) {
              onTagAppend(e.target.value, e)
            }

            e.target.value = ''
          }
        }}
      />
    </Wrapper>
  )
}
