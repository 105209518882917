import React from 'react'

import * as Base from '@arch-log/webapp.shared/Product.v2/CategoryFilter'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as selectorStyles from './selector.module.scss'

export * from '../Template/Containers'

/**
 */
export const FilterInput = Base.FilterInput

/**
 *
 */
export const Selector = () => (
  <>
    <Base.SelectedHierarchy />
    <Base.Selections styles={selectorStyles} showProductCount={false} />
  </>
)

/**
 */
export const Title = ({ components: { Wrapper = Styled.Title } = {} }) => (
  <Wrapper>
    <Literals.Title />
  </Wrapper>
)

/**
 */
export const Reset = ({
  onReset,
  components: { Wrapper = Styled.ResetButton } = {},
}) => {
  const { reset } = Base.Selected.useActions()
  const { resetPattern } = Base.Filter.Pattern.useActions()

  return (
    <Wrapper
      onReset={() => {
        reset()
        resetPattern()
      }}
    >
      <Literals.Reset />
    </Wrapper>
  )
}
