import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { Context } from './Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const Value = withContextValue(
  Context,
  (data) => data,
)(Renderers.Note.Value)
