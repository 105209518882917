import React from 'react'

import image from './images/sketchup.svg'
import imageGray from './images/sketchup_gray.svg'
import * as styles from './styles.module.scss'

import { classNames } from '@arch-log/webapp.lib/CssHelper'

/**
 */
export const SketchUp = ({ className, grayscale = false }) => (
  <img
    src={ grayscale ? imageGray : image }
    alt="sketchup"
    title="sketchup"
    className={
      className ?? styles.Image
    }
  />
)
