import React from 'react'

import { Default } from './Default'

import * as Modal from '@arch-log/webapp.atomics/components/Modal'

/**
 */
export const useActions = () => {
  const { show, hide } = React.useContext(Modal.Context)

  const showModal = ({ copyData }) => {
    show(() => <Default copyData={copyData} onComplete={() => hide()} />)
  }

  return {
    showModal,
    hide,
  }
}
