import React from 'react'

import * as Steps from './Steps'
import * as ProjectProduct from '@arch-log/webapp.modules/project.product.v2/components'
import { ValidateDeleteProjectProductEntriesInitializer } from './ValidateDeleteProjectProductEntriesInitializer'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import * as Containers from './Containers'
import { createServiceProducts, createTags } from './Model'

const BaseFlow = createFlow([
  Steps.Init,
  Steps.QueryProgress,
  Steps.Confirm,
  Steps.Progress,
  Steps.Complete,
])

const BaseFlowWrapper = ({ ...props }) => {
  const { entries } = ProjectProduct.Scope.DeprecatedEntries.useEntries()
  const {
    entries: validateEntries,
  } = ProjectProduct.Remote.Queries.ValidateDeleteProjectProductEntries.useQuery()

  const tags = createTags(validateEntries)

  const serviceProducts = createServiceProducts(tags, validateEntries, entries)

  return <BaseFlow tags={tags} serviceProducts={serviceProducts} {...props} />
}

/**
 */
export const Flow = ({ projectId, productIds, ...props }) => {
  return (
    <ProjectProduct.Remote.Queries.Entries.AllLoader
      projectId={projectId}
      fetchPolicy="network-only"
      components={{ Loading: Containers.QueryLoading }}
    >
      <ValidateDeleteProjectProductEntriesInitializer
        projectId={projectId}
        productIds={productIds}
        components={{ Loading: Containers.QueryLoading }}
        fetchPolicy="network-only"
      >
        <ProjectProduct.Remote.Mutations.DeleteEntries.Initializer
          projectId={projectId}
          productIds={productIds}
        >
          <BaseFlowWrapper
            projectId={projectId}
            productIds={productIds}
            {...props}
          />
        </ProjectProduct.Remote.Mutations.DeleteEntries.Initializer>
      </ValidateDeleteProjectProductEntriesInitializer>
    </ProjectProduct.Remote.Queries.Entries.AllLoader>
  )
}
