import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({
  isRelatedProductCountEnabled = false,
  children,
}) => {
  return (
    <Context.Provider
      value={{
        isRelatedProductCountEnabled,
      }}
    >
      {children}
    </Context.Provider>
  )
}
