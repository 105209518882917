import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const BackButton = ({ children, onBack }) => (
  <div onClick={onBack} className={styles.BackButton}>
    {children}
  </div>
)

/**
 */
export const ResetButton = ({ children, onReset }) => (
  <div onClick={onReset} className={styles.ResetButton}>
    {children}
  </div>
)

export const Title = ({ children }) => (
  <div className={styles.Title}>{children}</div>
)

/**
 */
export const Back = () => <Icons.NaviBefore />
