import { gql } from '@apollo/client'

export const query = gql`
  query projectProductEntries($projectId: UUID!, $offset: Int, $size: Int) {
    result: projectProductEntries(
      projectV2Id: $projectId
      offset: $offset
      size: $size
    ) {
      entries {
        id
        label
        oldId
        code
        manufacturingInfo {
          manufacturer {
            name
            labels {
              ja
              en
            }
            shortLabels {
              ja
              en
            }
          }
          #countryOfOrigin
          #countryOfManufactured
        }
        medias {
          type 
          url
          label
          thumbnails {
            name
            url
          }
        }
        images {
          url
          label
          thumbnails {
            name
            url
          }
        }
        categories {
          name
          labels {
            ja
            en
          }
        }
      }
      total
      offset
      requestSize
    }
  }
`
