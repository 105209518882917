import React from 'react'

import * as BaseNamespaces from '@arch-log/webapp.modules/project.product.tag/components/Namespaces'
import * as BaseNamespace from '@arch-log/webapp.modules/project.product.tag/components/Namespaces/Reference'

import * as NamespacedTags from '@arch-log/webapp.shared/AddedProductTags/NamespacedTags'

import * as Layout from './Layout'
import * as Styled from './Styled'

/**
 * Default Container for Namespaces
 */
export const Namespaces = ({
  components: {
    Namespace: namespaceComponents,
    Tags: tagsComponents,
    Tag: tagComponents,
    Layout: { Wrapper = Layout.NamespacesWrapper } = {},
  } = {},
  ...props
}) => (
  <Wrapper>
    <BaseNamespaces.Loaders.AtOnce>
      <BaseNamespaces.Each>
        <Namespace
          components={{
            ...namespaceComponents,
            Tags: tagsComponents,
            Tag: tagComponents,
          }}
          {...props}
        />
      </BaseNamespaces.Each>
    </BaseNamespaces.Loaders.AtOnce>
  </Wrapper>
)

/**
 * Default Namespace Container
 */
export const Namespace = ({
  components: {
    Tags: tagsComponents,
    Tag: tagComponents,
    Layout: {
      Wrapper = Layout.NamespaceWrapper,
      TitleSection = Layout.NamespaceTitleSection,
      TagsSection = Layout.NamespaceTagsSection,
    } = {},
  } = {},
  editable = false,
  ...props
}) => (
  <Wrapper>
    <TitleSection>
      <NamespaceName />
    </TitleSection>
    <TagsSection>
      <NamespacedTags.Default
        editable={ editable }
        components={{
          ...tagsComponents,
          Tag: {
            ...(tagsComponents?.Tag ?? {}),
            ...tagComponents,
          },
        }}
      />
    </TagsSection>
  </Wrapper>
)

/**
 * Default NamespaceName Container
 */
export const NamespaceName = ({
  components: { Decorator = Styled.NamespaceName } = {},
}) => (
  <Decorator>
    <BaseNamespace.Properties.Name />
  </Decorator>
)
