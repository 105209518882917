import React from 'react'

import * as Containers from './Containers'
import * as InnerPanes from './Panes'
import * as Layout from './Layout'
import * as Filter from '@arch-log/webapp.shared/AddedProduct.v2/List/Condition/Filter'

/**
 *
 */
export const Default = ({
  components: {
    Title: titleComponents,
    keyword: keywordComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      Body = Layout.Body,
    } = {},
  } = {},
}) => {
  const { condition, setCondition } = React.useContext(
    Filter.LocalCondition.Context,
  )
  const { reset: resetCat } = React.useContext(Filter.Category.Selected.Context)
  const { resetSelected: resetMan } = React.useContext(
    Filter.Manufacturer.Selected.Context,
  )

  return (
    <Wrapper>
      <Header>
        <Containers.Title components={titleComponents} />
      </Header>
      <Body>
        <InnerPanes.Default
          onChangeCondition={(v) => setCondition({ ...condition, ...v })}
          condition={condition}
          onReset={() => {
            resetCat()
            resetMan()
            setCondition({ keyword: '', onlyBimSupport: false })
          }}
        />
      </Body>
    </Wrapper>
  )
}
