import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import { Context as AddedProductContext } from '@arch-log/webapp.modules/project.product/components/Reference'
//
import * as DefaultComponents from './DefaultComponents'
import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 *
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      BIM = DefaultComponents.BIMIcon,
      HasParent = DefaultComponents.ParentIcon,
      Background = DefaultComponents.Background,
      Title = DefaultComponents.Title,
      Layout: { Container = Layout.Container } = {},
    } = {},
    onClickEntry = (product) => {},
  }) => {
    const { addedProduct } = React.useContext(AddedProductContext)

    return (
      <Container onClick={() => onClickEntry(addedProduct)}>
        <Background />
        <BIM />
        <HasParent />
        <Containers.ParentChildIcon />
        <Title />
      </Container>
    )
  },
)
