import React from 'react'

import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'

/**
 */
export const useActions = () => {
  const { show: showModal, hide } = React.useContext(Modal.Context)

  const show = ({ projectId, productIds }) => {
    showModal(() => (
      <Content.Flow
        projectId={projectId}
        productIds={productIds}
        onComplete={() => {
          hide()
        }}
        onCancel={() => hide()}
      />
    ))
  }

  return {
    show,
    closeModal: hide,
  }
}
