import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import { createField } from '../utils'

/**
 */
export const OmniclassNumber = createField(
  'ArchitecturalClassification_OmniclassNumber',
  () => <Product.Properties.ArchitecturalClassification.Omniclass.Number />,
  Product.Properties.ArchitecturalClassification.Omniclass.HasNumber,
)

/**
 */
export const OmniclassVersion = createField(
  'ArchitecturalClassification_OmniclassVersion',
  () => <Product.Properties.ArchitecturalClassification.Omniclass.Version />,
  Product.Properties.ArchitecturalClassification.Omniclass.HasVersion,
)

/**
 */
export const UniformatCode = createField(
  'ArchitecturalClassification_UniformatCode',
  () => <Product.Properties.ArchitecturalClassification.Uniformat.Code />,
  Product.Properties.ArchitecturalClassification.Uniformat.HasCode,
)

/**
 */
export const UniformatVersion = createField(
  'ArchitecturalClassification_UniformatVersion',
  () => <Product.Properties.ArchitecturalClassification.Uniformat.Version />,
  Product.Properties.ArchitecturalClassification.Uniformat.HasVersion,
)

/**
 */
export const RevitFamilyCategoryId = createField(
  'ArchitecturalClassification_RevitFamilyCategoryId',
  () => (
    <Product.Properties.ArchitecturalClassification.RevitFamilyCategory.Id />
  ),
  Product.Properties.ArchitecturalClassification.RevitFamilyCategory.HasId,
)

/**
 */
export const RevitFamilyCategoryNameJa = createField(
  'ArchitecturalClassification_RevitFamilyCategoryNameJa',
  () => (
    <Product.Properties.ArchitecturalClassification.RevitFamilyCategory.NameJa />
  ),
  Product.Properties.ArchitecturalClassification.RevitFamilyCategory.HasNameJa,
)

/**
 */
export const RevitFamilyCategoryNameEn = createField(
  'ArchitecturalClassification_RevitFamilyCategoryNameEn',
  () => (
    <Product.Properties.ArchitecturalClassification.RevitFamilyCategory.NameEn />
  ),
  Product.Properties.ArchitecturalClassification.RevitFamilyCategory.HasNameEn,
)
