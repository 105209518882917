import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'
import * as Routes from '@arch-log/webapp.shared/Routes'

import { Context } from './Context'
import * as SampleRequests from '@arch-log/webapp.modules/project.sample_request/components'
import * as ProjectProducts from '@arch-log/webapp.modules/project.product/components'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectBimRequest from '@arch-log/webapp.usecases/ProjectBimRequest/AddTargetProducts/Components'
import * as Selections from './Selections'
import * as BulkCopyProductModal from 'components/BulkCopyProductModal'
import * as BulkTagEditModal from 'components/BulkTagEditModal'
import * as BulkDeleteProductModal from 'components/BulkDeleteProductModal'
import * as SampleRequestFilteredConfirmModal from 'components/SampleRequestFilteredConfirmModal'

/**
 */
export const Initializer = (props) => {
  return (
    <ProjectProducts.Actions.Initializer>
      <SampleRequests.Actions.Initializer>
        <ProjectBimRequest.Initializer>
          <Selections.Initializer>
            <BaseInitializer {...props} />
          </Selections.Initializer>
        </ProjectBimRequest.Initializer>
      </SampleRequests.Actions.Initializer>
    </ProjectProducts.Actions.Initializer>
  )
}

/**
 */
const BaseInitializer = ({ children }) => {
  // const {
  //   setStorage,
  // } = ProductBimRequest.Local.SessionStorage.useContext()
  const { addTargetProduct: addBimRequestTarget } = ProjectBimRequest.useContext()

  const { selectedIds } = React.useContext(Selections.Context)

  const [projectEntry] = Project.Scope.Entry.useEntry()

  const { redirectTo } = useNavigate()

  const { addedProducts } = React.useContext(ProjectProducts.Context)

  const { addEntries: callAddToSampleRequests } = React.useContext(
    SampleRequests.Actions.Context,
  )

  const { show: showCopyProductModal } = BulkCopyProductModal.useActions()

  const { show: showTagEditModal } = BulkTagEditModal.useActions()

  const { show: showDeleteProductModal } = BulkDeleteProductModal.useActions()

  const {
    show: showSampleRequestErrorModal,
  } = SampleRequestFilteredConfirmModal.useActions()

  const addToSampleRequests = ({ onComplete = () => {} }) => {
    const sleep = async (msec, callback) => {
      return await new Promise((resolve) => {
        setTimeout(() => {
          resolve(callback())
        }, msec)
      })
    }

    const filtered = addedProducts
      .filter((addedProduct) => selectedIds.includes(addedProduct.product_id))
      .filter((addedProduct) => {
        if (
          ['Accessories', `Demo Products`].includes(
            addedProduct?.product?.identifier?.manufacturer?.name?.en,
          )
        ) {
          return false
        }
        return true
      })

    if (filtered.length > 0) {
      callAddToSampleRequests(filtered.map((v) => v.product_id))
    }

    if (filtered.length !== selectedIds.length) {
      //
      showSampleRequestErrorModal(
        addedProducts.filter((v) =>
          ['Accessories', 'Demo Products'].includes(
            v?.product?.identifier?.manufacturer?.name?.en,
          ),
        ),
        {
          onHidden: () => {
            sleep(500, onComplete)
          },
        },
      )
    } else {
      sleep(500, onComplete)
    }
  }

  const removeEntries = () => {
    showDeleteProductModal({
      projectId: projectEntry.uuid,
      productIds: selectedIds,
    })
  }

  const requestBims = () => {
    // const url = Routes.BimRequest.toTop({
    //   ids: selectedIds,
    //   project: projectEntry.id,
    // })

    addBimRequestTarget({
      productIds: selectedIds,
      projectId: projectEntry?.uuid,
    })

    redirectTo(
      Routes.BimRequest.toTop({
        // ids: selectedIds,
        project: projectEntry.uuid,
      }),
    )
  }

  const copyProducts = () => {
    showCopyProductModal({
      copyProductIds: selectedIds,
      fromProject: projectEntry.id,
    })
  }

  const editTags = () => {
    showTagEditModal({ projectId: projectEntry.id, products: selectedIds })
  }

  return (
    <Context.Provider
      value={{
        selectedIds,
        removeEntries,
        addToSampleRequests,
        requestBims,
        editTags,
        copyProducts,
      }}
    >
      {children}
    </Context.Provider>
  )
}
