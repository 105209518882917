import React from 'react'

import { useLocalStorage } from 'react-use'
import { Key } from '@arch-log/webapp.usecases/ProjectBimRequest/Base/LocalStorageRules.js'

import { CURRENT_VERSION, States } from '@arch-log/webapp.usecases/ProjectBimRequest/Base/LocalStorage/constants.js'

export const useStorage = () => {
  const [storage = {}, setStorage] = useLocalStorage(Key)

//  useEffect(() => {
//    // componentWillUnmount
//    return () => {
//      // clean
//      setStorage((prev) => {
//        const { version = null, entries = {} } = prev
//        return {
//          version,
//          entries: Object.fromEntries(
//            Object.entries(entries).filter(
//              (entry) => entry.state !== States.COMPLETED,
//            ),
//          ),
//        }
//      })
//    }
//  })

  const addProductIds = ({ projectId, productIds }) => {
    setStorage((prev = {}) => {
      const { version = null, entries = {} } = prev

      if (version === CURRENT_VERSION) {
        return {
          version,
          entries: {
            ...(entries ?? {}),
            [projectId]: {
              ...entries?.[projectId],
              productIds: [
                ...new Set([
                  ...(entries?.[projectId]?.productIds ?? []),
                  ...productIds,
                ]),
              ],
            },
          },
        }
      } else {
        // overwrite
        return {
          version: CURRENT_VERSION,
          entries: {
            ...entries,
            [projectId]: {
              productIds,
              state: States.INIT,
            },
          },
        }
      }
    })
  }

  return {
    addProductIds,
  }
}
