import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('module.product')('List.Sort.Title')

/**
 */
export const ProductName = translated('module.product')(
  'List.Sort.OrderBy.ProductName',
)

/**
 */
export const Popularity = translated('module.product')(
  'List.Sort.OrderBy.Popularity',
)

/**
 */
export const ProductCode = translated('module.product')(
  'List.Sort.OrderBy.ProductCode',
)

/**
 */
export const Manufacturer = translated('module.product')(
  'List.Sort.OrderBy.Manufacturer',
)

/**
 */
export const Category = translated('module.product')(
  'List.Sort.OrderBy.Category',
)

/**
 */
export const RegisteredAt = translated('module.product')(
  'List.Sort.OrderBy.RegisteredAt',
)
