import React from 'react'

/**
 */
export const DefaultImageRenderer = ({
  value,
  defaultValue = {},
  onClick,
  className,
  propsFromValue,
  ...props
}) => {
  const { alt, src } = value ?? defaultValue

  return (
    <img
      {...props}
      className={className}
      src={src}
      alt={alt}
      onClick={(e) => onClick(e, value)}
    />
  )
}

/**
 */
export const Image = ({
  render: Renderer = DefaultImageRenderer,
  ...props
}) => {
  return <Renderer {...props} />
}
