import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Label = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Value = ({
  render: Renderer = ValueRenderers.NumberRenderer,
  value: { value = null, valueDirty = null },
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={valueDirty ?? defaultValue} {...props} />
}

/**
 */
export const Unit = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value: { unitLabel = null, unitType = null },
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={unitLabel ?? defaultValue} {...props} />
}

/**
 */
export const Note = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}
