import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation DeleteProjectProductEntries(
    $projectId: UUID!
    $productIds: [UUID!]!
    $options: DeleteProjectProductEntries_Options
  ) {
    deleteProjectProductEntries(
      projectId: $projectId
      productIds: $productIds
      options: $options
    ) {
      entries {
        productId
      }
    }
  }
`
