import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => <span>{children}</span>

export const TitleIcon = () => <Icons.Filter className={styles.Title_Icon} />
