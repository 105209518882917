import React from 'react'

import { Context as ParentContext } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

import * as Image from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Properties/Image'

/**
 * Selector
 */
export const SelectOneByIndex = withContextValue(
  //
  ParentContext,
  (data) => data.entry.medias.filter((entry) => entry.type === 'IMAGE'),
)(
  //
  ({
    value: values,
    defaultValues = null,
    index,
    render: Renderer = ({ children }) => <>{children}</>,
    ...props
  }) => {
    const value = values?.[index] ?? defaultValues

    return (
      <Image.Context.Provider {...props} value={value}>
        <Renderer {...props} value={value} />
      </Image.Context.Provider>
    )
  },
)
