import React from 'react'

import * as DefaultComponents from './DefaultComponents'
import * as Components from './Components'
import * as Literals from './Literals'
import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

//const defaultComponents = {
//  Field: DefaultFieldComponents,
//  FieldGroup: DefaultFieldGroupComponents
//}

const DefaultFieldRenderer = ({
  children,
  formatLabel,
  label,
  language,
  components = {},
  ...props
}) => {
  const DefaultComponents = React.useContext(Components.Context)

  const {
    Container = DefaultComponents.FieldContainer,
    Label = DefaultComponents.FieldLabel,
    Value = DefaultComponents.FieldValue,
  } = components

  return (
    <Container>
      <Label>{formatLabel(label)}</Label>
      <Value>{children}</Value>
    </Container>
  )
}

export const createFieldWithRows = (
  label,
  ValueRenderer,
  Condition = DefaultComponents.Fragment,
  options = {},
) =>
  createField(label, ValueRenderer, Condition, {
    ...options,
    components: {
      ...(options.components ?? {}),
      Field: {
        ...(options.components?.Field ?? {}),
        Value: DefaultComponents.FieldValue_Rows,
      },
    },
  })

/**
 */
export const createField = (
  label,
  ValueRenderer,
  Condition = DefaultComponents.Fragment,
  // options
  { components: { Field: DefaultFieldComponents } = {} } = {},
) =>
  withLanguage(
    ({
      language,
      render: Renderer = DefaultFieldRenderer,
      components = {},
      formatLabel,
      ...props
    }) => {
      const { formatLabel: DefaultFormatLabel } = Literals.useLiterals()

      return (
        <Condition>
          <Renderer
            label={label}
            language={language}
            formatLabel={formatLabel ?? DefaultFormatLabel}
            {...props}
            components={{ ...DefaultFieldComponents, ...components.Field }}
          >
            <ValueRenderer language={language} {...components} {...props} />
          </Renderer>
        </Condition>
      )
    },
  )

const DefaultFieldGroupRenderer = ({
  children,
  label,
  formatLabel,
  components = {},
}) => {
  const DefaultComponents = React.useContext(Components.Context)

  const {
    Container = DefaultComponents.FieldGroupContainer,
    Label = DefaultComponents.FieldGroupLabel,
    Value = DefaultComponents.FieldGroupValue,
  } = components

  return (
    <Container>
      <Label>{formatLabel(label)}</Label>
      <Value>{children}</Value>
    </Container>
  )
}

/**
 */
export const createGroup = (
  label,
  ValueRenderer,
  Condition = DefaultComponents.Fragment,
) =>
  withLanguage(
    ({
      //
      components = {},
      render: Renderer = DefaultFieldGroupRenderer,
      language = null,
      ...props
    }) => {
      const { t, formatLabel } = Literals.useLiterals()

      return (
        <Condition>
          <Renderer
            {...props}
            label={label}
            language={language}
            formatLabel={formatLabel}
            components={components.FieldGroup}
          >
            <ValueRenderer
              components={components}
              t={t}
              formatLabel={formatLabel}
              language={language}
              {...props}
            />
          </Renderer>
        </Condition>
      )
    },
  )
