import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Left = ({ children }) => (
  <div className={styles.Left}>{children}</div>
)

/**
 */
export const Right = ({ children }) => (
  <div className={styles.Right}>{children}</div>
)

export const ThumbnailsSection = ({ children }) => (
  <div className={styles.Thumbnails}>{children}</div>
)

export const InfoSection = ({ children }) => (
  <div className={styles.Info}>{children}</div>
)
