import React from 'react'

import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'
import { Provider } from './Provider'

const range = (ids, first, second) => {
  if (first <= second) {
    return ids.slice(first, second + 1)
  } else {
    return ids.slice(second, first + 1)
  }
}

export const Initializer = ({ children }) => {
  const [state, setState] = React.useState({
    lastSelected: null,
    selectedIds: [],
  })

  const { addedProducts } = React.useContext(AddedProducts.Context)

  const productIds = React.useMemo(
    () => addedProducts.map((product) => product.product_id),
    [addedProducts],
  )

  const { lastSelected, selectedIds } = state

  const addRange = (id) => {
    const first = productIds.findIndex((v) => v === id)
    const second = productIds.findIndex((v) => v === lastSelected)

    const ids = range(productIds, first, second)

    setState({
      lastSelected,
      selectedIds: [...new Set([...selectedIds, ...ids])],
    })
  }

  const removeRange = (id) => {
    const first = productIds.findIndex((v) => v === id)
    const second = productIds.findIndex((v) => v === lastSelected)

    const ids = range(productIds, first, second)

    setState({
      ...state,
      selectedIds: selectedIds.filter((v) => !ids.includes(v)),
    })
  }

  const add = (...ids) => {
    setState({
      lastSelected: ids.slice(-1)[0],
      selectedIds: [...new Set([...selectedIds, ...ids])],
    })
  }

  const addAll = (...ids) => {
    setState({
      lastSelected: null,
      selectedIds: [...new Set([...selectedIds, ...ids])],
    })
  }

  const remove = (...ids) => {
    setState({
      lastSelected: ids.slice(-1)[0],
      ...state,
      selectedIds: selectedIds.filter((v) => !ids.includes(v)),
    })
  }

  const removeAll = () => {
    setState({
      ...state,
      selectedIds: [],
    })
  }

  return (
    <Provider
      selectedIds={selectedIds}
      actions={{
        add,
        addAll,
        addRange,
        remove,
        removeRange,
        removeAll,
      }}
    >
      {children}
    </Provider>
  )
}
