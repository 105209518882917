import React from 'react'

import { Filters } from '@arch-log/webapp.modules/product-category.v2/Filters'
import { Context } from './Context'

/**
 * Post Filter
 */
export const Filter = ({ children, filterBy = Filters.None }) => {
  const { entries = [] } = React.useContext(Context)

  const filtered = entries.filter(filterBy)

  return (
    <Context.Provider value={{ entries: filtered }}>
      {children}
    </Context.Provider>
  )
}
