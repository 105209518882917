import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import { createField } from '../../utils'

/**
 * FIXME
 */
export const Published = createField(
  'SalesDates_Published',
  Product.Properties.SalesInfo.PublishedAt,
)
