import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

/**
 */
export const FamilyCategoryId = withContextValue(
  Context,
  (data) => data.entry.bimInfo.revitExtended.familyCategoryId,
)(Renderers.BimInfo.RevitExtended.FamilyCategoryId)

/**
 */
export const FamilyCategoryLabel = withContextValue(
  Context,
  (data) => data.entry.bimInfo.revitExtended.familyCategoryLabels,
)(Renderers.BimInfo.RevitExtended.FamilyCategoryLabel)
