import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  onBack,
  onReset,
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      HeaderLeft = Layout.HeaderLeft,
      HeaderCenter = Layout.HeaderCenter,
      HeaderRight = Layout.HeaderRight,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
}) => (
  <Wrapper>
    <Header>
      <HeaderLeft>
        <Containers.Back onBack={onBack} />
        <Containers.Title />
      </HeaderLeft>
      <HeaderCenter>
        <Containers.FilterInput />
      </HeaderCenter>
      <HeaderRight>
        <Containers.Reset onReset={onReset} />
      </HeaderRight>
    </Header>
    <Body>
      <Containers.Selector />
    </Body>
    <Footer></Footer>
  </Wrapper>
)
