import React from 'react'

import * as Code from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Properties/History/Code'

import { Context } from './Context'
import { Context as ParentContext } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

import {
  withInitContext,
  selectContextValue,
  withContextValue,
} from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const Each = withInitContext(Context, () => {
  return selectContextValue(ParentContext)((data) => data.entry.history.codes)
})(
  withContextValue(
    Context,
    /* selector = */ (data) => data,
  )(({ value: values, ...props }) => {
    return values.map((value, index) => {
      return <Code.Context.Provider {...props} value={value} key={index} />
    })
  }),
)
