import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

/**
 */
export const Id = withContextValue(
  Context,
  (data) => data.entry.id,
)(Renderers.Id)

/**
 */
export const Label = withContextValue(
  Context,
  (data) => data.entry.label,
)(Renderers.Label)

/**
 */
export const Code = withContextValue(
  Context,
  (data) => data.entry.code,
)(Renderers.Code)

/**
 */
export const RegisteredAt = withContextValue(
  Context,
  (data) => data.entry.registeredAt,
)(Renderers.RegisteredAt)

/**
 */
export const UpdatedAt = withContextValue(
  Context,
  (data) => data.entry.updatedAt,
)(Renderers.UpdatedAt)

/**
 */
export const AddedAt = withContextValue(
  Context,
  (data) => data.entry.addedAt,
)(Renderers.AddedAt)

/**
 */
export const ProviderName = withContextValue(
  Context,
  (data) => data.entry.code,
)(Renderers.SalesInfo.Provider.Label)

/**
 */
export const IsLocked = withContextValue(
  Context,
  (data) => data.entry.isLocked,
)(Renderers.IsLocked)

/**
 */
export const SnapshotAt = withContextValue(
  Context,
  (data) => data.entry.snapshotAt,
)(Renderers.SnapshotAt)

/**
 */
export const IsPrivate = withContextValue(
  Context,
  (data) => data.entry.isPrivate,
)(Renderers.IsPrivate)
