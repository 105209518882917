import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

/**
 */
export const Label = withContextValue(
  Context,
  (data) => data.entry.salesInfo.provider.labels,
)(Renderers.SalesInfo.Provider.Label)

/**
 */
export const shortLabel = withContextValue(
  Context,
  (data) => data.entry.selesInfo.provider.shortLabels,
)(Renderers.SalesInfo.Provider.ShortLabel)
