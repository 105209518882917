import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Defaults from '../DefaultComponents'
import { createGroup } from '../utils'

import * as DateInformationFields from './DateInformation'

/**
 */
export const DateInformation = createGroup(
  'SalesDates_Basic',
  (props) => <DateInformationFields.Published {...props} />,
  //Product.Properties.Has,
)
