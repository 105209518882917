import React from 'react'

import * as Remote from '@arch-log/webapp.modules/product-category.v2/components/Remote'
import { Provider } from './Provider'

export const InitializeChildEntriesWithRemote = ({
  //
  parent,
  children,
}) => {

  if (parent) {
    // Filter By Parent
    return (
      <Remote.Queries.ChildEntries.Loader
        parent={parent}
        render={({ data }) => {
          return (
            <Provider entries={data?.productCategories?.entries ?? []}>
              {children}
            </Provider>
          )
        }}
      />
    )
  }

  return (
    <Remote.Queries.RootEntries.Loader
      render={({ data }) => {
        return (
          <Provider entries={(data ?? {})?.productCategories?.entries ?? []}>
            {children}
          </Provider>
        )
      }}
    />
  )
}

export const InitializeAllEntriesWithRemote = ({ ...props }) => {
  return (
    <Remote.Queries.AllEntries.Loader>
      <InitializeAllEntriesWithRemoteInner {...props} />
    </Remote.Queries.AllEntries.Loader>
  )
}

const InitializeAllEntriesWithRemoteInner = ({ ...props }) => {
  const { data } = React.useContext(Remote.Queries.AllEntries.Context)

  const entries = React.useMemo(() => {
    return (data ?? {})?.productCategories?.entries ?? []
  }, [data])

  return <Provider entries={entries} {...props} />
}
