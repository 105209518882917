import React from 'react'

import { Context } from './Context'

/**
 */
export const useActions = () => {
  const { push, pop, reset } = React.useContext(Context)

  return {
    push,
    pop,
    reset,
  }
}
