import React from 'react'

import {
  OrderBy,
  Direction,
} from '@arch-log/webapp.modules/project.product/order'

import { Provider } from './Provider'

/**
 */
export const Initializer = ({
  children,
  defaultSortKey = OrderBy.AddedAt,
  defaultDirection = Direction.Desc,
  onChange,
}) => {
  const [ordinal, setOrdinal] = React.useState({
    key: defaultSortKey,
    direction: defaultDirection,
  })

  const orderBy = (key, direction) => {
    setOrdinal({ key, direction })

    if (onChange) {
      onChange({ key, direction })
    }
  }

  return (
    <Provider
      sortKey={ordinal.key}
      direction={ordinal.direction}
      orderBy={orderBy}
    >
      {children}
    </Provider>
  )
}
