import React from 'react'

import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

/**
 */
export const useActions = () => {

  const actions  = Modal.useActions()

  const {
    show: showModal,
    hide,
  } = actions

  const show = (product, props = {}) => {
    showModal(() => (<Content.Default {...props} product={ product }/>))
  }
  
  return {
    show,
    hide,
  }
}
