import React from 'react'

import { Context } from './Context'
import { Context as ParentContext } from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntry/Context'
import * as Image  from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntry/Properties/Image'

import {
  withInitContext,
  selectContextValue,
  withContextValue,
} from '@arch-log/webapp.modules/utils/withContext'

/**
 * Initialize Context if not existed
 */
export const Each = withInitContext(
  Context,
  () => {
    return selectContextValue(ParentContext)((data) => data.entry.images)
  },
)(
  withContextValue(
    Context,
    /* selector = */ (data) => data,
  )(({ value: values, ...props }) => {
    return values.map((value, index) => {
      return <Image.Context.Provider {...props} value={value} key={index} />
    })
  }),
)
