import React from 'react'

import * as Base from '@arch-log/webapp.shared/Product.v2/ManufacturerFilter'

import * as Literals from './Literals'
import * as Styled from './Styled'
import * as selectorStyles from './selector.module.scss'

export * from '../Template/Containers'

/**
 */
export const Title = ({ components: { Wrapper = Styled.Title } = {} }) => (
  <Wrapper>
    <Literals.Title />
  </Wrapper>
)

export const Selector = (props) => (
  <Base.Selections styles={selectorStyles} {...props} />
)

export const PatternFilter = (props) => <Base.FilterInput />

/**
 */
export const Reset = ({
  onReset,
  components: { Wrapper = Styled.ResetButton } = {},
}) => {
  const { resetSelected } = Base.useActions()
  const { resetPattern } = Base.Filter.Pattern.useActions()

  return (
    <Wrapper
      onReset={() => {
        resetSelected()
        resetPattern()
      }}
    >
      <Literals.Reset />
    </Wrapper>
  )
}
