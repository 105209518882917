import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { Context } from './Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const Name = withContextValue(
  Context,
  (data) => data.name,
)(Renderers.Category.Name)

/**
 */
export const Label = withContextValue(Context, (data) => ({
  labels: data.labels,
  name: data.name,
}))(Renderers.Category.Label)
