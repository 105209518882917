import React from 'react'

import { Context } from './Context'

import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'
/**
 *
 */
export const Provider = ({ children, sortKey, direction, orderBy }) => {
  return (
    <Context.Provider value={{ key: sortKey, direction, orderBy }}>
      <AddedProducts.Sort orderBy={sortKey} direction={direction}>
        {children}
      </AddedProducts.Sort>
    </Context.Provider>
  )
}
