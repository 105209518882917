import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Header from './Header'
import * as Entries from './Entries'

import * as Layout from './Layout'
import * as styles from './styles.module.scss'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Entries: entriesComponents,
      Layout: {
        Container = Layout.Container,
        Header = Layout.Header,
        Body = Layout.Body,
      } = {},
    } = {},
  }) => {
    return (
      <Initializer>
        <Container>
          <Header>
            <Header.Default />
          </Header>
          <Body>
            <Entries.Default components={entriesComponents} />
          </Body>
        </Container>
      </Initializer>
    )
  },
)
