import React from 'react'

import { Context } from './Context'

/**
 */
export const useActions = () => {
  const { setPattern, resetPattern } = React.useContext(Context)

  return {
    setPattern,
    resetPattern,
  }
}
