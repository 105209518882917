import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'

import * as styles from './styles.module.scss'

/**
 */
export const FilterIcon = () => <Icons.Filter className={styles.Icon} />

/**
 */
export const SortIcon = () => <Icons.Sort className={styles.Icon} />
