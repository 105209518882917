import { isUsageServiceTagMeta } from '@arch-log/webapp.modules/project.product.v2/helper'

/**
 */
export const createTags = (validateEntries) => {
  return [
    ...new Set(
      validateEntries
        .filter((e) => !e.isDeletable)
        .map((e) => e.caused.filter(isUsageServiceTagMeta))
        .flat(),
    ),
  ]
}

/**
 */
export const createServiceProducts = (
  tags,
  validateEntries,
  deprecatedEntries,
) => {
  return tags.map((tag) => ({
    tag,
    products: validateEntries
      .filter((e) => e.caused.some((caused) => caused === tag))
      .map((validateEntry) =>
        deprecatedEntries.find(
          (deprecatedEntry) => deprecatedEntry.id === validateEntry.productId,
        ),
      )
      .filter((v) => v),
  }))
}
