import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import * as Renderers from '@arch-log/webapp.modules/product.v2/components/PropertyRenderers/Manufacturer'

// EntryContext
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntry/Context'

/**
 */
export const DisplayName = withContextValue(
  Context,
  (data) => data.entry.manufacturer.displayNames,
)(Renderers.DisplayName)

/**
 */
export const DisplayShortName = withContextValue(
  Context,
  (data) => data.entry.manufacturer.displayShortNames,
)(Renderers.DisplayName)
