import React from 'react'

import * as Base from '@arch-log/webapp.shared/AddedProduct.v2/Sorter/Selected'

export const Initializer = Base.Initializer

///**
// *
// */
//export const Initializer = ({ children }) => {
//  const [ordinal, setOrdinal] = React.useState({
//    key: OrderBy.RegisteredAt,
//    direction: Direction.Desc,
//  })
//
//  const orderBy = (key, direction) => {
//    setOrdinal({ key, direction })
//  }
//
//  return (
//    <Context.Provider
//      value={{ key: ordinal.key, direction: ordinal.direction, orderBy }}
//    >
//      <Applier>{children}</Applier>
//    </Context.Provider>
//  )
//}
//
//const Applier = ({ children }) => {
//  const { key: sortKey, direction } = React.useContext(Context)
//
//  return (
//    <AddedProducts.Sort orderBy={sortKey} direction={direction}>
//      {children}
//    </AddedProducts.Sort>
//  )
//}
