import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as config from '@arch-log/webapp.modules/config/config'
import * as Styled from './Styled'

export const Default = ({
  components: {
    Icon = Styled.Icon,
  } = {}
}) => {

  const { product: {
    isPrivate = false,
  }} = React.useContext(Product.Context)

  if(config.get('product.debug_icon_enabled')) {
    if(isPrivate) {
      return <Icon/>
    }
  }

  return null
}
