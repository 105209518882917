import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import * as DefaultComponents from './DefaultComponents'
import * as AddedProduct from '@arch-log/webapp.modules/project.product/components/Reference'

import * as List from './List'
import * as SideInfo from './SideInfo'
import * as Layout from './Layout'

/**
 */
export const Initializer = ({ children }) => (
  <SideInfo.Selected.Initializer>{children}</SideInfo.Selected.Initializer>
)

/**
 */
const SideInfoContainer = ({ children }) => {
  const { selected, setSelected } = React.useContext(SideInfo.Selected.Context)

  if (!selected) {
    return false
  }

  return (
    <AddedProduct.Provider addedProduct={selected}>
      {children}
    </AddedProduct.Provider>
  )
}

/**
 * ProductList
 */
export const Default = withDefaultRenderComponent(
  ({
    canEditTags = false,
    components: {
      Initializer: MyInitializer = Initializer,
      List: listComponents,
      SideInfo: { Content: sideInfoContentComponents } = {},
      Layout: {
        Container = Layout.Container,
        ListSection = Layout.ListSection,
        ExpandSection = Layout.ExpandSection,
      } = {},
    } = {},
  }) => {
    return (
      <MyInitializer>
        <Container>
          <ListSection>
            <List.Default components={listComponents} />
          </ListSection>
          <SideInfoContainer>
            <ExpandSection>
              <SideInfo.Default
                components={sideInfoContentComponents}
                canEditTags={canEditTags}
              />
            </ExpandSection>
          </SideInfoContainer>
        </Container>
      </MyInitializer>
    )
  },
)
