import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

import { useProperty } from '../useProperty'

/**
 */
export const CountProducts = ({
  render: Renderer = ValueRenderers.NumberRenderer,
  format = '0,0',
  ...props
}) => {
  const value = useProperty((data) => data.countProducts)

  return <Renderer value={value} {...props} defaultRenderer={ValueRenderers.NumberRenderer} format={format} />
}
