import React from 'react'

import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

import * as ScopeCategory from '@arch-log/webapp.modules/product-category.v2/components/Scope'
import * as Filter from './Filter'
import * as Mode from './Mode'
import * as Selected from './Selected'
import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const FilterInput = ({
  components: { Input = Styled.FilterInput } = {},
}) => {
  const { pattern, setPattern } = React.useContext(Filter.Pattern.Context)
  const { formatPlaceholder } = Literals.useLiterals()

  return (
    <Input
      value={pattern}
      onChangeValue={(v) => {
        setPattern(v)
      }}
      placeholder={formatPlaceholder('Search.EntryCategory')}
    />
  )
}

export const SelectedHierarchy = ({
  components: {
    SelectedList = Styled.SelectedList,
    SelectedListItem = Styled.SelectedListItem,
  } = {},
}) => {
  const { hierarchy, popUntil } = React.useContext(Selected.Context)

  const items = hierarchy.map((category) => (
    <SelectedListItem
      key={category.id}
      onClick={() => {
        popUntil(category.id)
      }}
    >
      <ScopeCategory.Category.Provider {...category}>
        <CategoryLabel />
      </ScopeCategory.Category.Provider>
    </SelectedListItem>
  ))

  return <SelectedList>{items}</SelectedList>
}

export const Selections = ({}) => {
  const { pattern } = React.useContext(Filter.Pattern.Context)
  const { hasEnables } = React.useContext(Filter.Enabled.Context)

  if (hasEnables || (pattern && pattern.length > 0)) {
    return <FlatSelections />
  }

  return <HierarchySelections />
}

export const FlatSelections = ({}) => {
  const { enables } = React.useContext(Filter.Enabled.Context)

  return (
    <ScopeCategory.Categories.Filter
      filterBy={(cat) => {
        if (enables && enables.length > 0) {
          return enables.includes(cat.id)
        }
        return true
      }}
    >
      <SelectionList />
    </ScopeCategory.Categories.Filter>
  )
}

export const HierarchySelections = ({}) => {
  const { selected } = React.useContext(Selected.Context)

  return (
    <ScopeCategory.Categories.InitializeChildEntriesWithRemote
      parent={selected}
    >
      <SelectionList />
    </ScopeCategory.Categories.InitializeChildEntriesWithRemote>
  )
}

export const SelectionList = ({
  components: {
    Items = Styled.SelectorItems,
    Item = Styled.SelectorItem,
    Empty = Styled.Empty,
  } = {},
}) => {
  const { pattern } = React.useContext(Filter.Pattern.Context)

  return (
    <Items>
      <ScopeCategory.Categories.Filter
        filterBy={(entry) =>
          pattern
            ? Object.values(entry.labels).find((v) => v.includes(pattern))
            : true
        }
      >
        <SelectionListInner />
      </ScopeCategory.Categories.Filter>
    </Items>
  )
}

const SelectionListEmpty = ({
  isFiltered,
  components: { Wrapper = Styled.Empty } = {},
}) => {
  const { pattern } = React.useContext(Filter.Pattern.Context)

  if (!pattern) {
    return null
  }

  return (
    <Wrapper>
      <Literals.Empty />
    </Wrapper>
  )
}

const SelectionListInner = ({}) => {
  const { entries } = React.useContext(ScopeCategory.Categories.Context)

  if (entries.length === 0) {
    return <SelectionListEmpty />
  }
  return (
    <ScopeCategory.Categories.Each>
      <SelectionListItem />
    </ScopeCategory.Categories.Each>
  )
}

/**
 */
export const SelectionListItem = ({
  components: { Items = Styled.SelectorItems, Item = Styled.SelectorItem } = {},
}) => {
  const { push, replace } = React.useContext(Selected.Context)
  const { id } = React.useContext(ScopeCategory.Category.Context)
  const { pattern } = React.useContext(Filter.Pattern.Context)
  const { hasEnables } = React.useContext(Filter.Enabled.Context)

  return (
    <Item
      onSelect={() => {
        if (hasEnables || pattern) {
          replace(id)
        } else {
          push(id)
        }
      }}
    >
      <ScopeCategory.Category.Properties.CountProducts
        render={({ value }) => (
          <span
            style={{
              color: (value ?? null) !== null && value < 1 ? '#cccccc' : null,
            }}
          >
            <CategoryLabel />
            <RelatedProductCount />
          </span>
        )}
      />
    </Item>
  )
}

/**
 */
export const CategoryLabel = withLanguage(({ language, ...props }) => {
  return (
    <>
      <ScopeCategory.Category.Properties.Label language={language} {...props} />
    </>
  )
})

/**
 */
export const RelatedProductCount = ({}) => {
  const { isRelatedProductCountEnabled } = React.useContext(Mode.Context)

  if (!isRelatedProductCountEnabled) {
    return null
  }

  return (
    <ScopeCategory.Category.Properties.CountProducts
      render={({ value, defaultRenderer: Renderer, ...props }) => {
        if ((value ?? null) !== null) {
          return <Renderer prefix="(" suffix=")" value={value} {...props} />
        }
        return null
      }}
    />
  )
}
