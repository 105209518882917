import React from 'react'

import * as defaultStyles from './styles.module.scss'

/**
 */
export const FilterInput = (props) => (
  <input {...props} className={defaultStyles.FilterInput} />
)

/**
 */
export const SelectionsWrapper = ({
  children,
  className,
  styles: overrideStyles,
}) => {
  const styles = { ...defaultStyles, ...overrideStyles }

  return <div className={className ?? styles.Selections}>{children}</div>
}

/**
 */
export const SelectionWrapper = ({
  children,
  styles: overrideStyles,
  isSelected,
  isPrivate,
  onSelect,
  ...props
}) => {
  const styles = { ...defaultStyles, ...overrideStyles }

  const {
    className = styles.Selection,
    selectedClassName = styles.Selection_Selected,
    privateClassName = styles.Selection_Private,
  } = props

  return (
    <span
      className={
        isSelected
          ? selectedClassName
          : isPrivate
          ? privateClassName
          : className
      }
      onClick={() => onSelect()}
    >
      {children}
    </span>
  )
}

/**
 */
export const Empty = ({ children }) => (
  <li className={defaultStyles.Empty}>{children}</li>
)
