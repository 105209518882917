import React from 'react'

import { Context } from './Context'
import * as Project from '@arch-log/webapp.modules/project.v2/components'

/**
 */
export const Initializer = ({
  components: { NotTargetProject } = {},
  fromProject,
  children,
}) => {
  const [entries] = Project.Scope.Entries.useEntries()

  const defaultTargetProjects = entries.filter((entry) => {
    return entry.id !== fromProject
  })

  const [targetProjects, setTargetProjects] = React.useState(
    defaultTargetProjects,
  )

  if (targetProjects.length === 0) {
    return <NotTargetProject />
  }

  return (
    <Context.Provider value={{ targetProjects, setTargetProjects }}>
      {children}
    </Context.Provider>
  )
}
