import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import { navigate } from 'gatsby'
import { useProjectRoutes } from 'components/ProjectRoutes'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Links from '@arch-log/webapp.shared/Links'
import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as BulkActions from './BulkActions'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

export const SelectAll = withDefaultRenderComponent(
  ({
    components: {
      Label = Styled.SelectAllLabel,
      CheckBox = Styled.SelectAllCheckBox,
    } = {},
  }) => (
    <BulkActions.SelectAll
      components={{
        Label,
        CheckBox,
      }}
    ></BulkActions.SelectAll>
  ),
)

export const SearchProducts = () => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <Links.ProductSearch.ToTop
      project={projectEntry.id}
      className={styles.SearchProducts}
    >
      <Literals.SearchProducts />
    </Links.ProductSearch.ToTop>
  )
}

export const BulkActionDispatcher = ({
  components: {
    Wrapper = Styled.BulkActionDispatcherWrapper,
    Toggle = Styled.BulkActionDispatcherToggle,
    Items = Styled.BulkActionDispatcherItems,
    Item = Styled.BulkActionDispatcherItem,
  } = {},
}) => {
  const [isShown, setShown] = React.useState(false)
  const { selectedIds } = React.useContext(BulkActions.Context)

  return (
    <Wrapper onMouseLeave={() => setShown(false)}>
      <Toggle
        disabled={selectedIds.length === 0}
        toggle={() => {
          if (selectedIds.length > 0) {
            setShown(!isShown)
          }
        }}
      >
        <Literals.DispatchBulkAction />
      </Toggle>
      <Items isVisible={isShown}>
        <Item>
          <BulkRequestBims />
        </Item>

        <Item>
          <BulkAddToSampleRequests />
        </Item>

        <ProjectAuthorization.ContentForPermissions
          permission={Permissions.TagsWrite}
        >
          <Item>
            <BulkTagEdit />
          </Item>
        </ProjectAuthorization.ContentForPermissions>

        <Item>
          <BulkRemoveEntries />
        </Item>

        <ProjectAuthorization.ContentForPermissions
          permission={Permissions.ProductsAdd}
        >
          <Item>
            <BulkCopyProjectProducts />
          </Item>
        </ProjectAuthorization.ContentForPermissions>
      </Items>
    </Wrapper>
  )
}

export const BulkRemoveEntries = withDefaultRenderComponent(
  ({ components: { Button = Styled.RemoveEntriesButton } = {} }) => {
    const { removeEntries } = React.useContext(BulkActions.Context)
    return (
      <Button
        onClick={() => {
          removeEntries()
        }}
      />
    )
  },
)

export const BulkAddToSampleRequests = withDefaultRenderComponent(
  ({ components: { Button = Styled.AddToSampleRequestButton } = {} }) => {
    const { addToSampleRequests } = React.useContext(BulkActions.Context)
    const routes = useProjectRoutes()
    return (
      <Button
        onClick={() => {
          addToSampleRequests({
            onComplete: () => {
              navigate(routes.toSampleRequests())
            },
          })
        }}
      />
    )
  },
)

export const BulkRequestBims = ({
  components: { Button = Styled.BulkRequestBimsButton } = {},
}) => {
  const { requestBims } = React.useContext(BulkActions.Context)
  return (
    <Button
      onClick={() => {
        requestBims()
      }}
    >
      <Literals.BulkRequestBims />
    </Button>
  )
}

export const BulkCopyProjectProducts = ({
  components: { Button = Styled.BulkCopyProjectProductsButton } = {},
}) => {
  const { copyProducts } = React.useContext(BulkActions.Context)

  return (
    <Button
      onClick={() => {
        copyProducts()
      }}
    >
      <Literals.BulkCopyProjectProducts />
    </Button>
  )
}

export const BulkTagEdit = ({
  components: { Button = Styled.BulkTagEditButton } = {},
}) => {
  const { editTags } = React.useContext(BulkActions.Context)

  return (
    <Button
      onClick={() => {
        editTags()
      }}
    >
      <Literals.BulkTagEdit />
    </Button>
  )
}
