import React from 'react'

import * as Base from '@arch-log/webapp.shared/Product.v2/Sorter/Styled'

import * as styles from './styles.module.scss'

/**
 * Title Decorator
 */
export const Title = Base.Title

/**
 * Sort Decorator
 */
export const SortButton = Base.SortButton
