import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Selected from './Selected'
import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  product,
  selected,
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Left = Layout.Left,
      Right = Layout.Right,
      InfoSection = Layout.InfoSection,
      ThumbnailsSection = Layout.ThumbnailsSection,
    } = {},
  } = {},
}) => {
  
  return (
  <Product.Provider product={product}>
    <Wrapper>
      <Product.Properties.Images.First
        render={({ image }) => (
          <Selected.Initializer selected={selected ?? image}>
            <Left>
              <Containers.SelectedImage />
            </Left>
            <Right>
              <InfoSection>
                <Containers.Info />
              </InfoSection>
              <ThumbnailsSection>
                <Containers.Thumbnails />
              </ThumbnailsSection>
            </Right>
          </Selected.Initializer>
        )}
      />
    </Wrapper>
  </Product.Provider>
)
}
