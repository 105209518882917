import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

export const SearchProducts = () => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <Links.ProductSearch.ToTop
      project={projectEntry.id}
      className={styles.SearchProducts}
    >
      <Literals.SearchProducts />
    </Links.ProductSearch.ToTop>
  )
}
