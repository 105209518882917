import React from 'react'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Title = ({ components: { Decorator = Styled.Title } = {} }) => (
  <>
    <Styled.TitleIcon />
    <Decorator>
      <Literals.Title />
    </Decorator>
  </>
)
