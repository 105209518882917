import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useEntry = () => {
  const { entry } = useContext()

  const getProperty = (key) => {
    const keys = key.split('.')

    return keys.reduce((prev, cur) => {
      return prev?.[cur]
    }, entry)
  }

  return [entry, { getProperty }]
}

