import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as Products from '@arch-log/webapp.modules/product/components'
import * as Buttons from '@arch-log/webapp.shared/Buttons'
import * as Literals from './Literals'
import * as Styled from './Styled'
import {
  OrderBy,
  Direction,
} from '@arch-log/webapp.modules/project/order'
import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ components: { Decorator = Styled.Title } = {} }) => (
  <>
    <Icons.Sort className={styles.Title_Icon} />
    <Decorator>
      <Literals.Title />
    </Decorator>
  </>
)

export const SortBy = ({
  children,
  sortKey = OrderBy.AddedAt,
  defaultDirection,
  components: { Decorator = Buttons.SortButton } = {},
}) => {
  const {
    key: curSortKey,
    direction: curSortDirection,
    setOrderBy,
  } = React.useContext(Products.Sort.Context)

  const sortBy = (sortKey, defaultDirection) => {
    if (curSortKey === sortKey) {
      setOrderBy(sortKey, Direction.oposite(curSortDirection))
    } else {
      setOrderBy(sortKey, defaultDirection)
    }
  }

  const isActive = (name) => name === curSortKey

  return (
    <Decorator
      onClick={() => sortBy(sortKey, defaultDirection)}
      isActive={isActive(sortKey)}
      direction={curSortDirection}
    >
      {children}
    </Decorator>
  )
}

export const SortByProductName = () => (
  <SortBy sortKey={OrderBy.ProductName} defaultDirection={Direction.Desc}>
    <Literals.ProductName />
  </SortBy>
)

export const SortByRegisteredAt = () => (
  <SortBy sortKey={OrderBy.RegisteredAt} defaultDirection={Direction.Desc}>
    <Literals.RegisteredAt />
  </SortBy>
)

export const SortByAddedAt = () => (
  <SortBy sortKey={OrderBy.AddedAt} defaultDirection={Direction.Desc}>
    <Literals.AddedAt />
  </SortBy>
)

export const SortByProductCode = () => (
  <SortBy sortKey={OrderBy.ProductCode} defaultDirection={Direction.Desc}>
    <Literals.ProductCode />
  </SortBy>
)

export const SortByManufacturer = () => (
  <SortBy sortKey={OrderBy.Manufacturer} defaultDirection={Direction.Desc}>
    <Literals.Manufacturer />
  </SortBy>
)

export const SortByCategory = () => (
  <SortBy sortKey={OrderBy.Category} defaultDirection={Direction.Desc}>
    <Literals.Category />
  </SortBy>
)
