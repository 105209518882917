import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('module.product')(
  'Search.Filter.Manufacturer.Title',
)

/**
 */
export const Reset = translated('module.product')('Search.Filter.Reset')
