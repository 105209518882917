import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { Context } from './Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const Label = withContextValue(
  Context,
  (data) => data.name,
)(Renderers.Classification.Label)

/**
 */
export const Value = withContextValue(Context, (data) => ({
  value: data.value,
  valueDirty: data.valueDirty,
}))(Renderers.Classification.Value)
