import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  copyProducts,
  fromProject,
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
    Title: titleComponents,
    CopyFailure: CopyFailureComponents,
    CopyProductListTitle: copyProductListTitleComponents,
    CopyProductListDescription: copyProductListDescriptionComponents,
    IncludePrivateProductError: includePrivateProductErrorComponents,
    CopyProductList: copyProductListComponents,
    Copy: copyComponents,
  } = {},
}) => {
  return (
    <Containers.Initializer
      copyProducts={copyProducts}
      fromProject={fromProject}
    >
      <Wrapper>
        <Header>
          <Containers.Title components={titleComponents} />
          <Containers.CopyFailure components={CopyFailureComponents} />
          <Containers.CopyProjectSelector fromProject={fromProject} />
        </Header>
        <Body>
          <Containers.CopyProductListTitle
            components={copyProductListTitleComponents}
          />
          <Containers.CopyProductListDescription
            components={copyProductListDescriptionComponents}
          />
          <Containers.IncludePrivateProductError
            components={includePrivateProductErrorComponents}
            copyProducts={copyProducts}
          />
          <Containers.CopyProductList components={copyProductListComponents} />
        </Body>
        <Footer>
          <Containers.Copy
            components={copyComponents}
            fromProject={fromProject}
          />
        </Footer>
      </Wrapper>
    </Containers.Initializer>
  )
}
