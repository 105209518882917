import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Components from '../Components'
import { createGroup } from '../utils'

import * as EcoLabelFields from './EcoLabel'

/**
 */
const DefaultValueFormatter = (v, { t }) => {
  if (typeof v === 'boolean') {
    return t(v ? 'Values.Boolean.Yes' : 'Values.Boolean.No')
  }
  return v
  //return t(v ?? '')
}

/**
 */
export const EcoLabel = createGroup(
  'Ecos_EcoLabel',
  ({ components = {}, t, language = null, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components
    return (
      <Product.Properties.Ecos.Each>
        <Container>
          <Label>
            <Product.Properties.Eco.Label formatter={(v) => t(`Ecos.${v}`)} />
          </Label>
          <Value>
            <Product.Properties.Eco.Value
              formatter={DefaultValueFormatter}
              t={t}
            />
            <Product.Properties.Eco.Note prefix="(" suffix=")" />
          </Value>
        </Container>
      </Product.Properties.Ecos.Each>
    )
  },
  //Product.Properties.Has,
)
