import React from 'react'

import {
  withDefaultRenderComponent,
  Fragment,
} from '@arch-log/webapp.atomics/components/Rules'
import * as SwitchableContent from '@arch-log/webapp.atomics/components/SwitchableContent'
import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Log from '@arch-log/webapp.modules/log/components'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    canEditTags = false,
    components: {
      Title: titleComponents,
      ProductCode: productCodeComponents,
      Image: imageComponents,
      Buttons: ButtonsContent = Fragment,
      Layout: {
        Wrapper = Layout.Wrapper,
        Image = Layout.Image,
        Title = Layout.Title,
        Info = Layout.Info,
        ButtonsAndIcons = Layout.ButtonsAndIcons,
        Buttons = Layout.Buttons,
        Icons = Layout.Icons,
      } = {},
    } = {},
  }) => {
    const { product } = React.useContext(Product.Context)

    const { dispatchExpand } = Log.useDispatcher()

    // dispatch log when sideinfo is open
    React.useEffect(() => {
      if (product) {
        dispatchExpand(product)
      }
    }, [product])

    return (
      <Wrapper>
        <Image>
          <Containers.Image components={imageComponents} />
        </Image>
        <Title>
          <Containers.Title components={titleComponents} />
          <Containers.ProductCode components={productCodeComponents} />
        </Title>
        <ButtonsAndIcons>
          <Buttons>
            <ButtonsContent>
              <Containers.CompareButton />
            </ButtonsContent>
          </Buttons>
          <Icons>
            <Containers.SupportedBIMs />
          </Icons>
        </ButtonsAndIcons>
        <Info>
          <Containers.Tabs canEditTags={canEditTags} />
        </Info>
      </Wrapper>
    )
  },
)
