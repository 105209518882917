import React from 'react'

import * as Manufacturer from '@arch-log/webapp.modules/manufacturer.v2/components'
import * as Filter from './Filter'
import * as Selected from './Selected'
import * as Setting from './Setting'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

/**
 */
export const Initializer = ({
  children,
  defaultValue,
  value,
  onSelected,
  enables,
  showProductCount = false,
}) => (
  <Setting.Initializer showProductCount={showProductCount}>
    <Filter.Enabled.Initializer enables={enables}>
      <Manufacturer.Remote.Queries.Entries.LazyLoader>
        <Selected.Initializer
          value={value}
          onSelected={onSelected}
          defaultValue={defaultValue}
        >
          <Filter.Pattern.Initializer>{children}</Filter.Pattern.Initializer>
        </Selected.Initializer>
      </Manufacturer.Remote.Queries.Entries.LazyLoader>
    </Filter.Enabled.Initializer>
  </Setting.Initializer>
)
