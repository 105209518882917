import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Footer = ({ children }) => (
  <div className={styles.Footer}>{children}</div>
)

/**
 */
export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)

/**
 */
export const BodySection = ({ children }) => (
  <div className={styles.BodySection}>{children}</div>
)

/**
 */
export const ConfirmLayoutWrapper = ({ children }) => (
  <div className={styles.Confirm_Layout_Wrapper}>{children}</div>
)

/**
 */
export const ConfirmMessageLayoutWrapper = ({ children }) => {
  return <div className={styles.ConfirmMessageLayoutWrapper}>{children}</div>
}
