import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Label = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  language = 'ja',
  ...props
}) => {
  return <Renderer value={value?.[language] ?? defaultValue} {...props} />
}

/**
 */
export const ShortLabel = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  defaultValue = null,
  language = 'ja',
  ...props
}) => {
  return <Renderer value={value?.[language] ?? defaultValue} {...props} />
}
