import React from 'react'

import * as Tag from '@arch-log/webapp.modules/project.product.tag/components'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as Styled from './Styled'
import * as Layout from './Layout'
import * as Literals from './Literals'
import { useActions } from '../useActions'
import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ components: { Title = Styled.Title } = {} }) => (
  <Title>
    <Literals.Title />
  </Title>
)

/**
 */
const InputLabel = ({ components: { Label = Styled.InputLabel } = {} }) => (
  <Label>
    <Literals.InputLabel />
  </Label>
)

/**
 */
const TagGroupName = ({ components: { Label = Styled.SelectLabel } = {} }) => (
  <Label>
    <Literals.TagGroupName />
  </Label>
)

/**
 */
export const Description = ({
  components: {
    Container = Styled.DescriptionContainer,
    Section = Styled.DescriptionSection,
  } = {},
}) => (
  <Container>
    <Section>
      <Literals.DescriptionAdd />
    </Section>
    <Section>
      <Literals.DescriptionRemove />
    </Section>
  </Container>
)

/**
 */
export const Form = ({
  products,
  components: {
    Input = Styled.TagNameInput,
    ButtonContainer = Styled.ButtonContainer,
  } = {},
}) => {
  const { namespaces } = React.useContext(Tag.Namespaces.Context)

  const [data, setData] = React.useState({
    targetTagGroup: namespaces[0],
    tagname: '',
  })

  const { batchAddTag, batchRemoveTag } = Tag.useActions()

  return (
    <>
      <TagGroupName />
      <TargetTagGroup
        onChangeValue={(value, e) => {
          setData({
            ...data,
            targetTagGroup: value,
          })
        }}
        value={data.targetTagGroup}
      />

      <InputLabel />
      <Input
        onChangeValue={(value, e) => {
          setData({
            ...data,
            tagname: value,
          })
        }}
        value={data.tagname}
      />
      <ButtonContainer>
        <Append
          onAppend={() => {
            batchAddTag(data.tagname, products, data.targetTagGroup)
            //
            setData({
              ...data,
              tagname: '',
            })
          }}
        />
        <Remove
          onRemove={() => {
            batchRemoveTag(data.tagname, products, data.targetTagGroup)
            setData({
              ...data,
              tagname: '',
            })
          }}
        />
      </ButtonContainer>
    </>
  )
}

export const Append = ({
  onAppend,
  components: { Button = Styled.AppendButton } = {},
}) => {
  return (
    <Button onClick={() => onAppend()}>
      <Literals.Append />
    </Button>
  )
}

export const Remove = ({
  onRemove,
  components: { Button = Styled.RemoveButton } = {},
}) => {
  return (
    <Button onClick={() => onRemove()}>
      <Literals.Remove />
    </Button>
  )
}

/**
 */
export const Close = ({
  components: { CloseButton = Styled.CloseButton } = {},
}) => {
  const { hide } = useActions()

  return (
    <CloseButton
      onClick={() => {
        hide()
      }}
    >
      <Literals.Close />
    </CloseButton>
  )
}

export const TargetTagGroup = ({ onChangeValue, value }) => {
  return (
    <select
      onChange={(e) => onChangeValue(e.target.value, e)}
      value={value}
      className={styles.SelectTagGroup}
    >
      <Tag.Namespaces.Each>
        <Tag.Namespaces.Namespace.Properties.Name
          render={({ value }) => <option value={value}>{value}</option>}
        />
      </Tag.Namespaces.Each>
    </select>
  )
}

export const NamespaceNotDefined = ({
  project_id,
  components: {
    Container = Layout.Wrapper,
    Discription = Styled.NamespaceNotDefinedDiscription,
  } = {},
}) => {
  return (
    <Container>
      <Discription>
        <Literals.NamespaceNotDefined />
      </Discription>
      <LinkToTags project_id={project_id} />
    </Container>
  )
}

export const LinkToTags = ({ project_id }) => {
  const { hide } = useActions()
  return (
    <ProjectLinks.ToTagManage
      className={styles.LinkToTagsButton}
      pid={project_id}
      onClick={() => {
        hide()
      }}
    >
      <Literals.LinkToTags />
    </ProjectLinks.ToTagManage>
  )
}
