import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Layout_Wrapper}>{children}</div>
)

/**
 */
export const Image = ({ children }) => (
  <div className={styles.Layout_ImageWrapper}>
    <div className={styles.Layout_Image}>{children}</div>
  </div>
)

/**
 */
export const Title = ({ children }) => (
  <div className={styles.Layout_Title}>{children}</div>
)

/**
 */
export const Info = ({ children }) => (
  <div className={styles.Layout_Info}>{children}</div>
)

/**
 */
export const ButtonsAndIcons = ({ children }) => (
  <div className={styles.Layout_ButtonsAndIcons}>{children}</div>
)

/**
 */
export const Buttons = ({ children }) => (
  <div className={styles.Layout_Buttons}>{children}</div>
)

/**
 */
export const Icons = ({ children }) => (
  <div className={styles.Layout_Icons}>{children}</div>
)
