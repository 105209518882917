import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const MenuSection = ({ children }) => (
  <div className={styles.Menu}>{children}</div>
)

/**
 */
//export const MenuItem = ({ children }) => (<div className={styles.Menu_Item }>{ children }</div>)
export const MenuItem = ({ children }) => <>{children}</>

/**
 */
export const ContentSection = ({ children }) => <>{children}</>
