import React from 'react'

import { Context } from './Context'

const DefaultLoading = () => <span>Loading Manufacturer Ref</span>

/**
 *
 */
export const withManufacturer = (Component) =>
  //
  function WithManufacturer({
    //
    loading: Loading = DefaultLoading,
    ...props
  }) {
    const { manufacturer, isLoading } = React.useContext(Context)

    if (isLoading) return <Loading />

    return <Component manufacturer={manufacturer} {...props} />
  }
