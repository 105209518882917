import React from 'react'

import { Context } from './Context'
import { Context as TargetProjectsContext } from '../Context'

/**
 */
export const Initializer = ({ children }) => {
  const { targetProjects } = React.useContext(TargetProjectsContext)

  const [targetProject, setTargetProject] = React.useState(
    targetProjects.length > 0 ? targetProjects[0].id : null,
  )

  return (
    <Context.Provider value={{ targetProject, setTargetProject }}>
      {children}
    </Context.Provider>
  )
}
