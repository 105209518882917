// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--+Cv8k";
export var Info = "styles-module--Info--uqpjQ";
export var Label = "styles-module--Label--AMnOs";
export var Left = "styles-module--Left--V6vRB";
export var ManufacturerName = "styles-module--ManufacturerName--K8Wy+";
export var ProductCode = "styles-module--ProductCode--XgbyY";
export var ProductName = "styles-module--ProductName--Fsg98";
export var Right = "styles-module--Right--l+VjM";
export var SelectedImage = "styles-module--SelectedImage--5nutm";
export var ThumbnailImage = "styles-module--ThumbnailImage--HXBBZ";
export var ThumbnailImage_Selected = "styles-module--ThumbnailImage_Selected--gGc6Y";
export var Thumbnails = "styles-module--Thumbnails--DdGye";