import React from 'react'

import {
  withCustomComponents,
  withDefaultRenderComponent,
} from '@arch-log/webapp.atomics/components/Rules'
import * as Base from '@arch-log/webapp.shared/AddedProduct.v2/List'

//import * as Actions from '../Actions'
import * as CustomEntry from './Entries/Entry'
import * as BulkActions from './BulkActions'

import * as Layout from './Layout'
import * as Containers from './Containers'
import * as styles from './styles.module.scss'

/**
 */
export const Default = withCustomComponents(
  withDefaultRenderComponent(
    ({
      components: {
        SelectAll: selectAllComponents,
        Entries: entriesComponents,
        Layout: {
          Wrapper = Layout.Wrapper,
          Header = Layout.Header,
          HeaderConditionSection = Layout.HeaderCondition,
          HeaderControlSection = Layout.HeaderControl,
          Body = Layout.Body,
          Footer = Layout.Footer,
        } = {},
      } = {},
    }) => (
      <Base.Initializer>
        <BulkActions.Initializer>
          <Wrapper>
            <Header>
              <HeaderConditionSection>
                <Base.Header.Default />
              </HeaderConditionSection>
              <HeaderControlSection>
                <Containers.SelectAll components={selectAllComponents} />
                <Containers.BulkActionDispatcher />
              </HeaderControlSection>
            </Header>
            <Body>
              <Base.Entries.Default components={entriesComponents} />
            </Body>
            <Footer>
              <Containers.SearchProducts />
            </Footer>
          </Wrapper>
        </BulkActions.Initializer>
      </Base.Initializer>
    ),
  ),
  {
    Entries: {
      Entry: {
        Content: {
          render: CustomEntry.Default,
        },
      },
    },
  },
)
