// extracted by mini-css-extract-plugin
export var Catalog = "default-module--Catalog--HL3cX";
export var CatalogPageList = "default-module--CatalogPageList--aWmvz";
export var FieldContainer = "default-module--FieldContainer--2q8Sv";
export var FieldGroupContainer = "default-module--FieldGroupContainer--39S9c";
export var FieldGroupLabel = "default-module--FieldGroupLabel--qSGyZ";
export var FieldGroupValue = "default-module--FieldGroupValue--DLO3D";
export var FieldLabel = "default-module--FieldLabel--AZBYz";
export var FieldValue = "default-module--FieldValue--EHBaj";
export var FieldValue_Rows = "default-module--FieldValue_Rows--kqmIC";
export var List = "default-module--List--8uVGC";