import React from 'react'

import * as BIMIcons from '@arch-log/webapp.shared/BIMIcons'
import * as Product from '@arch-log/webapp.modules/product/components/Reference'

import * as Styled from './Styled'

/**
 */
export const ArchiCAD = ({ components: { Wrapper = Styled.IconWrapper } = {}}) => (
  <Product.Properties.Bim.HasArchiCAD
    render={({ has }) => {
      if (has) {
        return (
          <Wrapper>
            <BIMIcons.ArchiCAD />
          </Wrapper>
        )
      } else {
        //return <BIMIcons.ArchiCAD grayscale={true} />
        return null
      }
    }}
  />
)

/**
 */
export const Revit = ({ components: { Wrapper = Styled.IconWrapper } = {}}) => (
  <Product.Properties.Bim.HasRevit
    render={({ has }) => {
      if (has) {
        return (
          <Wrapper>
            <BIMIcons.Revit />
          </Wrapper>
        )
      } else {
        //return <BIMIcons.Revit grayscale={true} />
        return null
      }
    }}
  />
)

/**
 */
export const SketchUp = ({ components: { Wrapper = Styled.IconWrapper } = {}}) => (
  <Product.Properties.Bim.HasSketchUp
    render={({ has }) => {
      if (has) {
        return (
          <Wrapper>
            <BIMIcons.SketchUp />
          </Wrapper>
        )
      } else {
        //return <BIMIcons.SketchUp grayscale={true} />
        return null
      }
    }}
  />
)
