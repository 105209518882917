import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'
import { Context as AddedProductContext } from '@arch-log/webapp.modules/project.product/components/Reference'

import * as SideInfo from '../../SideInfo'
import * as EntryContent from './Entry'

import * as DefaultComponents from './DefaultComponents'
import * as Layout from './Layout'

const Entries = ({ children }) => (
  <AddedProducts.UIScrollLoader>
    <AddedProducts.Each>{children}</AddedProducts.Each>
  </AddedProducts.UIScrollLoader>
)

/**
 *
 */
export const Entry = withDefaultRenderComponent(
  ({
    components: {
      Content: contentComponents,
      Selector = DefaultComponents.EntrySelector,
    } = {},
  }) => {
    // FIXME should be remove by onEntryClicked
    const { selected: expanded, setSelected: setExpanded } = React.useContext(
      SideInfo.Selected.Context,
    )

    const { addedProduct } = React.useContext(AddedProductContext)

    return (
      <Selector
        isSelected={expanded && expanded.product_id === addedProduct.product_id}
        onSelect={() => {
          if (expanded && expanded.product_id === addedProduct.product_id) {
            setExpanded(null)
          } else {
            setExpanded(addedProduct)
          }
        }}
      >
        <EntryContent.Default components={contentComponents} />
      </Selector>
    )
  },
)

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Entry: entryComponents,
      Entries: entriesComponents,
      Layout: { Container = Layout.Entries, EntryWrapper = Layout.Entry } = {},
    } = {},
  }) => {
    return (
      <Container>
        <Entries components={entriesComponents}>
          <EntryWrapper>
            <Entry components={entryComponents} />
          </EntryWrapper>
        </Entries>
      </Container>
    )
  },
)
