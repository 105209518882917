import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const FieldsWrapper = ({ children }) => (
  <div className={styles.FieldsWrapper}>{children}</div>
)

/**
 */
export const Title = ({ children }) => (
  <div className={styles.TitleWrapper}>{children}</div>
)

/**
 */
export const ProductCode = ({ children }) => (
  <div className={styles.ProductCode}>{children}</div>
)

/**
 */
export const CompareButton = ({ children }) => (
  <div className={styles.CompareButton}>{children}</div>
)
