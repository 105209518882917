import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'

import * as styles from './styles.module.scss'

/**
 */
export const CloseButton = ({ onClose }) => (
  <div className={styles.CloseButton} onClick={() => onClose()}>
    <div className={styles.CloseButton_Icon}>
      <Icons.ModalClose />
    </div>
  </div>
)
