import React from 'react'

import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'
import * as Condition from './Condition'

export const Initializer = ({ children }) => {
  const { addedProducts = [] } = React.useContext(AddedProducts.Context)

  const categories = addedProducts
    .map((entry) => {
      return entry?.product?.categories?.[0]
    })
    .filter((v) => v)

  const manufacturers = addedProducts
    .map((entry) => {
      return entry?.product?.identifier?.manufacturer?.name?.en
    })
    .filter((v) => v)

  return (
    <Condition.Initializer
      categories={categories}
      manufacturers={manufacturers}
    >
      {children}
    </Condition.Initializer>
  )
}
