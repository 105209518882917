import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 *
 */
export const Title = translated('module.project')('List.Sort.Label')

/**
 */
export const Name = translated('module.project')('Field.Labels.Name')

/**
 */
export const ConstructionStartDate = translated('module.project')(
  'Field.Labels.ConstructionStartDate',
)

/**
 */
export const ConstructionEndDate = translated('module.project')(
  'Field.Labels.ConstructionEndDate',
)

/**
 */
export const RegisteredAt = translated('module.project')(
  'Field.Labels.RegisteredAt',
)

/**
 */
export const UpdatedAt = translated('module.project')('Field.Labels.UpdatedAt')
