import {
  comparatorByProperty,
  Direction,
} from '@arch-log/webapp.lib/order'

/**
 * Enum of OrderBy Key
 */
export const OrderBy = {
  Name: 'name',
}

/**
 */
export const SortKeys = [OrderBy.Name]

/**
 *
 */
export const Sorter = (
  key = OrderBy.Name,
  direction = Direction.Desc,
  { language = 'ja' },
) => {
  switch (key) {
    case OrderBy.Name:
      return comparatorByProperty(
        (entry) => entry.sortNames[language],
        direction,
      )
    default:
      return (a, b) => 0
  }
}

/**
 */
export { Direction }
