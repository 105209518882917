import React from 'react'

import * as BaseIcons from '@arch-log/webapp.shared/ProductIcons'


/**
 */
export const ParentChildIcon = () => (<BaseIcons.ParentChildIcon.Default/>)

/**
 */
export const PrivateIcon = () => (<BaseIcons.IsPrivate.Default/>)

