import React from 'react'

import * as Entry from '@arch-log/webapp.modules/project.product.v2/components/Scope/DeprecatedEntry'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from './Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 * Each
 */
export const Each = withContextValue(
  Context,
  ({ entries }) => entries,
)(
  // Render Component
  ({
    value: entries = [],
    keyBy = (entry, idx) => idx,
    render: Renderer = ChildrenRenderer,
    ...props
  }) => {
    return entries.map((entry, idx) => {
      const key = keyBy(entry, idx)
      return (
        <Entry.Provider entry={entry} key={key}>
          <Renderer {...props} entry={entry} />
        </Entry.Provider>
      )
    })
  },
)
