import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import { createField } from '../../utils'

/**
 */
export const ProductClassification = createField(
  'Classifications_Basic_ProductClassification',
  () => (
    <>
      <Product.Properties.Classifications.ProductClassification />
    </>
  ),
  Product.Properties.Classifications.HasProductClassification,
)

/**
 */
export const ProductType = createField(
  'Classifications_Basic_ProductType',
  () => (
    <>
      <Product.Properties.Classifications.ProductType />
    </>
  ),
  Product.Properties.Classifications.HasProductType,
)

/**
 */
export const ProductCategory = createField(
  'Classifications_Basic_ProductCategory',
  () => (
    <>
      <Product.Properties.Classifications.ProductCategory />
    </>
  ),
  Product.Properties.Classifications.HasProductCategory,
)

/**
 */
export const GradeType = createField(
  'Classifications_Basic_GradeType',
  () => (
    <>
      <Product.Properties.Classifications.GradeType />
    </>
  ),
  Product.Properties.Classifications.HasGradeType,
)

/**
 */
export const SizeType = createField(
  'Classifications_Basic_SizeType',
  () => (
    <>
      <Product.Properties.Classifications.SizeType />
    </>
  ),
  Product.Properties.Classifications.HasSizeType,
)

/**
 */
export const ShapeType = createField(
  'Classifications_Basic_ShapeType',
  () => (
    <>
      <Product.Properties.Classifications.ShapeType />
    </>
  ),
  Product.Properties.Classifications.HasShapeType,
)

/**
 */
export const AlignmentType = createField(
  'Classifications_Basic_AlignmentType',
  () => (
    <>
      <Product.Properties.Classifications.AlignmentType />
    </>
  ),
  Product.Properties.Classifications.HasAlignmentType,
)

/**
 */
export const MaterialType = createField(
  'Classifications_Basic_MaterialType',
  () => (
    <>
      <Product.Properties.Classifications.MaterialType />
    </>
  ),
  Product.Properties.Classifications.HasMaterialType,
)

/**
 */
export const ProductStyle = createField(
  'Classifications_Basic_ProductStyle',
  () => (
    <>
      <Product.Properties.Classifications.ProductStyles.Each>
        <Product.Properties.Classifications.ProductStyle.Value />
      </Product.Properties.Classifications.ProductStyles.Each>
    </>
  ),
  Product.Properties.Classifications.HasProductStyles,
)

/**
 */
export const ProductDesigner = createField(
  'Classifications_Basic_ProductDesigner',
  () => (
    <>
      <Product.Properties.Classifications.ProductDesigner />
    </>
  ),
  Product.Properties.Classifications.HasProductDesigner,
)

/**
 */
export const ProductApplications = createField(
  'Classifications_Basic_ProductApplications',
  () => (
    <>
      <Product.Properties.Classifications.ProductApplications />
    </>
  ),
  Product.Properties.Classifications.HasProductApplications,
)
