import React from 'react'

import * as styles from './styles.module.scss'

import * as BaseTag from '@arch-log/webapp.atomics/components/Tag'

/**
 */
export const TagName = ({ children, canDelete = true, onDelete }) => {
  if (canDelete && onDelete) {
    return (
      <BaseTag.Container>
        <BaseTag.Label>{children}</BaseTag.Label>
        <BaseTag.Delete onDelete={onDelete} />
      </BaseTag.Container>
    )
  }

  return (
    <BaseTag.Container>
      <BaseTag.Label>{children}</BaseTag.Label>
    </BaseTag.Container>
  )
}
