import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('app')(
  'SampleRequestFilteredConfirmModal.Message',
)

/**
 */
export const Ok = translated('app')('SampleRequestFilteredConfirmModal.Ok')
