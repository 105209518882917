// extracted by mini-css-extract-plugin
export var AcceptButton = "styles-module--AcceptButton--dIL7b";
export var CancelButton = "styles-module--CancelButton--N7Qo+";
export var ConfirmModalErros = "styles-module--ConfirmModalErros--ptLuC";
export var DeletionConfirmation = "styles-module--DeletionConfirmation--tpvAM";
export var Description = "styles-module--Description--iLBkp";
export var FormDescription = "styles-module--FormDescription--Il9XP";
export var Message = "styles-module--Message--UYcB4";
export var Product = "styles-module--Product--FTuZV";
export var ProductValue = "styles-module--ProductValue--gIv4u";
export var Products = "styles-module--Products--XkkgB";
export var ServiceDescription = "styles-module--ServiceDescription--o1HX8";
export var ServiceDescriptionContainer = "styles-module--ServiceDescriptionContainer--xarpH";
export var ServiceDescriptionList = "styles-module--ServiceDescriptionList--pGoY3";
export var ServiceName = "styles-module--ServiceName--khFrq";
export var ServiceProduct = "styles-module--ServiceProduct--pi5FK";
export var ServiceProductList = "styles-module--ServiceProductList--+k3eh";
export var SubDescription = "styles-module--SubDescription--+v+0J";
export var SubmitButton = "styles-module--SubmitButton--TqC+g";