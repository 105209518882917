import React from 'react'

import { Context } from './Context.jsx'
import { Version } from '@arch-log/webapp.usecases/ProjectBimRequest/Base/LocalStorageRules.js'
import * as Local from '@arch-log/webapp.usecases/ProjectBimRequest/AddTargetProducts/Local'

/**
 *
 */
export const Initializer = ({ children }) => {
  const { addProductIds } = Local.useStorage()

  const addTargetProduct = ({ projectId, productIds }) => {
    addProductIds({
      projectId,
      productIds: productIds.map((id) => addHyphens(id)),
    })
  }

  return (
    <Context.Provider value={{ addTargetProduct }}>{children}</Context.Provider>
  )
  //
  //
  //  const [value, setValue, remove] = Local.useStorage()
  //
  //  const addTargetProduct = ({projectId, productIds}) => {
  //    // FIXME: productIdがUUID化するまでの一時的な対応
  //    productIds = productIds.map(id => addHyphens(id))
  //    setValue((prev) => {
  //      return (() => {
  //        if (prev?.version === Version) {
  //          // merge
  //          return {
  //            ...prev,
  //            [projectId]: {
  //              productIds: [...new Set([
  //                  ...(prev?.[projectId]?.productIds ?? []),
  //                  ...productIds
  //                ])
  //              ],
  //              state: States.INIT,
  //            },
  //          }
  //        } else {
  //          // overwrite
  //          return {
  //            version: Version,
  //            [projectId]: {
  //              productIds,
  //            },
  //          }
  //        }
  //      })()
  //    })
  //  }
  //
  //  return <Context.Provider value={{ addTargetProduct }}>{ children }</Context.Provider>
}

const addHyphens = (v) => {
  if (!v) {
    throw new Error(`Invalid input ${v}`)
  }
  const temp = v.replaceAll('-', '')
  if (temp.length !== 32) {
    throw new Error(`Invlaid UUID format ${temp}`)
  }
  return [
    temp.substr(0, 8),
    temp.substr(8, 4),
    temp.substr(12, 4),
    temp.substr(16, 4),
    temp.substr(20),
  ].join('-')
}
