import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = () => {
  return (
    <Containers.Initializer defaultContent="filter">
      <Layout.Wrapper>
        <Layout.Panes>
          <Containers.Panes />
        </Layout.Panes>
        <Layout.Menus>
          <Containers.Menus />
        </Layout.Menus>
      </Layout.Wrapper>
    </Containers.Initializer>
  )
}
