import React from 'react'

import * as Link from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Properties/BimInfo/Link'

import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Properties/BimInfo'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const Each = withContextValue(
  Context,
  ({ data }) => data.links,
)(
  ({
    //
    value: values,
    render: Renderer = ChildrenRenderer,
    ...props
  }) => {
    return values.map((value, index) => {
      return (
        <Link.Context.Provider value={value} key={index}>
          <Renderer {...props} />
        </Link.Context.Provider>
      )
    })
  },
)
