import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'

import { createField } from '../utils'

/**
 * FIXME
 */
export const SampleBook = createField(
  'BasicInformations_SampleBook',
  () => <Product.Properties.BasicInformation.SampleBook />,
  Product.Properties.BasicInformation.HasSampleBook,
)

/**
 * FIXME
 */
export const Components = createField(
  'BasicInformations_Components',
  () => <Product.Properties.BasicInformation.Component />,
  Product.Properties.BasicInformation.HasComponent,
)

/**
 * FIXME
 */
export const Stock = createField(
  'BasicInformations_Stock',
  () => <Product.Properties.BasicInformation.Stock />,
  Product.Properties.BasicInformation.HasStock,
)

/**
 * FIXME
 */
export const Packing = createField(
  'BasicInformations_Packing',
  () => <Product.Properties.BasicInformation.Packing />,
  Product.Properties.BasicInformation.HasPacking,
)

/**
 * FIXME
 */
export const SalesUnits = createField(
  'BasicInformations_SalesUnits',
  () => <Product.Properties.BasicInformation.SalesUnit />,
  Product.Properties.BasicInformation.HasSalesUnit,
)

/**
 * FIXME
 */
export const OemManufacturer = createField(
  'BasicInformations_OemManufacturer',
  () => <Product.Properties.BasicInformation.OemManufacturer />,
  Product.Properties.BasicInformation.HasOemManufacturer,
)

/**
 * FIXME
 */
export const ManufacturedIn = createField(
  'BasicInformations_ManufacturedIn',
  () => <Product.Properties.BasicInformation.ManufacturedIn />,
  Product.Properties.BasicInformation.HasManufacturedIn,
)

/**
 * FIXME
 */
export const CountryOfOrigin = createField(
  'BasicInformations_CountryOfOrigin',
  () => <Product.Properties.BasicInformation.CountryOfOrigin />,
  Product.Properties.BasicInformation.HasCountryOfOrigin,
)

/**
 * FIXME
 */
export const ShippingTargetAreaCountry = createField(
  'BasicInformations_ShippingTargetAreaCountry',
  () => (
    <>
      <Product.Properties.BasicInformation.ShippingTargetAreaCountry />
    </>
  ),
  Product.Properties.BasicInformation.HasShippingTargetAreaCountry,
)
