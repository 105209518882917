import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  products,
  onOk,
  children,
  components: {
    Wrapper = Layout.Wrapper,
    Header = Layout.Header,
    Body = Layout.Body,
    Footer = Layout.Footer,
  } = {},
}) => {
  return (
    <Wrapper>
      <Body>
        <Containers.Message />
      </Body>
      <Footer>
        <Containers.Ok onClick={onOk} />
      </Footer>
    </Wrapper>
  )
}
