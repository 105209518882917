import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children, enables = [] }) => {
  return (
    <Context.Provider
      value={{
        enables: [enables].flat().filter((v) => v),
        hasEnables: enables && enables.length > 0,
      }}
    >
      {children}
    </Context.Provider>
  )
}
