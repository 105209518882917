import React from 'react'

import * as Remote from '@arch-log/webapp.modules/product-category.v2/components/Remote'
import { Provider } from './Provider'

export const InitializeWithRemote = ({
  //
  id,
  children,
  ...props
}) => {
  return (
    <Remote.Queries.Entries.Loader
      ids={[id].flat()}
      render={({ data }) => {
        return (
          <Provider {...((data ?? {})?.productCategories?.entries?.[0] ?? {})}>
            {children}
          </Provider>
        )
      }}
    />
  )
}
