import React from 'react'

import * as styles from './layout.module.scss'

export * from '../Template/Layout'

/**
 */
export const HeaderLeft = ({ children }) => (
  <div className={styles.HeaderLeft}>{children}</div>
)

/**
 */
export const HeaderCenter = ({ children }) => (
  <div className={styles.HeaderCenter}>{children}</div>
)
/**
 */
export const HeaderRight = ({ children }) => (
  <div className={styles.HeaderRight}>{children}</div>
)
