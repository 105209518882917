import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

export const Title = translated('module.project.product')('List.Sort.Title')

export const ProductName = translated('module.project.product')(
  'List.Sort.OrderBy.ProductName',
)

export const AddedAt = translated('module.project.product')(
  'List.Sort.OrderBy.AddedAt',
)

export const ProductCode = translated('module.project.product')(
  'List.Sort.OrderBy.ProductCode',
)

export const Manufacturer = translated('module.project.product')(
  'List.Sort.OrderBy.Manufacturer',
)

export const Category = translated('module.project.product')(
  'List.Sort.OrderBy.Category',
)

export const RegisteredAt = translated('module.project.product')(
  'List.Sort.OrderBy.RegisteredAt',
)
