import React from 'react'

import { Context as StyleContext } from '../../Style'

/**
 */
export const Catalog = ({ children }) => {
  const { styles } = React.useContext(StyleContext)

  return <dl className={styles.Catalog}>{children}</dl>
}

/**
 */
export const CatalogTitle = ({ children }) => <dt>{children}</dt>

/**
 */
export const CatalogPages = ({ children }) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <dd>
      <ul className={styles.CatalogPageList}>{children}</ul>
    </dd>
  )
}

/**
 */
export const CatalogPage = ({ children }) => <li>{children}</li>
