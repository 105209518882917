import React from 'react'

import { Context } from './Context'

/**
 */
export const useActions = () => {
  const { setSelected, resetSelected } = React.useContext(Context)

  return {
    setSelected,
    resetSelected,
  }
}
