import React from 'react'

import * as Base from '@arch-log/webapp.shared/Styled/TabbedListHeader/Containers'

import * as Styled from './Styled'
import * as BasePanes from './Panes'

//export * from '@arch-log/webapp.shared/Styled/TabbedListHeader/Containers'

export const Initializer = Base.Initializer

/**
 */
export const MenuFilter = ({ name }) => (
  <Base.Menu name="filter">
    <Styled.FilterIcon />
  </Base.Menu>
)

export const MenuSort = () => (
  <Base.Menu name="sort">
    <Styled.SortIcon />
  </Base.Menu>
)

export const PaneFilter = () => (
  <Base.Pane name="filter">
    <BasePanes.Filter.Default
      components={{
        Title: { Decorator: BasePanes.Template.Styled.Title },
        Layout: {
          Wrapper: BasePanes.Template.Layout.Wrapper,
          Header: BasePanes.Template.Layout.Header,
        },
      }}
    />
  </Base.Pane>
)

export const PaneSort = () => (
  <Base.Pane name="sort">
    <BasePanes.Sort.Default
      components={{
        Title: { Decorator: BasePanes.Template.Styled.Title },
        Layout: {
          Wrapper: BasePanes.Template.Layout.Wrapper,
          Header: BasePanes.Template.Layout.Header,
        },
      }}
    />
  </Base.Pane>
)

export const Panes = () => (
  <Base.Panes>
    <PaneFilter />
    <PaneSort />
  </Base.Panes>
)

export const Menus = () => (
  <>
    <MenuFilter />
    <MenuSort />
  </>
)
