import React from 'react'

import * as defaultStyles from './styles.module.scss'

/**
 */
export const Selections = ({ children, styles: overwriteStyles }) => {
  const styles = { ...defaultStyles, ...overwriteStyles }
  return <ul className={styles.Selections}>{children}</ul>
}

export const Selection = ({
  children,
  onSelect,
  isSelected,
  styles: overwriteStyles,
}) => {
  const styles = { ...defaultStyles, ...overwriteStyles }
  return (
    <li
      onClick={() => {
        onSelect()
      }}
      className={
        isSelected ? styles.Selections_Item_Selected : styles.Selections_Item
      }
    >
      {children}
    </li>
  )
}

/**
 */
export const SelectionsWrapper = ({ children, styles: overwriteStyles }) => {
  const styles = { ...defaultStyles, ...overwriteStyles }
  return <div className={styles.Selections_Wrapper}>{children}</div>
}

/**
 */
export const SelectedList = ({ children }) => (
  <ul className={defaultStyles.SelectedList}>{children}</ul>
)

/**
 */
export const SelectedListItem = ({ children, ...props }) => (
  <li className={defaultStyles.SelectedListItem} {...props}>
    <span className={defaultStyles.SelectedListItem_Label}>{children}</span>{' '}
    <span className={defaultStyles.SelectedListItem_Arrow}>&gt;</span>
  </li>
)

/**
 */
export const SelectorItems = ({ children }) => (
  <ul className={defaultStyles.Selections}>{children}</ul>
)

/**
 */
export const SelectorItem = ({ children, onSelect }) => (
  <li className={defaultStyles.Selections_Item} onClick={onSelect}>
    {children}
  </li>
)

/**
 */
export const FilterInput = ({ value = '', onChangeValue, ...props }) => (
  <input
    {...props}
    value={value}
    onChange={(e) => onChangeValue(e.target.value, e)}
    className={defaultStyles.FilterInput}
  />
)

/**
 */
export const Empty = ({ children }) => (
  <li className={defaultStyles.Empty}>{children}</li>
)
