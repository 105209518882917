import React from 'react'

import * as styles from './layout.module.scss'

import * as BaseTag from '@arch-log/webapp.atomics/components/Tag'


/**
 */
export const TagList = ({ children }) => (
  <ul className={styles.TagList}>{children}</ul>
)

/**
 */
export const TagListItem = ({ children }) => (
  <li className={styles.TagListItem}>{children}</li>
)

