import React from 'react'

import { Context } from './Context'

/**
 */
export const useProperty = (selector) => {
  const data = React.useContext(Context)

  return selector(data)
}
