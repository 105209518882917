export const Color = [
  'colors_name',
  'colors_code',
  'colors_available_range',
  'colors_unevenness',
]

export const Texture = ['surface_texture', 'gloss']

export const PatternType = [
  'pattern_type',
  'pattern_name',
  'pattern_wood_species',
  'pattern_repeat_height',
  'pattern_repeat_width',
  'pattern_repeat_step',
  'pattern_note',
]

export const Shape = [
  'accessories',
  'pitch',
  'chamfering',
  'orientation',
  'angle',
]
