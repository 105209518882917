import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import * as ConfirmModal from '@arch-log/webapp.shared/ConfirmModal'
import * as ConfirmModalContent from '@arch-log/webapp.shared/ConfirmModal/Content'
import * as ProjectProduct from '@arch-log/webapp.modules/project.product.v2/components'
import * as DeleteForm from '@arch-log/webapp.modules/project.product.v2/components/DeleteForm'
import * as Fields from './Fields'
import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Styled from './Styled'
import * as Literals from './Literals'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { useAsync } from 'react-use'

import * as actions from '@arch-log/webapp.modules/project.product/actions'

/**
 */
export const Init = ({ next }) => {
  React.useEffect(() => {
    next({
      defaultValues: { deletionConfirmation: '' },
    })
  }, [])

  return null
}

/**
 */
export const Input = ({ next, context, onCancel, tags, serviceProducts }) => {
  return (
    <DeleteForm.Initializer
      onSubmit={(data) => next({ formData: data })}
      defaultValues={context?.defaultValues}
      values={context?.formData}
      errors={context?.errors}
    >
      <Layout.Container>
        <Layout.Body>
          <Containers.Description />
          <Containers.SubDescription />
          <Containers.ServiceDescriptionList tags={tags} />
          <Containers.ServiceProductList serviceProducts={serviceProducts} />
          <Containers.FormDescription />
          <Containers.FormErros />
          <Fields.DeletionConfirmation />
        </Layout.Body>
        <Layout.Footer>
          <DeleteForm.Submit render={Containers.SubmitButton} />
          <Containers.CancelButton onCancel={onCancel} />
        </Layout.Footer>
      </Layout.Container>
    </DeleteForm.Initializer>
  )
}

/**
 */
export const QueryProgress = ({ next }) => {
  const { loading } = ProjectProduct.Remote.Queries.Entries.useQuery()

  useEffect(() => {
    if (loading) {
      return
    }

    next()
  }, [loading])

  return <Containers.QueryLoading />
}

/**
 */
export const Confirm = ({
  projectId,
  productIds,
  next,
  prev,
  context,
  tags,
  serviceProducts,
}) => {
  const { hideConfirm } = ConfirmModal.useConfirmModal()

  const SubFlow = createFlow([
    ConfirmForUnTaggedProducts,
    ConfirmForTaggedProducts,
  ])

  return (
    <Layout.Container>
      <Layout.Body>
        <SubFlow
          projectId={projectId}
          productIds={productIds}
          context={context}
          onComplete={() => {
            next()
          }}
          onCancel={() => {
            hideConfirm()
          }}
          prev={prev}
          tags={tags}
          serviceProducts={serviceProducts}
        />
      </Layout.Body>
    </Layout.Container>
  )
}

/**
 */
export const ConfirmForUnTaggedProducts = ({
  next,
  tags,
  onCancel,
  onComplete,
  context,
}) => {
  useEffect(() => {
    if (tags.length) {
      next()
    }
  }, [tags])

  return (
    <ConfirmModalContent.Default
      onOk={(e) => {
        e.hideModal = false
        onComplete()
      }}
      onCancel={onCancel}
      cancel={() => <Literals.Cancel />}
      components={{
        Message: () => (
          <Layout.ConfirmMessageLayoutWrapper>
            <Containers.ConfirmMessage />
            {context?.errorKey ? (
              <Containers.ConfirmModalErros errorKey={context.errorKey} />
            ) : null}
          </Layout.ConfirmMessageLayoutWrapper>
        ),
        Ok: {
          Button: Styled.AcceptButton,
        },
        Layout: {
          Wrapper: Layout.ConfirmLayoutWrapper,
        },
      }}
    />
  )
}

/**
 */
export const ConfirmForTaggedProducts = ({
  next,
  context,
  onCancel,
  tags,
  serviceProducts,
  onComplete,
}) => {
  useEffect(() => {
    if (!tags.length) {
      next()
    }
  }, [tags])

  return (
    <DeleteForm.Initializer
      onSubmit={onComplete}
      defaultValues={context?.defaultValues}
      values={context?.formData}
      errors={context?.errors}
    >
      <Layout.Container>
        <Layout.Body>
          <Containers.Description />
          <Containers.SubDescription />
          <Containers.ServiceDescriptionList tags={tags} />
          <Containers.ServiceProductList serviceProducts={serviceProducts} />
          <Containers.FormDescription />
          <Containers.FormErros />
          <Fields.DeletionConfirmation />
        </Layout.Body>
        <Layout.Footer>
          <DeleteForm.Submit render={Containers.SubmitButton} />
          <Containers.CancelButton onCancel={onCancel} />
        </Layout.Footer>
      </Layout.Container>
    </DeleteForm.Initializer>
  )
}

/**
 */
const ProgressStep = ({ projectId, productIds, next, prev, tags }) => {
  const {
    deleteEntries,
  } = ProjectProduct.Remote.Mutations.DeleteEntries.useMutation()

  const { entries } = ProjectProduct.Scope.DeprecatedEntries.useEntries()

  const productV2Ids = productIds
    .map((id) => entries.find((e) => e.oldId === id)?.id)
    .filter((v) => v)

  const dispatch = useDispatch()

  useAsync(async () => {
    try {
      await deleteEntries({
        variables: {
          projectId,
          productIds: productV2Ids,
        },
        onCompleted: (data) => {
          // TODO: convert project.product.v2
          dispatch(actions.initLoadEntries())

          next()
        },
        onError: (error) => {
          const key = 'Failed'
          tags.length
            ? prev({ errors: { _form: key } })
            : prev({ errorKey: key })

          // TODO: server response error
          // prev({
          //   errors: { _form: `Form.Error.${error.message}` },
          // })
        },
      })
    } catch (err) {
      const key = 'ContactToOpeartor'
      tags.length ? prev({ errors: { _form: key } }) : prev({ errorKey: key })
    }
  }, [])

  return <Containers.ProgressBar />
}

const BaseSubFlow = createFlow([ProgressStep])

/**
 */
export const Progress = ({
  projectId,
  productIds,
  next,
  prev,
  context,
  tags,
}) => {
  return (
    <Layout.Container>
      <Layout.Body>
        <BaseSubFlow
          projectId={projectId}
          productIds={productIds}
          context={context}
          onComplete={() => {
            next()
          }}
          prev={prev}
          tags={tags}
        />
      </Layout.Body>
    </Layout.Container>
  )
}

/**
 */
export const Complete = ({ onComplete }) => {
  React.useEffect(() => {
    onComplete()
  }, [])

  return null
}
