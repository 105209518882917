import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'

/**
 */
export const PublishedAt = withContextValue(
  Context,
  (data) => data.entry.salesInfo.publishedAt,
)(Renderers.SalesInfo.PublishedAt)

/**
 */
export const DiscontinuedAt = withContextValue(
  Context,
  (data) => data.entry.salesInfo.discontinuedAt,
)(Renderers.SalesInfo.DiscontinuedAt)

/**
 */
export const TargetAreaCountry = withContextValue(
  Context,
  (data) => data.entry.salesInfo.targetAreaCountry,
)(Renderers.SalesInfo.TargetAreaCountry)

/**
 */
export const SalesUnit = withContextValue(
  Context,
  (data) => data.entry.salesInfo.salesUnit,
)(Renderers.SalesInfo.SalesUnit)

/**
 */
export const Stock = withContextValue(
  Context,
  (data) => data.entry.salesInfo.stock,
)(Renderers.SalesInfo.Stock)

/**
 */
export const Packing = withContextValue(
  Context,
  (data) => data.entry.salesInfo.packing,
)(Renderers.SalesInfo.Packing)

/**
 */
export const Components = withContextValue(
  Context,
  (data) => data.entry.salesInfo.components,
)(Renderers.SalesInfo.Components)

/**
 */
export const SampleBook = withContextValue(
  Context,
  (data) => data.entry.salesInfo.sampleBook,
)(Renderers.SalesInfo.SampleBook)
