import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'

import * as DeleteForm from '@arch-log/webapp.modules/project.product.v2/components/DeleteForm'
import * as Literals from './Literals'
import * as Styled from './Styled'

import * as Layouts from './Layout'

/**
 */
export const Title = () => (
  <Styled.Title>
    <Literals.Title />
  </Styled.Title>
)

/**
 */
export const Description = () => (
  <Styled.Description>
    <Literals.Description />
  </Styled.Description>
)

/**
 */
export const SubDescription = () => (
  <Styled.SubDescription>
    <Literals.SubDescription />
  </Styled.SubDescription>
)

/**
 */
export const ServiceDescriptionList = ({ tags = [] }) => {
  return (
    <Styled.ServiceDescriptionList>
      {tags.map((tag, index) => {
        return <ServiceDescription tag={tag} key={index} />
      })}
    </Styled.ServiceDescriptionList>
  )
}

/**
 */
export const ServiceProductList = ({ serviceProducts = [] }) => {
  return (
    <Styled.ServiceProductList>
      {serviceProducts.map(({ tag, products }, index) => {
        return (
          <Styled.ServiceProduct key={index}>
            <ServiceName tag={tag} />
            <Products products={products} />
          </Styled.ServiceProduct>
        )
      })}
    </Styled.ServiceProductList>
  )
}

/**
 */
export const ServiceName = ({ tag, format = (name) => `[${name}]` }) => {
  const { name } = Literals.useConvertTagToServiceName({ tag })

  const formatName = format(name)

  return <Styled.ServiceName>{formatName}</Styled.ServiceName>
}

/**
 */
export const Products = ({ products = [] }) => {
  return (
    <Styled.Products>
      {products.map((product, index) => (
        <Product product={product} key={index} />
      ))}
    </Styled.Products>
  )
}

/**
 */
export const Prefix = ({ value = '-' }) => {
  return <Styled.Prefix>{value}</Styled.Prefix>
}

/**
 */
export const Product = ({ product }) => {
  const { code, providerLabel } = Literals.useProductValue({ product })

  const formatValue = Literals.formatProductProperty({ code, providerLabel })

  return (
    <Styled.Product>
      <Prefix />
      <Styled.ProductValue>{formatValue}</Styled.ProductValue>
    </Styled.Product>
  )
}

/**
 */
export const ServiceDescription = ({ tag }) => {
  const { description } = Literals.useConvertTagToServiceDescription({
    tag,
  })

  return (
    <Styled.ServiceDescriptionContainer>
      <Prefix />
      <Styled.ServiceDescription>{description}</Styled.ServiceDescription>
    </Styled.ServiceDescriptionContainer>
  )
}

/**
 */
export const CancelButton = ({ onCancel }) => (
  <Styled.CancelButton onClick={onCancel}>
    <Literals.Cancel />
  </Styled.CancelButton>
)

/**
 */
export const SubmitButton = ({ disabled }) => (
  <Styled.SubmitButton disabled={disabled}>
    <Literals.Submit />
  </Styled.SubmitButton>
)

/**
 */
export const FormDescription = () => (
  <Styled.FormDescription>
    <Literals.FormDescription />
  </Styled.FormDescription>
)

/**
 */
export const FormErros = () => {
  const { formatFormError } = Literals.useLiterals()

  return <DeleteForm.Errors.Form formatMessage={formatFormError} />
}

/**
 */
export const QueryLoading = () => {
  return (
    <Layouts.Container>
      <Loader>
        <Literals.QueryProgress />
      </Loader>
    </Layouts.Container>
  )
}

/**
 */
export const ProgressBar = () => {
  return (
    <Loader>
      <Literals.Progress />
    </Loader>
  )
}

/**
 */
export const ConfirmMessage = ({
  componenst: { Decorator = Styled.Message } = {},
}) => (
  <Decorator>
    <Literals.ConfirmMessage />
  </Decorator>
)

/**
 */
export const AcceptButton = ({
  components: { Button = Styled.AcceptButton } = {},
  onClick,
  ...props
}) => (
  <Button onClick={onClick} {...props}>
    <Literals.DeleteConfirmAccept />
  </Button>
)

/**
 */
export const ConfirmModalErros = ({
  components: { Decorator = Styled.ConfirmModalErros } = {},
  errorKey,
}) => {
  const { formatFormError } = Literals.useLiterals()

  return <Decorator>{formatFormError(errorKey)}</Decorator>
}
