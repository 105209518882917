import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'
import { Trans } from 'react-i18next'

/**
 */
export const Message = ({ components }) => {
  const { t } = useTranslation('app')

  return (
    <Trans
      t={t}
      i18nKey={t('BulkCopyProductModal.Complete.Message')}
      components={components}
    />
  )
}

/**
 */
export const CompleteButton = translated('app')(
  'BulkCopyProductModal.Complete.CompleteButton',
)

/**
 */
export const CopyDataEmptyMessage = translated('app')(
  'BulkCopyProductModal.Complete.CopyDataEmptyMessage',
)
