import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { Context } from './Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const Label = withContextValue(
  Context,
  (data) => data.name,
)(Renderers.Specification.Label)

/**
 */
export const Value = withContextValue(Context, (data) => ({
  value: data.value,
  valueDirty: data.valueDirty,
}))(Renderers.Specification.Value)

/**
 */
export const Unit = withContextValue(Context, (data) => ({
  unitLabel: data.unitLabel,
  unitType: data.unitType,
}))(Renderers.Specification.Unit)

/**
 */
export const Note = withContextValue(
  Context,
  (data) => data.note,
)(Renderers.Specification.Note)
