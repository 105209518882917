import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Link = ({
  render: Renderer = ValueRenderers.LinkRenderer,
  value,
  defaultValue = null,
  ...props
}) => {
  const { url = null, label = null } = value ?? defaultValue

  return <Renderer value={url} alt={label} {...props} />
}
