import React from 'react'

import { TextRenderer } from '@arch-log/webapp.atomics/components/ValueRenderer'

import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const Message = ({
  components: {
    Decorator = Styled.Message,
    AddTotalProducts: addTotalProductsComponents,
    CopyDataEmptyMessage: copyDataEmptyMessageComponents,
  } = {},
  copyData,
}) => {
  const totalCopyData = copyData.length !== 0 ? copyData.length : null

  if (!totalCopyData) {
    return (
      <Decorator>
        <CopyDataEmptyMessage components={copyDataEmptyMessageComponents} />
      </Decorator>
    )
  }

  return (
    <Decorator>
      <Literals.Message
        components={{
          AddTotalProducts: (
            <AddTotalProducts
              components={addTotalProductsComponents}
              value={totalCopyData}
            />
          ),
        }}
      />
    </Decorator>
  )
}

/**
 */
export const CopyDataEmptyMessage = ({
  components: { Decorator = Styled.CopyDataEmptyMessage } = {},
}) => {
  return (
    <Decorator>
      <Literals.CopyDataEmptyMessage />
    </Decorator>
  )
}

/**
 */
export const AddTotalProducts = ({
  components: {
    Decorator = Styled.AddTotalProducts,
    Renderer = TextRenderer,
  } = {},
  value,
}) => {
  return (
    <Decorator>
      <Renderer value={value} />
    </Decorator>
  )
}

/**
 */
export const CompleteButton = ({
  components: { Button = Styled.CompleteButton } = {},
  onComplete,
  ...props
}) => (
  <Button onComplete={onComplete} {...props}>
    <Literals.CompleteButton />
  </Button>
)
