import React from 'react'

import { classNames } from '@arch-log/webapp.lib/CssHelper'
import * as styles from './styles.module.scss'

const modeByType = (type) => {
  switch (type) {
    case 'geometry':
      return '3D'
    case 'material':
      return '2D'
    default:
      return 'UNKNOWN'
  }
}

/**
 */
export const BIM = ({
  isDownload = false,
  isProxy = false,
  isLink = false,
  hasUrl = true,
  type = null,
}) => {
  const className = classNames(
    [
      styles.BIM,
      hasUrl ? null : styles.BIM_NoURL,
      isProxy ? styles.BIM_Proxy : null,
      isDownload ? styles.BIM_Download : null,
      isLink ? styles.BIM_Link : null,
    ].filter((v) => v),
  )

  const title = [
    isDownload ? 'Download ' : null,
    type ? modeByType(type) : null,
    'BIM',
    hasUrl ? null : 'missing URLs',
    isProxy ? 'has proxy' : 'has no proxy',
  ]
    .filter((v) => v)
    .join(' ')

  return (
    <div className={className} title={title}>
      BIM
    </div>
  )
}
