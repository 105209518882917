import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

export const FilterTag = ({ children, onDelete }) => {
  return (
    <span className={styles.FilterTag}>
      {children}
      <span onClick={() => onDelete()} className={styles.FilterTag_Delete}>
        x
      </span>
    </span>
  )
}

export const KeywordInput = (props) => (
  <label>
    <input {...props} className={styles.Keyword} type="text" />
  </label>
)

/**
 */
export const ToggleButton = ({ children, onClick }) => (
  <span className={styles.FilterButton} onClick={onClick}>
    {children}
    <div className={styles.FilterButton_Icon}>
      <Icons.Dropdown />
    </div>
  </span>
)

/**
 */
export const ResetButton = ({ children, onReset }) => (
  <span className={styles.ResetButton} onClick={onReset}>
    {children}
  </span>
)

/**
 * FIX ME
 */
export const BimCheckBox = ({ value, children, onChangeValue, ...props }) => {
  const icon = value ? (
    <div className={styles.Check_Selected}>
      <Icons.CheckBox_Checked />
    </div>
  ) : (
    <div className={styles.Check} />
  )

  return (
    <label className={styles.CheckBox_Container}>
      <div className={styles.CheckBox}>
        {icon}
        <input
          id="bim_search_checkbox"
          type="checkbox"
          className={styles.CheckBox_Input}
          {...props}
          value={value}
          onChange={() => {
            onChangeValue(!value)
          }}
        />
      </div>
      <span className={styles.CheckBox_Label}>{children}</span>
    </label>
  )
}
