import React from 'react'

import { useDispatch } from 'react-redux'
import * as actions from '../actions'

/**
 */
export const WebLink = ({
  render: Renderer = ({ children, dispatchLog }) => <>{children}</>,
  ...props
}) => {
  const dispatch = useDispatch()

  const dispatchLog = (href = null, product = null) => {
    dispatch(
      actions.dispatch(
        'PRODUCT',
        'weblink', 
        href, 
        {
          description: 'clicked',
          product,
      }),
    )
  }

  return <Renderer dispatchLog={dispatchLog} {...props} />
}


