import React from 'react'

import { Empty } from './Empty'

// modules
import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'
import * as ManufacturerNames from '@arch-log/webapp.modules/project.product/components/ManufacturerNames'

// components
import * as ProductList from 'components/ProductList'

/**
 */
export const Default = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  return (
    <AddedProducts.Loader.AtOnce components={{ Empty }}>
      <ManufacturerNames.Provider>
        <ProductList.Default
          canEditTags={hasPermission(Permissions.TagsWrite)}
        />
      </ManufacturerNames.Provider>
    </AddedProducts.Loader.AtOnce>
  )
}
