import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { KeepRatioImageRenderer } from '@arch-log/webapp.atomics/components/ValueRenderer'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as Links from '@arch-log/webapp.shared/Links'
import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProductImageModal from '@arch-log/webapp.shared/ProductImageModal'
import * as ProductFields from '@arch-log/webapp.shared/ProductFields'
import * as BaseSupportedBIMs from '@arch-log/webapp.shared/ProductIcons/SupportedBIMs'

import * as Tags from './Tags'
import * as Tab from './Tab'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const CompareButton = () => {
  const [projectEntry] = Project.Scope.Entry.useEntry()
  const { product } = React.useContext(Product.Context)

  return (
    <Links.ProductSearch.ToTop
      compare={product.id}
      project={projectEntry.id}
      className={styles.CompareButton}
    >
      <Icons.Compare className={styles.Icon} />
      <Literals.Compare />
    </Links.ProductSearch.ToTop>
  )
}

export const SupportedBIMs = BaseSupportedBIMs.Default

/**
 */
export const Tabs = ({ children, canEditTags = false }) => (
  <Tab.Container default="info">
    <Tab.Menus>
      <Tab.Menu target="info">
        <Literals.Info />
      </Tab.Menu>
      <Tab.Menu target="tags">
        <Literals.Tags />
      </Tab.Menu>
    </Tab.Menus>
    <Tab.Content name="info">
      <Fields />
    </Tab.Content>
    <Tab.Content name="tags">
      <Tags.Default editable={canEditTags} />
    </Tab.Content>
  </Tab.Container>
)

/**
 */
export const Image = ({
  components: { Renderer = KeepRatioImageRenderer } = {},
}) => {
  const { show } = ProductImageModal.useActions()
  const { product } = React.useContext(Product.Context)

  return (
    <Product.Properties.Images.First>
      <Product.Properties.Image.Full
        className={styles.Image}
        render={({ src, ...props }) => (
          <Renderer
            src={`${src}`}
            {...props}
            onClick={() => {
              show(product)
            }}
          />
        )}
      />
    </Product.Properties.Images.First>
  )
}

/**
 */
export const Title = withDefaultRenderComponent(
  ({ components: { Decorator = Styled.Title } = {} }) => (
    <Decorator>
      <ProductFields.ManufacturerName
        render={({ children, props }) => <strong>{children}</strong>}
      />
    </Decorator>
  ),
)

/**
 */
export const ProductCode = withDefaultRenderComponent(
  ({ components: { Decorator = Styled.ProductCode } = {} }) => (
    <Decorator>
      <ProductFields.Code render={({ children, props }) => <>{children}</>} />
    </Decorator>
  ),
)

/**
 */
export const Fields = withDefaultRenderComponent(
  ({ components: { Wrapper = Styled.FieldsWrapper } = {} }) => (
    <Wrapper>
      <ProductFields.Identifiers />
      <ProductFields.Categories />
      <ProductFields.Prices />
      <ProductFields.Measurements />
      <ProductFields.Classifications />
      <ProductFields.Appearances />
      <ProductFields.BasicInformations />
      <ProductFields.Materials />
      <ProductFields.Standards />
      <ProductFields.SalesDates />
      <ProductFields.Ecos />
      <ProductFields.Features />
      <ProductFields.Notices />
      <ProductFields.Specifications />
      <ProductFields.ArchitecturalClassification />
      <ProductFields.Notes />
      <ProductFields.Links />
      <ProductFields.Ieses />
    </Wrapper>
  ),
)
