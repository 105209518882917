import React from 'react'

import * as Switchable from '@arch-log/webapp.atomics/components/SwitchableContent'
import * as Top from './Top'
import * as CategoryFilter from './CategoryFilter'
import * as ManufacturerFilter from './ManufacturerFilter'

export const Default = ({ ...props }) => {
  return (
    <Switchable.Initializer default="top">
      <Inner {...props} />
    </Switchable.Initializer>
  )
}

const Inner = ({ condition, onChangeCondition, onReset }) => {
  const { setSelected } = React.useContext(Switchable.Context)

  return (
    <>
      <Switchable.Content name="top">
        <Top.Default
          onCategory={() => setSelected('category')}
          onManufacturer={() => setSelected('manufacturer')}
          onChangeCondition={onChangeCondition}
          condition={condition}
          onReset={onReset}
        />
      </Switchable.Content>
      <Switchable.Content name="manufacturer">
        <ManufacturerFilter.Default onBack={() => setSelected('top')} />
      </Switchable.Content>
      <Switchable.Content name="category">
        <CategoryFilter.Default onBack={() => setSelected('top')} />
      </Switchable.Content>
    </>
  )
}
