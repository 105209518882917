// extracted by mini-css-extract-plugin
export var Background = "styles-module--Background--Gqka8";
export var Background_Image = "styles-module--Background_Image--z35aZ";
export var BimIcon = "styles-module--BimIcon--FUhiC";
export var Container = "styles-module--Container--+inE9";
export var Discontinued_Label = "styles-module--Discontinued_Label--fmJg6";
export var HasParent = "styles-module--HasParent--RNE64";
export var Hidden = "styles-module--Hidden--TqcT7";
export var Icon = "styles-module--Icon--DcWHy";
export var Label = "styles-module--Label--zMCbV";
export var ProductOptionIcon = "styles-module--ProductOptionIcon--u7pPI";
export var SelectEntry = "styles-module--SelectEntry--bjtAo";
export var Title = "styles-module--Title--iglt6";
export var Title_Code = "styles-module--Title_Code--5zFIH";
export var Title_ManufacturerName = "styles-module--Title_ManufacturerName--3OWGw";