import React from 'react'

import * as Entry from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from './Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 * SelectOne
 */
export const SelectOne = withContextValue(
  Context,
  ({ entries }) => entries,
)(
  // Render Component
  ({
    //
    value: entries = [],
    selector = (e) => e,
    selectBy,
    render: Renderer = ChildrenRenderer,
    ...props
  }) => {
    const selected = entries.find((entry, idx) => {
      return selector(entry) == selectBy 
    })
    return (
      <Entry.Provider entry={selected}>
        <Renderer {...props} entry={selected} />
      </Entry.Provider>
    )
  },
)
