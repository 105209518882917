import { gql } from '@apollo/client'

export const query = gql`
  query ($projectId: String!, $offset: Int, $size: Int) {
    projectProducts(projectId: $projectId, offset: $offset, size: $size) {
      entries {
        id
        name
        code
        manufacturerUn
        manufacturer {
          uniqueName
          displayNames {
            ja
            en
          }
          displayShortNames {
            ja
            en
          }
        }
        images {
          url
          thumbnailUrl
          originalUrl
        }
        state
        categories {
          name
          labels {
            ja
            en
          }
        }
      }
      total
      offset
      requestSize
    }
  }
`
