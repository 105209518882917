import React from 'react'

import { Context } from './Context'
import { query } from './query'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({
  projectId,
  productIds,
  options = {
    force: true,
  },
  onCompleted,
  onError,
  children,
  ...props
}) => {
  const [deleteProjectProductEntries, { data, loading, error, called }] =
    useMutation(query, {
      variables: {
        projectId,
        productIds,
        options,
      },
      onCompleted,
      onError,
    })

  return (
    <Context.Provider
      value={{
        deleteEntries: deleteProjectProductEntries,
        data,
        loading,
        called,
        error,
        ...props,
      }}
    >
      {children}
    </Context.Provider>
  )
}
