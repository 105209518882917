import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Layout_Wrapper}>{children}</div>
)

/**
 */
export const Header = ({ children }) => (
  <div className={styles.Layout_Header}>{children}</div>
)
