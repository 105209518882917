import React from 'react'

import * as Containers from './Containers'
import * as Styled from './Styled'
import * as Layout from './Layout'
import * as Literals from './Literals'

/**
 *
 */
export const Default = ({
  components: {
    Message: MessageDecorator = Styled.Message,
    Layout: { Wrapper = Layout.Wrapper } = {},
  } = {},
}) => (
  <Wrapper>
    <MessageDecorator>
      <Literals.Message />
    </MessageDecorator>
    <Containers.SearchProducts />
  </Wrapper>
)
