import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Components from '../Components'
import * as Labels from './labels'
import { createGroup } from '../utils'

const MaterialRenderer = ({ components = {}, t, language = null }) => {
  const DefaultComponents = React.useContext(Components.Context)

  const {
    Container = DefaultComponents.FieldContainer,
    Label = DefaultComponents.FieldLabel,
    Value = DefaultComponents.FieldValue,
  } = components

  return (
    <Container>
      <Label>
        <Product.Properties.Material.Label
          language={language}
          formatter={(v) => t(`Materials.${v}`)}
        />
      </Label>
      <Value>
        <Product.Properties.Material.Value />
      </Value>
    </Container>
  )
}

/**
 */
export const Basic = createGroup(
  'Materials_Basic',
  ({ ...props }) => (
    <Product.Properties.Materials.Filter labels={Labels.Basic}>
      <Product.Properties.Materials.Each>
        <MaterialRenderer {...props} />
      </Product.Properties.Materials.Each>
    </Product.Properties.Materials.Filter>
  ),
  (props) => (
    <Product.Properties.Materials.Has labels={Labels.Basic} {...props} />
  ),
)

/**
 */
export const Composite = createGroup(
  'Materials_Composite',
  ({ ...props }) => (
    <Product.Properties.Materials.Filter labels={Labels.Composite}>
      <Product.Properties.Materials.Each>
        <MaterialRenderer {...props} />
      </Product.Properties.Materials.Each>
    </Product.Properties.Materials.Filter>
  ),
  (props) => (
    <Product.Properties.Materials.Has labels={Labels.Composite} {...props} />
  ),
)

/**
 */
export const Carpet = createGroup(
  'Materials_Carpet',
  ({ ...props }) => (
    <Product.Properties.Materials.Filter labels={Labels.Carpet}>
      <Product.Properties.Materials.Each>
        <MaterialRenderer {...props} />
      </Product.Properties.Materials.Each>
    </Product.Properties.Materials.Filter>
  ),
  (props) => (
    <Product.Properties.Materials.Has labels={Labels.Carpet} {...props} />
  ),
)

/**
 */
export const Coating = createGroup(
  'Materials_Coating',
  ({ ...props }) => (
    <Product.Properties.Materials.Filter labels={Labels.Coating}>
      <Product.Properties.Materials.Each>
        <MaterialRenderer {...props} />
      </Product.Properties.Materials.Each>
    </Product.Properties.Materials.Filter>
  ),
  (props) => (
    <Product.Properties.Materials.Has labels={Labels.Coating} {...props} />
  ),
)
