import React from 'react'
import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useEntries = () => {
  const { entries, setEntries, sortEntries, filterEntries, resetEntries } =
    useContext()

  return [entries, { setEntries, sortEntries, filterEntries, resetEntries }]
}
