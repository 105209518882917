export const AirConditionEquipment = [
  'smax_static_pressure',
  'smax_air_volume',
  'wind_direction',
  'set_pressure',
  'amount_of_outside_air_per_person',
  'staff_density',
  'number_of_people',
  'human_body_sensible_heat',
  'latent_heat_of_the_human_body',
  'unit_area_oa_load',
  'unit_area_lighting_load',
  'heating_outside_air_load',
  'cooling_outside_air_load',
  'fire_hood_view_factor',
  'fire_power_consumption',
  'return_method',
  'support_method',
  'inspection_port_position',
  'operation_unit_position',
  'outdoor_cover',
  'fuse_operating_temperature',
  'controller',
  'gradient',
  'inside_seal',
  'external_seal',
  'inner_coating',
  'exterior_coating',
  'airtight_performance',
  'ra_size_d',
  'ra_size_w',
  'sa_size_d',
  'sa_size_w',
  'ea_size_d',
  'ea_size_w',
  'oa_size_d',
  'oa_size_w',
  'sm_size_d',
  'sm_size_w',
  'duct_diameter',
  'duct_height',
  'duct_width',
  'flue_diameter',
  'flue_height',
  'flue_width',
  'gauge',
  'notch',
  'filter_format',
  'fan_type',
  'number_of_rotations',
  'diffusion_radius',
  'reach',
  'effective_opening_area',
  'aperture_ratio',
  'unit_air_volume',
  'air_volume',
  'actual_air_volume',
  'exhaust_smoke_volume',
  'max_air_volume',
  'mid_air_volume',
  'min_air_volume',
  'air_balance_air_volume',
  'wind_speed',
  'area_wind_speed',
  'wind_velocity_through_the_coil',
  'amount_of_air_supply',
  'outside_air_volume',
  'outside_air_volume_outside_cooling',
  'outside_air_volume_total_heat_exchanger',
  'unit_outside_air_volume',
  'return_air_volume',
  'return_air_volume_outside_cooling',
  'displacement',
  'displacement_volume_outside_cooling',
  'displacement_total_heat_exchanger',
  'pressure',
  'working_pressure',
  'used_steam_pressure',
  'saturated_vapor_pressure',
  'pressure_resistance',
  'pressure_loss',
  'static_pressure',
  'external_static_pressure',
  'external_static_pressure_outside_air',
  'external_static_pressure_exhaust',
  'external_static_pressure_return_blower',
  'external_static_pressure_blower',
  'max_static_pressure',
  'mid_static_pressure',
  'min_static_pressure',
  'number_of_units_remote_control',
  'number_of_units_refrigerant_branch_unit',
  'coil_type',
  'number_of_coil_rows',
  'number_of_coil_rows_hot_water',
  'number_of_coil_rows_cold_and_hot_water',
  'number_of_coil_rows_cold_water',
  'heat_transfer_area',
  'heat_collection_area',
  'format_total_heat_exchanger',
  'format_humidifier',
  'heat_source_method',
  'humidification_method',
  'refrigerant',
  'dehumidification_amount',
  'effective_humidification_amount',
  'converted_evaporation',
  'steam_consumption',
  'radiation_amount',
  'exhaust_heat_recovery_amount',
  'rated_output',
  'heat_storage_capacity',
  'cooling_storage_capacity',
  'room_heating_capacity',
  'room_cooling_capacity',
  'room_cooling_ability_sensible_heat',
  'cooling_capacity',
  'heating_capacity',
  'heat_exchanger_capacity',
  'refrigerating_capacity',
  'ice_making_ability',
  'ipf',
  'heating_capacity_per_unit',
  'total_heat_exchange_efficiency',
  'enthalpy_exchange_efficiency_heating',
  'enthalpy_exchange_efficiency_cooling',
  'temperature_exchange_efficiency',
  'summer_dry_air_temperature',
  'outside_air_dry_bulb_temperature_in_winter',
  'summer_outside_air_wet_bulb_temperature',
  'winter_outside_air_wet_bulb_temperature',
  'summer_outside_air_relative_humidity',
  'winter_outdoor_air_relative_humidity',
  'the_room_temperature_set_value',
  'summer_indoor_dry_bulb_temperature',
  'winter_indoor_dry_bulb_temperature',
  'summer_indoor_wet_bulb_temperature',
  'winter_indoor_wet_bulb_temperature',
  'indoor_humidity_setting_value',
  'summer_indoor_relative_humidity',
  'winter_indoor_relative_humidity',
  'apf',
  'cop',
  'iplv',
  'atf_target_value',
  'wtf_target_value',
]

export const FacilityManagement = [
  'legal_useful_life',
  'estimated_useful_life',
  'warranty_per_unit',
  'warranty_parts_warranty_period',
  'warranty_description',
  'expendables',
]

export const Machine = [
  'speaker',
  'elevator_type',
  'monitor',
  'surveillance_camera',
  'emergency_stop_button',
  'card_reader',
  'communication_cable_type',
  'communication_cable_application',
  'cable_type',
  'cable_size',
  'battery_for_emergency_landing_device',
  'regenerative_power_storage_system',
  'input_terminal',
  'output_terminal',
  'relay_terminal',
  'frequency_characteristic',
  'rate_of_strain',
  'sound_volume',
  'rated_input_sound_volume',
  'rated_output_sound_volume',
  'alarm_setpoint_high',
  'alarm_setpoint_low',
  'speaker_type',
  'noise_level',
  'noise_level_per_unit',
  'sound_pressure_leveldb',
  'sound_pressure_level_63db',
  'sound_pressure_level_125db',
  'sound_pressure_level_500db',
  'sound_pressure_level_1000db',
  'sound_pressure_level_2000db',
  'sound_pressure_level_250db',
  'sound_pressure_level_4000db',
  'sound_pressure_level_8000db',
  'sound_power_leveldb',
  'sound_power_level_63db',
  'sound_power_level_125db',
  'sound_power_level_250db',
  'sound_power_level_500db',
  'sound_power_level_1000db',
  'sound_power_level_2000db',
  'sound_power_level_4000db',
  'sound_power_level_8000db',
  'cold_hot_water_volume',
  'hot_water_volume',
  'hot_water_volume_secondary_side',
  'high_temperature_water_volume',
  'cold_water_volume',
  'cold_water_secondary_side',
  'cooling_water_amount',
  'heat_source_water_volume',
  'brine_water_volume',
  'pressure_resistance_primary_side',
  'pressure_resistance_secondary_side',
  'pressure_resistance_hot_water_primary_side',
  'pressure_resistance_cold_water_primary_side',
  'pressure_resistance_high_temperature_water',
  'pressure_resistance_cooling_water',
  'coil_pressure_resistance_hot_water',
  'coil_pressure_resistance_cold_and_hot_water',
  'coil_pressure_resistance_cold_water',
  'head_loss',
  'head_loss_warm_water',
  'head_loss_cold_water',
  'head_loss_heat_source_water',
  'head_loss_high_temperature_water',
  'head_loss_cold_and_hot_water',
  'head_loss_cooling_water',
  'head_loss_brine',
  'head_loss_warm_water_primary_side',
  'head_loss_high_temperature_water_primary_side',
  'head_loss_medium_temperature_cold_water_primary_side',
  'head_loss_primary_heat_source_water',
  'head_loss_cold_water_primary_side',
  'water_temperature_difference',
  'hot_water_temperature',
  'boiling_temperature',
  'hot_water_outlet_temperature',
  'hot_water_inlet_temperature',
  'hot_water_outlet_temperature_db',
  'hot_water_inlet_temperature_db',
  'hot_water_outlet_temperature_wb',
  'hot_water_inlet_temperature_wb',
  'hot_water_outlet_temperature_primary_side',
  'hot_water_inlet_temperature_primary_side',
  'high_temperature_water_outlet_temperature',
  'high_temperature_water_outlet_temperature_primary_side',
  'high_temperature_water_inlet_temperature',
  'high_temperature_water_inlet_temperature_primary_side',
  'heat_source_water_outlet_temperature',
  'heat_source_water_outlet_temperature_primary_side',
  'heat_source_water_inlet_temperature',
  'heat_source_water_inlet_temperature_primary_side',
  'medium_temperature_cold_water_outlet_temperature',
  'medium_temperature_cold_water_outlet_temperature_primary_side',
  'medium_temperature_cold_water_inlet_temperature',
  'medium_temperature_cold_water_inlet_temperature_primary_side',
  'cold_water_outlet_temperature',
  'cold_water_inlet_temperature',
  'cold_water_outlet_temperature_db',
  'cold_water_inlet_temperature_db',
  'cold_water_outlet_temperature_wb',
  'cold_water_inlet_temperature_wb',
  'cold_water_outlet_temperature_primary_side',
  'cold_water_inlet_temperature_primary_side',
  'cooling_water_outlet_temperature',
  'cooling_water_inlet_temperature',
  'brine_outlet_temperature',
  'brine_inlet_temperature',
  'fuel_type',
  'fuel_consumption_heating',
  'fuel_consumption_hot_water_supply',
  'fuel_consumption_per_unit_heating',
  'fuel_consumption_per_unit_hot_water_supply',
  'gas_consumption_heating',
  'gas_consumption_hot_water_supply',
  'gas_consumption_freezing',
  'gas_consumption_per_unit_heating',
  'gas_consumption_per_unit_hot_water_supply',
  'hot_water_supply_per_unit',
  'hot_water_volume_per_unit',
  'amount_of_cold_water_per_unit',
  'hot_water_supply_capacity_per_unit',
]

export const Electric = [
  'power_consumption_blower_heating',
  'power_consumption_fan_cooling',
  'single_phase_power_supply_type',
  'single_phase_load_voltage',
  'single_phase_load_power',
  'rated_input_wattage',
  'input_wattage',
  'apparent_power_transformer_capacity',
  'load_capacity',
  'average_load_capacity',
  'array_tilt_angle',
  'array_azimuth',
  'array_output',
  'power_conditioner_conversion_efficiency',
  'presence_or_absence_of_grid_cooperation',
  'estimated_monthly_power_generation',
  'monthly_tilted_surface_solar_radiation',
  'number_of_months',
  'average_daily_tilted_surface_solar_radiation_per_month',
  'monthly_comprehensive_design_coefficient',
  'installation_site',
  'solar_panel_installation_location',
  'solar_module_output_per_unit_area',
  'output_per_solar_module',
  'depth_per_solar_module',
  'thickness_per_solar_module',
  'width_per_solar_module',
  'solar_module_mass',
  'solar_module_quantity',
  'total_mass_of_solar_module',
  'solar_module_conversion_efficiency',
  'number_of_solar_module_rows',
  'number_of_solar_module_lines',
  'number_of_solar_module_stages',
  'solar_cell_type',
  'solar_cell_installation_form',
  'storage_battery',
  'insolation_intensity',
  'estimated_annual_power_generation',
  'engine_rpm',
  'engine_output',
  'excitation_force_vibration',
  'minimum_amount_lubricating_oil',
  'maximum_amount_lubricating_oil',
  'type_lubricating_oil',
  'frequency_vibration',
  'lubricating_oil_amount',
  'consumption_lubricating_oil',
  'installation_altitude_upper_limit',
  'noise_level_db_a_exhaust_silencer',
  'rated_output_ps_gas_turbine',
  'rated_output_gas_turbine',
  'rated_output_diesel',
  'rated_output_generator',
  'rated_output_power_generator',
  'voltage_generator',
  'electrical_classification',
  'mounted_tank_capacity',
  'fuel_consumption',
  'fuel_consumption_light_oil',
  'fuel_consumption_kerosene',
  'generator_output',
  'load_allowed_capacity',
  'transformer_capacity',
  'wiring_connection_position',
  'line_length',
  'frequency_for_equipment',
  'frequency',
  'allowable_current',
  'input_current',
  'output_current',
  'rated_input_current',
  'maximum_current_value',
  'rated_input_voltage',
  'rated_voltage',
  'secondary_voltage',
  'voltage_electric_heater',
  'voltage_auxiliary_equipment',
  'input_power',
  'power_consumption',
  'rated_power_consumption',
  'average_power_consumption',
  'annual_power_consumption',
  'power_consumption_humidifier',
  'power_consumption_total_heat_exchanger',
  'power_consumption_blower',
  'power_consumption_heating',
  'power_consumption_electric_heater',
  'power_consumption_auxiliary_equipment',
  'power_consumption_cooling',
  'power_consumption_per_unit',
  'power_consumption_per_unit_heating',
  'power_consumption_per_unit_cooling',
  'power_factor',
  'phase',
  'phase_electric_heater',
  'power_supply_type',
  'power_supply_type_auxiliary_equipment',
  'power_unit',
  'ups_power',
  'power_supply_capacity',
  'output_capacity',
  'rated_input_capacity',
  'apparent_power',
  'battery_capacity',
  'recommended_breaker_capacity',
  'electric_motor_type',
  'motor_output',
  'motor_output_compressor',
  'motor_output_outside_air',
  'motor_output_return_fan',
  'motor_output_blower',
  'motor_output_exhaust',
  'motor_output_auxiliary_equipment',
  'motor_output_per_unit',
  'motor_output_per_unit_compressor',
  'motor_output_per_unit_blower',
  'motor_output_per_unit_auxiliary_machine',
  'inverter_output_set_value',
  'load_factor',
  'load_classification',
  'load_type',
  'oa_outlet_load',
  'lighting_load',
  'cabinet_type',
  'open_close_type',
  'adjustment_angle',
  'angle_of_view',
  'monitor_size',
  'centralized_controller',
  'electrical_and_watch',
  'access_control_terminal',
  'energy_metering',
  'fire_stop',
  'electric_method',
  'control_method',
  'operation_control_method',
  'operation_control_switch',
  'start_stop_method',
  'starting_method',
  'operating_method',
  'interlocking',
]

export const MotorEvokedPotential = [
  'tsubo_tatami_conversion',
  'movable_load',
  'seating_capacity',
  'quantity_number_of_units',
  'model',
  'device_classification_code',
  'type_of_equipment',
  'special_specifications',
  'equipment_specifications',
  'format',
  'format2',
  'designation',
  'accessoriesofequipment',
  'machine_foundation_construction_procedure',
  'installation_method_of_equipment',
  'installation_location',
  'installation_interval',
  'installation_area',
  'height_of_gantry',
  'number_of_connections',
  'number_of_circuits',
  'number_of_system',
  'usable_temperature',
  'driving_range_high',
  'driving_range_low',
  'clearance_back',
  'clearance_bottom',
  'clearance_front',
  'clearance_front_door',
  'clearance_left',
  'clearance_right',
  'clearance_top',
  'unit_area_weight',
  'mass',
  'operating_mass',
  'product_mass',
  'operating_mass_per_unit',
  'product_mass_per_unit',
]

export const FireFightning = [
  'installation_conditions',
  'fire_extinguisher_capacity_unit_a',
  'fire_extinguisher_capacity_unit_b',
  'fire_extinguisher_capacity_unit_c',
  'fire_inspection_number',
  'fire_fighting_equipment_model_number',
  'hose_type',
  'fire_extinguisher',
  'sensitivity_type',
  'marked_temperature',
  'maximum_ambient_temperature',
  'specified_water_discharge',
  'radiation_dose',
  'radiation_range',
  'radiant_pressure',
  'standard_radiation_pressure',
  'foaming_ratio',
  'red_operation_start_pressure',
  'fire_extinguisher_connection_size',
  'effective_sprinkler_radius_1',
]

export const Joinery = [
  'fire_protection_performance',
  'joinery_type',
  'joinery_class',
  'joinery_form',
  'joinery_effective_width',
  'joinery_effective_height',
  'joinery_overall_width',
  'joinery_overall_height',
  'joinery_depth',
  'joinery_material',
  'joinery_finish',
  'joinery_combination_shape',
  'joinery_frame_depth',
  'joinery_frame_shape',
  'joinery_frame_material',
  'joinery_frame_finish',
  'joinery_doorsill_shape',
  'joinery_doorsill_material',
  'joinery_glass_type',
  'joinery_glass_thickness',
  'joinery_glass_presser',
  'joinery_grill_width',
  'joinery_grill_height',
  'joinery_grill_shape',
  'joinery_grill_material',
  'joinery_effective_aperture_ratio_of_grill',
  'joinery_support_hardware',
  'joinery_opening_closing_adjustment_hardware',
  'joinery_lock',
  'joinery_operation_hardware',
  'joinery_remarks',
  'joinery_fire_protection_equipment_grade',
  'joinery_compound_fire_protection_equipment_certification_number',
  'joinery_specific_fire_protection_equipment',
  'joinery_fire_protection_equipment_certification_number',
  'joinery_fireproof_performance',
  'joinery_open_close_method',
  'joinery_airtightness_jis',
  'joinery_sound_insulation_performance_jis',
  'joinery_watertightness_jis',
  'joinery_wind_pressure_resistance_jis',
  'joinery_heat_insulation_performance_jis',
  'joinery_security_performance',
  'joinery_window_heat_transfer_coefficient_of_center_of_glass_ug_value',
  'joinery_window_heat_transfer_coefficient_u_value',
  'joinery_sash_heat_transfer_coefficient_uf_value',
  'joinery_solar_heat_gain_rate_eta_value',
]

export const Lightning = [
  'light_distribution_angle',
  'effective_detection_height',
  'detection_range',
  'public_model_number',
  'illuminance_range',
  'luminous_flux',
  'light_source_color_temperature',
  'light_source_color_rendering_index',
  'dimmable',
  'dimming',
  'dimming_control_method',
  'tonable',
  'appliance_lifespan',
  'light_source_lifespan',
  'light_loss_factor',
  'lamp_rated_consumption_efficiency',
  'energy_consumption_efficiency',
  'intrinsic_energy_consumption_efficiency',
  'coefficient_of_energy_consumption_for_lighting',
  'equivalent_appliance',
  'equipment_size',
  'lamp_type',
  'lamp_size',
  'lamp_number',
  'number_of_lamps',
  'compatible_light_source',
  'lamp_included',
  'glare_classification',
  'lamp_total_luminous_flux',
  'lamp_rated_power_consumption',
  'lamp_current',
  'rated_luminous_flux',
  'luminous_intensity',
  'luminance',
  'illuminance',
  'cover_shape',
  'with_louver',
  'with_light_reflector',
  'lighting_fixture_material_cover',
  'lighting_fixture_material_louvre',
  'lighting_fixture_material_reflector',
  'lighting_fixture_material_body',
  'motion_sensor',
  'initial_illuminance_correction',
  'coefficient_of_utilization',
  'illuminance_range1',
  'illuminance_range2',
  'tilt_angle',
  'spot_beam_angle',
  'spot_field_angle',
  'beam_angle',
  'distance_of_light_source_to_irradiated_surface',
  'recommended_ceiling_height',
  'operating_temperature_range',
  'tolerance',
  'ballast',
  'ballast_fluorescent_lamp',
  'ballast_rated_voltage',
  'ballast_secondary_voltage',
  'ballast_input_current',
  'ballast_input_power',
  'lighting_control_device_type',
  'led_controller',
  'onoff_control_method',
  'time_schedule_control',
  'degrees_of_protection_provided_by_enclosures',
  'light_fixture_public_code',
  'certification_number',
  'with_emergency_lighting',
  'battery_classification',
  'battery_type_name',
  'battery_type_standard_code',
  'types_of_lighting_fixtures_for_disaster_prevention',
  'disaster_prevention_lighting_fixture_power_supply_type',
  'emergency_exit_light_certification_number',
  'emergency_exit_light_type',
  'emergency_exit_light_specifications',
  'emergency_exit_light_class',
  'emergency_exit_light_one_side_or_both_sides',
  'emergency_exit_light_panels',
  'emergency_exit_light_back_type',
]

export const PaintAndPlastererMaterials = [
  'basecoad_material',
  'middle_coat',
  'top_coat',
  'diluted_solution',
  'suitable_substrate',
  'application_for',
  'coat_thickness',
  'spread_thickness',
]

export const FumeHood = [
  'ventilation_frequency',
  'exhaust_air_volume',
  'processing_air_volume',
  'main_body_static_pressure',
]

export const SpecificationsAndOther = [
  'floor_heating_use',
  'electric_lock_capable',
  'stackable',
]

export const PhysicalResistance = [
  'visible_light_transmittance',
  'visible_light_reflectance',
  'visible_light_out_reflectance',
  'visible_light_in_reflectance',
  'solar_reflectance',
  'solar_energy_out_reflectance',
  'solar_energy_in_reflectance',
  'solar_energy_absorbtion',
  'solar_energy_transmittance',
  'solar_heat_gain_coefficient_eta_value',
  'solar_shading_coefficient_sc',
  'uv_protection_factor_upf',
  'uv_shielding_rate',
  'uv_transmittance',
  'adiabatic_performance_u_value',
  'heat_transmission_resistance_rt_value',
  'thermal_resistance_r',
  'heat_transfer_coefficient_u',
  'thermal_conductivity',
  'specific_heat',
  'heat_content',
  'cv_value',
  'cp_value',
  'absorption_rate',
  'transmittance',
  'reflectance',
  'refractive_index',
  'strength',
  'yield_strength',
  'tensile_strength',
  'hardness',
  'surface_hardness',
  'modulus_of_elasticity',
  'longitudinal_elastic_modulus_e',
  'poissons_ratio_v',
  'shear_modulus_g',
  'specific_gravity',
  'density',
  'mass_density',
  'emissivity_beta',
  'porosity',
  'moisture_permeation_resistance_ratio',
  'electrical_resistivity',
  'thermal_expansion_coefficient',
  'softening_point',
  'shock_absorption_g_value',
  'long_term_load_bearing_capacity',
  'load_bearing_capacity',
  'heatproof_temperature',
]

export const SpecialProcess = [
  'dust_proof',
  'stain_resistance',
  'bacteria_resistance',
  'mildew_resistance',
  'deodorant',
  'surface_roughness',
  'surface_strength_enhancement',
  'anti_shattering',
  'anti_penetration',
  'elasticity',
  'surface_treatment_agent',
  'coating',
  'can_fireproof_after_processing',
  'washable',
  'phosphorescent',
  'light_shielding',
  'heat_ray_shielding',
  'solar_heat_shielding',
  'reflection_reducing_effect',
  'uv_protection',
  'sound_insulation',
  'sound_absorption_performance',
  'anti_static',
  'electromagnetic_shielding_property',
  'negativeion',
  'formaldehyde_elimination',
  'insect_repellent',
]

export const Resistance = [
  'seismic_strength',
  'load_bearing',
  'static_load_resistance',
  'dynamic_load_resistance',
  'abrasion_resistance',
  'shock_absorption',
  'fireproof',
  'heat_resistance',
  'cigarette_resistance',
  'thermal_insulation_properties',
  'freeze_thaw_resistance',
  'weather_resistance',
  'light_resistance',
  'corrosion_resistance',
  'chemical_resistance',
  'acid_resistance',
  'alkaline_resistance',
  'salt_water_resistance',
  'oil_resistance',
  'slip_resistance',
  'water_repellent',
  'water_resistance',
  'water_proof',
  'water_absorbent',
  'breathability',
  'hygroscopic',
  'moisture_absorption',
  'moisture_proof',
  'rain_proof',
]

export const Installation = [
  'installation_plane',
  'installation_type',
  'installation_method',
  'installation_tools',
  'application_method',
  'application_tools',
  'service_life',
  'allowance',
]

export const WaterSupplyEquipment = [
  'insulation_specifications',
  'minimum_diameter',
  'maximum_diameter',
  'outlet_diameter',
  'entrance_diameter',
  'hot_and_cold_water_pipe_diameter',
  'cold_water_pipe_diameter',
  'hot_water_pipe_diameter',
  'high_temperature_water_pipe_diameter',
  'hot_water_supply_pipe_diameter',
  'return_hot_water_pipe_diameter',
  'cooling_water_pipe_diameter',
  'refrigerant_pipe_diameter',
  'refrigerant_liquid_pipe_diameter',
  'refrigerant_gas_pipe_diameter',
  'refrigerant_high_low_pressure_gas_pipe_diameter',
  'vent_pipe_diameter',
  'steam_pipe_diameter',
  'steam_return_pipe_diameter',
  'humidification_water_supply_pipe_diameter',
  'water_pipe_diameter',
  'medium_water_pipe_diameter',
  'wastewater_pipe_diameter',
  'overflow_pipe_diameter',
  'drain_pipe_diameter',
  'relief_valve_pipe_diameter',
  'expansion_tube_diameter',
  'suction_port',
  'suction_port_size_foot_valve',
  'discharge_port_diameter',
  'gas_pipe_diameter',
  'fuel_pipe_diameter',
  'medication_injection_tube_diameter',
  'chemical_injection_return_pipe_diameter',
  'joint_straight_length',
  'body_length',
  'refrigerant_pipe_length',
  'partition_size',
  'nominal_size_of_equipment',
  'heat_source_water_pipe_diameter',
  'brine_pipe_diameter',
  'water_supply_information',
  'piping_circuit_method',
  'issue_number',
  'number_of_units',
  'capacity',
  'effective_capacity',
  'water_volume',
  'amount_of_water_held_in_can',
  'hot_water_storage',
  'hot_water_supply_capacity',
  'filtration_capacity',
  'hot_water_supply',
  'unit_water_supply',
  'maximum_absorption',
  'amount_of_clean_water',
  'medium_water_volume',
  'discharge_amount',
  'miscellaneous_drainage',
  'amount_of_greywater',
  'actual_flow_rate',
  'max_flow_rate',
  'mid_flow_rate',
  'min_flow_rate',
  'discharge_rate',
  'minimum_flow_rate_varied_flow_rate',
  'lifting_height',
  'max_lifting_height',
  'mid_lifting_height',
  'min_lifting_height',
  'pushing_pressure',
  'back_pressure',
  'maximum_water_supply_pressure',
  'minimum_working_pressure',
  'maximum_working_pressure',
  'pressure_reducing_valve_pressure',
  'fuel_supply_pressure',
  'standard_mounting_height',
  'wastewater_center_height',
  'hot_water_equipment',
  'wash_water_volume',
  'flash_minimum_operating_pressure',
  'water_tap_minimum_pressure',
]
