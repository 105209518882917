import React from 'react'

import { createGroup } from '../utils'

import * as BasicFields from './Basic'
import * as LightningFields from './Lightning'
import * as Product from '@arch-log/webapp.modules/product/components/Reference'

/**
 */
export const Basic = createGroup('Classifications_Basic', (props) => (
  <>
    <BasicFields.ProductClassification {...props} />
    <BasicFields.ProductType {...props} />
    <BasicFields.ProductCategory {...props} />
    <BasicFields.GradeType {...props} />
    <BasicFields.SizeType {...props} />
    <BasicFields.ShapeType {...props} />
    <BasicFields.AlignmentType {...props} />
    <BasicFields.MaterialType {...props} />
    <BasicFields.ProductStyle {...props} />
    <BasicFields.ProductDesigner {...props} />
    <BasicFields.ProductApplications {...props} />
  </>
))

/**
 */
export const Lightning = createGroup(
  'Classifications_Lightning',
  (props) => (
    <>
      <LightningFields.ApplianceType {...props} />
      <LightningFields.WattageType {...props} />
      <LightningFields.LampType {...props} />
      <LightningFields.LightSourceColorDescription {...props} />
      <LightningFields.LightDistributionType {...props} />
      <LightningFields.LightBeamAngleType {...props} />
      <LightningFields.SurfaceMountedOrRecessed {...props} />
    </>
  ),
  (props) => (
    <Product.Properties.Classifications.HasChildFields
      fields={[
        'appliance_type',
        'wattage_type',
        'lamp_type',
        'light_source_color_description',
        'light_distribution_type',
        'light_beam_angle_type',
        'surface_mounted_or_recessed',
      ]}
      {...props}
    />
  ),
)
