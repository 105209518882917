import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as Components from '../Components'
import * as Labels from './labels'
import { createGroup } from '../utils'

/**
 */
const ValueFormatter = (v, { t }) => {
  if (typeof v === 'boolean') {
    return t(v ? 'Values.Boolean.Yes' : 'Values.Boolean.No')
  }
  return v
  //return t(v ?? '')
}

/**
 */
export const FireRetardant = createGroup(
  'Standars_FireRetardant',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <Product.Properties.Standards.Filter labels={Labels.FireRetardant}>
        <Product.Properties.Standards.Each>
          <Container>
            <Label>
              <Product.Properties.Standard.Label
                language={language}
                formatter={(v) => t(`Standards.${v}`)}
              />
            </Label>
            <Value>
              <Product.Properties.Standard.Value
                formatter={ValueFormatter}
                t={t}
              />
              <Product.Properties.Standard.Unit />
              <Product.Properties.Standard.Note prefix="(" suffix=")" />
            </Value>
          </Container>
        </Product.Properties.Standards.Each>
      </Product.Properties.Standards.Filter>
    )
  },
  (props) => (
    <Product.Properties.Standards.Has
      labels={Labels.FireRetardant}
      {...props}
    />
  ),
)

/**
 */
export const SoundInsulation = createGroup(
  'Standars_SoundInsulation',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <Product.Properties.Standards.Filter labels={Labels.SoundInsulation}>
        <Product.Properties.Standards.Each>
          <Container>
            <Label>
              <Product.Properties.Standard.Label
                language={language}
                formatter={(v) => t(`Standards.${v}`)}
              />
            </Label>
            <Value>
              <Product.Properties.Standard.Value
                formatter={ValueFormatter}
                t={t}
              />
              <Product.Properties.Standard.Unit />
              <Product.Properties.Standard.Note prefix="(" suffix=")" />
            </Value>
          </Container>
        </Product.Properties.Standards.Each>
      </Product.Properties.Standards.Filter>
    )
  },
  (props) => (
    <Product.Properties.Standards.Has
      labels={Labels.SoundInsulation}
      {...props}
    />
  ),
)

/**
 */
export const Standard = createGroup(
  'Standars_Standard',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <Product.Properties.Standards.Filter labels={Labels.Standard}>
        <Product.Properties.Standards.Each>
          <Container>
            <Label>
              <Product.Properties.Standard.Label
                language={language}
                formatter={(v) => t(`Standards.${v}`)}
              />
            </Label>
            <Value>
              <Product.Properties.Standard.Value
                formatter={ValueFormatter}
                t={t}
              />
              <Product.Properties.Standard.Unit />
              <Product.Properties.Standard.Note prefix="(" suffix=")" />
            </Value>
          </Container>
        </Product.Properties.Standards.Each>
      </Product.Properties.Standards.Filter>
    )
  },
  (props) => (
    <Product.Properties.Standards.Has labels={Labels.Standard} {...props} />
  ),
)

/**
 */
export const Environment = createGroup(
  'Standars_Environment',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components

    return (
      <Product.Properties.Standards.Filter labels={Labels.Environment}>
        <Product.Properties.Standards.Each>
          <Container>
            <Label>
              <Product.Properties.Standard.Label
                language={language}
                formatter={(v) => t(`Standards.${v}`)}
              />
            </Label>
            <Value>
              <Product.Properties.Standard.Value
                formatter={ValueFormatter}
                t={t}
              />
              <Product.Properties.Standard.Unit />
              <Product.Properties.Standard.Note prefix="(" suffix=")" />
            </Value>
          </Container>
        </Product.Properties.Standards.Each>
      </Product.Properties.Standards.Filter>
    )
  },
  (props) => (
    <Product.Properties.Standards.Has labels={Labels.Environment} {...props} />
  ),
)

/**
 */
export const Other = createGroup(
  'Standars_Other',
  ({ components = {}, language = null, t, ...props }) => {
    const DefaultComponents = React.useContext(Components.Context)

    const {
      Container = DefaultComponents.FieldContainer,
      Label = DefaultComponents.FieldLabel,
      Value = DefaultComponents.FieldValue,
    } = components
    return (
      <Product.Properties.Standards.Filter labels={Labels.Other}>
        <Product.Properties.Standards.Each>
          <Container>
            <Label>
              <Product.Properties.Standard.Label
                language={language}
                formatter={(v) => t(`Standards.${v}`)}
              />
            </Label>
            <Value>
              <Product.Properties.Standard.Value
                formatter={ValueFormatter}
                t={t}
              />
              <Product.Properties.Standard.Unit />
              <Product.Properties.Standard.Note prefix="(" suffix=")" />
            </Value>
          </Container>
        </Product.Properties.Standards.Each>
      </Product.Properties.Standards.Filter>
    )
  },
  (props) => (
    <Product.Properties.Standards.Has labels={Labels.Other} {...props} />
  ),
)
