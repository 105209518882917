import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'
import * as ProductIcons from '@arch-log/webapp.shared/ProductIcons'
import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import * as styles from './styles.module.scss'
import * as Icons from '@arch-log/webapp.shared/Icons'

/**
 */
export const DefaultOnSelectProduct = (product) => {
  console.log(product)
}

/**
 */
const Discontinued = () => (
  <div className={styles.Discontinued_Label}>Discontinued</div>
)

/**
 */
export const Background = () => (
  <div className={styles.Background}>
    <Product.IsDiscontinued>
      <Discontinued />
    </Product.IsDiscontinued>
    <Product.Properties.Images.First>
      <Product.Properties.Image.Thumbnail
        className={styles.Background_Image}
        render={({ src, ...props }) => (
          <Renderers.KeepRatioImageRenderer
            src={`${src}?size=300x300`}
            {...props}
          />
        )}
      />
    </Product.Properties.Images.First>
  </div>
)

/**
 */
export const Title = withLanguage(({ language }) => (
  <div className={styles.Title}>
    <Product.Properties.ManufacturerDisplayName
      language={language}
      short={true}
      className={styles.Title_ManufacturerName}
    />
    <Product.Properties.ProductCode className={styles.Title_Code} />
  </div>
))

/**
 */
export const BIMIcon = () => {
  const { product = {} } = React.useContext(Product.Context)

  const { bim: { type: bimType = null } = {} } = product

  if (!product.isBIMSupport) return null

  return (
    <div className={styles.BimIcon}>
      <ProductIcons.BIM
        isProxy={product.isProxySupport}
        type={bimType}
        isLink={product.isLinkBim}
      />
    </div>
  )
}

/**
 */
export const ParentIcon = () => {
  const { product: { option_selection: { options = null } = {} } = {} } =
    React.useContext(Product.Context)

  if (!options) {
    return null
  }

  return (
    <div className={styles.HasParent}>
      <ProductIcons.HasParent />
    </div>
  )
}

/**
 */
export const ProductOptionIcon = () => {
  const { product } = React.useContext(Product.Context)

  const options = product?.ProductOptions?.options ?? []

  if (!options || options.length === 0) {
    return null
  }

  return <Icons.ProductOption className={styles.ProductOptionIcon} />
}
