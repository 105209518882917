import * as Renderers from '@arch-log/webapp.modules/project.product.v2/components/PropertyRenderers'

import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const Registerer = withContextValue(
  Context,
  (data) => data.entry.archlogExtended.registerer,
)(Renderers.ArchlogExtended.Registerer)
