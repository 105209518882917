import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const EntrySelector = ({ onSelect, isSelected, children }) => (
  <div
    className={isSelected ? styles.Entry_Selected : styles.Entry}
    onClick={() => onSelect()}
  >
    <div className={styles.Inner}>{children}</div>
  </div>
)
