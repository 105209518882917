import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app')('BulkCopyProductModal.Title')

/**
 */
export const TargetProjectName = translated('app')(
  'BulkCopyProductModal.TargetProjectName',
)

/**
 */
export const CopyProductListTitle = translated('app')(
  'BulkCopyProductModal.CopyProductListTitle',
)

/**
 */
export const CopyProductListDescription = translated('app')(
  'BulkCopyProductModal.CopyProductListDescription',
)

/**
 */
export const Copy = translated('app')('BulkCopyProductModal.Actions.Copy')

/**
 */
export const NotTargetProjectDescription = translated('app')(
  'BulkCopyProductModal.NotTargetProjectDescription',
)

/**
 */
export const LinkToCreateProject = translated('app')(
  'BulkCopyProductModal.LinkToCreateProject',
)

/**
 */
export const CopyFailure = translated('app')(
  'BulkCopyProductModal.Errors.CopyFailure',
)

/**
 */
export const IncludePrivateProductError = translated('app')(
  'BulkCopyProductModal.Errors.IncludePrivateProductError',
)

/**
 */
export const Progress = translated('app')('BulkCopyProductModal.Progress')

/**
 */
export const NoCopyProductsButton = translated('app')(
  'BulkCopyProductModal.NoCopyProductsButton',
)
