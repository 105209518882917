import { USAGE_SERVICE_TAG_PREFIX } from './consts'

/**
 */
export const getUsageServiceTagMeta = (tags) =>
  tags.filter((tag) => isUsageServiceTagMeta(tag))

/**
 */
export const isUsageServiceTagMeta = (tag) => {
  const parseTag = tag.split('.')

  if (parseTag.length !== 2) {
    return false
  }

  if (parseTag[0] !== USAGE_SERVICE_TAG_PREFIX) {
    return false
  }

  return true
}
