import React from 'react'

import { Context } from './Context'

import * as Manufacturer from '@arch-log/webapp.modules/manufacturer.v2/components'
import * as Containers from './Containers'

/**
 */
const DefaultRenderer = ({ entry }) => {
  if (!entry) {
    return <Containers.NotSelected />
  }

  return <Manufacturer.Scope.Entry.Properties.DisplayShortName language="ja" />
}

/**
 */
export const Reference = ({ render: Renderer = DefaultRenderer, ...props }) => {
  const { selected } = React.useContext(Context)

  return (
    <Manufacturer.Scope.Entry.Provider entry={selected} render={Renderer} />
  )
}
