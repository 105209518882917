import React from 'react'
import * as Renderers from '@arch-log/webapp.modules/product.v2/components/PropertyRenderers'

import { Context } from '@arch-log/webapp.modules/project.product.v2/components/Scope/Entry_202305/Context'
import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'

/**
 */
export const IsSupported = withContextValue(
  Context,
  (data) => data.entry.bimInfo.plugins.sketchup?.url,
)(Renderers.BimInfo.Plugin.IsSupported)
