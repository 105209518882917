import React from 'react'

import * as Selected from './Selected'

/**
 */
export const useActions = () => {
  return {
    ...Selected.useActions(),
  }
}
