import React from 'react'

import { classNames } from '@arch-log/webapp.lib/CssHelper'
import { Context as StyleContext } from './Style'
import * as Components from './Components'

/**
 */
export const Fragment = ({ children }) => <>{children}</>

/**
 */
export const FieldGroupContainer = ({
  children,
  className,
  additionalClassName,
}) => {
  const { styles } = React.useContext(StyleContext)
  return (
    <dl
      className={classNames([
        className ?? styles.FieldGroupContainer,
        additionalClassName,
      ])}
    >
      {children}
    </dl>
  )
}

/**
 */
export const FieldGroupLabel = ({
  children,
  className,
  additionalClassName,
}) => {
  const { styles } = React.useContext(StyleContext)

  const DefaultComponents = React.useContext(Components.Context)

  const { LabelDecorator: Decorator = LabelDecorator } = DefaultComponents 

  return (
    <dt
      className={classNames([
        className ?? styles.FieldGroupLabel,
        additionalClassName,
      ])}
    >
      <LabelDecorator>{children}</LabelDecorator>
    </dt>
  )
}

/**
 */
export const FieldGroupValue = ({
  children,
  className,
  additionalClassName,
}) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <dd
      className={classNames([
        className ?? styles.FieldGroupValue,
        additionalClassName,
      ])}
    >
      {children}
    </dd>
  )
}

/**
 */
export const FieldContainer = ({
  children,
  className,
  additionalClassName,
}) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <dl
      className={classNames([
        className ?? styles.FieldContainer,
        additionalClassName,
      ])}
    >
      {children}
    </dl>
  )
}

/**
 */
export const FieldLabel = ({ children, className, additionalClassName }) => {
  const { styles } = React.useContext(StyleContext)

  const DefaultComponents = React.useContext(Components.Context)

  const { LabelDecorator: Decorator = LabelDecorator } = DefaultComponents 
  return (
    <dt
      className={classNames([
        className ?? styles.FieldLabel,
        additionalClassName,
      ])}
    >
      <Decorator>{children}</Decorator>
    </dt>
  )
}

/**
 */
export const FieldValue_Rows = ({
  children,
  className,
  additionalClassName,
}) => {
  const { styles } = React.useContext(StyleContext)
  const DefaultComponents = React.useContext(Components.Context)

  const { ValueDecorator: Decorator = ValueDecorator } = DefaultComponents 

  return (
    <dd
      className={classNames([
        className ?? styles.FieldValue_Rows,
        additionalClassName,
      ])}
    >
      <ValueDecorator>{children}</ValueDecorator>
    </dd>
  )
}

/**
 */
export const FieldValue = ({ children, className, additionalClassName }) => {
  const { styles } = React.useContext(StyleContext)
  const DefaultComponents = React.useContext(Components.Context)

  const { ValueDecorator: Decorator = ValueDecorator } = DefaultComponents 

  return (
    <dd
      className={classNames([
        className ?? styles.FieldValue,
        additionalClassName,
      ])}
    >
      <Decorator>{children}</Decorator>
    </dd>
  )
}

/**
 */
export const List = ({ children, className, additionalClassName }) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <ul className={classNames([className ?? styles.List, additionalClassName])}>
      {children}
    </ul>
  )
}

/**
 */
export const ListItem = ({ children, className, additionalClassName }) => {
  const { styles } = React.useContext(StyleContext)
  const DefaultComponents = React.useContext(Components.Context)

  const { ValueDecorator: Decorator = ValueDecorator } = DefaultComponents 

  return (
    <li
      className={classNames([
        className ?? styles.ListItem,
        additionalClassName,
      ])}
    >
      <Decorator>{children}</Decorator>
    </li>
  )
}

/**
 */
export const Tag = ({ children, className, additionalClassName }) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <span
      className={classNames([className ?? styles.Tag, additionalClassName])}
    >
      {children}
    </span>
  )
}

/**
 */
export const LabelDecorator = ({ children }) => <>{children}</>

/**
 */
export const ValueDecorator = ({ children }) => <>{children}</>
